import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import MainContainer from "../../layouts/container/mainContainer";
import { FormProvider, useForm } from "react-hook-form";
import "../../styles/pages/login.scss";
import Button from "../../components/buttons/button";
import Modal from "@mui/material/Modal";
import {
  callApi,
  saveEmployerFile,
  getEmployerFiles,
  deleteEmployerFile,
} from "../../services/apiService";
import Table, { defaultOptions, defaultCol } from "../../components/table";
import { AiFillEye, AiOutlineDownload, AiOutlinePlus } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { isRole } from "../../services/authService";
import { RoleEnum } from "../../constants/enum";

const urlBase = process.env.REACT_APP_URL_BASE;
const urlApi = process.env.REACT_APP_API_URL;

const FilesModal = ({ open, onClose, currentEmployer }) => {
  const { t } = useTranslation();
  const isTraveler = isRole(RoleEnum.traveler);

  const [botonActivo, setBotonActivo] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [deletePath, setDeletePath] = useState("");
  const [previewView, setPreviewModal] = useState(false);
  const [addFileModal, setAddFileModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const validations = {
    file: {
      required: {
        value: true,
        message: t("image.requireImage"),
      },
    },
  };
  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const getObjFiles = (tableMeta) =>
    documents.find((x) => x.id === tableMeta.rowData[0]);

  useEffect(() => {
    if (!currentEmployer || !open) {
      return;
    }
    if (currentEmployer.id == null || currentEmployer.id == undefined) {
      loadDataTable(currentEmployer.employer_id);
    }
    {
      loadDataTable(currentEmployer.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onSubmit = handleSubmit((data) => {
    setBotonActivo(true);
    var formData = new FormData();
    formData.append("employerId", parseInt(currentEmployer.id));
    formData.append("file", data.image[0]);
    callApi(
      () => saveEmployerFile(formData),
      () => {
        NotificationManager.success(t("template.uploadSucces"));
        onCloseHandleAddFile();
        loadDataTable(currentEmployer.id);
      }
    );
    setBotonActivo(false);
  });

  const loadDataTable = (currentEmployerId) => {
    if (currentEmployerId == undefined) {
      return;
    }
    callApi(
      () =>
        getEmployerFiles({ currentEmployerId: parseInt(currentEmployerId) }),
      (data) => {
        setDocuments(data);
      }
    );
  };
  const fnDeleteFile = (id, path) => {
    const data = {
      fileId: id,
      filePath: path,
    };
    callApi(
      () => deleteEmployerFile(data),
      () => {
        loadDataTable(currentEmployer.id);
        setDeleteModal(false);
      }
    );
  };

  const onCloseHandleAddFile = () => {
    setAddFileModal(false);
  };
  const handleClosePreviewView = () => {
    setPreviewModal(false);
  };
  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const onCloseHandle = () => {
    setDocuments([]);
    reset();
    onClose();
  };

  const columnsModal = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    defaultCol("file_name", "register.name"),
    {
      name: "Actions",
      //label: t("Link"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, documents, updateValue) => {
          var rowObjFile = getObjFiles(documents);
          return (
            <div className="table-btn-group">
              <Button
                label={<AiFillEye />}
                onClick={() => {
                  setPreviewModal(true);
                  setDeletePath(rowObjFile.path);
                }}
              />
              <a
                href={urlBase + deletePath.replace("WorldWideFiles", "")}
                download
                target="_blank"
              >
                <Button
                  label={<AiOutlineDownload />}
                  onClick={() => {
                    setDeletePath(rowObjFile.path);
                  }}
                />
              </a>
              {!isTraveler && (
                <Button
                  label={<MdDelete />}
                  onClick={() => {
                    setDeleteId(rowObjFile.id);
                    setDeletePath(rowObjFile.path);
                    setDeleteModal(true);
                  }}
                />
              )}
            </div>
          );
        },
      },
    },
  ];

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseHandle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer class="ww-container-xl" showImage={false}>
          <h1 style={{ margin: "20px 0 20px 0" }} className="title">
            {currentEmployer
              ? t("employerList.files") + ": " + currentEmployer.company_name
              : ""}
          </h1>
          {!isTraveler && (
            <Button
              label={<AiOutlinePlus />}
              onClick={() => {
                setAddFileModal(true);
              }}
            />
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <Table
              data={documents}
              columns={columnsModal}
              options={defaultOptions()}
            />
            <Button
              type="ww-btn-secondary"
              style={{ margin: "10px" }}
              size="btn-s secondary"
              label={t("main.close")}
              onClick={() => {
                onCloseHandle();
              }}
            />
          </div>
        </MainContainer>
      </Modal>
      {/* DELETE FILE */}
      <Modal
        open={deleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m" showImage={false}>
          <br />
          <br />
          <p>{t("alert.deletedocument")}</p>
          <div style={{ display: "flex" }}>
            <Button
              type="ww-btn-secondary"
              style={{ margin: "10px" }}
              size="btn-sx secondary"
              label={t("main.cancel")}
              onClick={() => setDeleteModal(false)}
            />
            <Button
              style={{ margin: "10px" }}
              size="btn-sx"
              label={t("main.delete")}
              onClick={() => fnDeleteFile(deleteId, deletePath)}
            />
          </div>
        </MainContainer>
      </Modal>
      {/* MODAL ADDFILE */}
      <Modal
        open={addFileModal}
        onClose={onCloseHandleAddFile}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m" showImage={false}>
          <FormProvider {...methods}>
            <form encType="multipart/form-data" className="form-m">
              <br />
              <br />
              <div className="inputs-style">
                <p>{t("form.labelII")}</p>
              </div>
              <div className="mb-3">
                <input
                  className="form-control"
                  type="file"
                  id="image"
                  name="image"
                  {...register("image", validations.file)}
                />
                <span
                  style={{ margin: "10px 0 0 0" }}
                  className="text-danger text-small d-block mb-2"
                >
                  {errors.image && errors.image.message}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  type="ww-btn-secondary"
                  size="btn-s"
                  label={t("main.cancel")}
                  onClick={() => onCloseHandleAddFile()}
                  disabled={botonActivo}
                />
                <Button
                  size="btn-s"
                  label={t("uploadFiles.upload")}
                  onClick={onSubmit}
                  disabled={botonActivo}
                />
              </div>
            </form>
          </FormProvider>
        </MainContainer>
      </Modal>
      <Modal
        open={previewView}
        onClose={handleClosePreviewView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer class="ww-container-m" Nologo={true}>
          <p>{t("form.previewView")}</p>
          <embed
            src={urlBase + "/" + deletePath.replace("WorldWideFiles", "")}
            className="file-preview-view"
          />
          <div>
            <Button
              style={{ margin: "10px" }}
              size="btn-s secondary"
              label={t("main.close")}
              onClick={() => setPreviewModal(false)}
            />
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default FilesModal;
