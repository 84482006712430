import React from "react";
import { getUserInfo } from "../../services/authService";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../buttons/button";
import { callApi, uploadTemplate } from "../../services/apiService";

const UploadTemplate = () => {
  const { t } = useTranslation();
  var userInfo = getUserInfo();

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    register,
    handleSubmit,
    //formState: { errors },
  } = methods;

  const onSubmit = handleSubmit((data) => {
    var formData = new FormData();
    formData.append("userId", userInfo.id);
    formData.append("template", data.template[0]);

    callApi(
      () => uploadTemplate(formData),
      () => {
        NotificationManager.success(t("template.uploadSucces"));
      }
    );
  });

  const validations = {
    template: {
      required: { value: true, message: t("main.WordWideWT") },
    },
  };

  return (
    <FormProvider {...methods}>
      <form encType="multipart/form-data" className="form-m">
        <div className="mb-3">
          <input
            className="form-control"
            type="file"
            id="template"
            name="template"
            {...register("template", validations.file)}
          />
        </div>
        <Button size="btn-s" label={t("main.getInto")} onClick={onSubmit} />
      </form>
    </FormProvider>
  );
};

export default UploadTemplate;
