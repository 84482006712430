import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  BiBriefcase,
  BiCaretRightCircle,
  BiCheckCircle,
  BiFile,
  BiFlag,
  BiHome,
  BiLibrary,
  BiListUl,
  BiUser,
} from "react-icons/bi";
import { useTranslation } from "react-i18next";

const steps = [
  {
    label: "main.form",
    icon: <BiFile />,
  },
  {
    label: "main.interview",
    icon: <BiUser />,
  },
  {
    label: "main.videoCV",
    icon: <BiCaretRightCircle />,
  },
  {
    label: "general.mockInterview",
    icon: <BiUser />,
  },
  {
    label: "general.employerSelection",
    icon: <BiListUl />,
  },
  {
    label: "general.interviewWork",
    icon: <BiUser />,
  },
  {
    label: "general.jobOffer",
    icon: <BiBriefcase />,
  },
  {
    label: "general.interviewConsular",
    icon: <BiFlag />,
  },
  {
    label: "general.flightAndAccommodation",
    icon: <BiHome />,
  },
  {
    label: "general.preTripTraining",
    icon: <BiLibrary />,
  },
  {
    label: "main.result",
    icon: <BiCheckCircle />,
  },
];

export default function CarruselProgressMovil({ stepProcess }) {
  const { t } = useTranslation();

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(stepProcess);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <Paper
        style={{ justifyContent: "center", backgroundColor: "transparent" }}
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 50,
          pl: 2,
          bgcolor: "background.default",
        }}
      >
        <div
          className={
            activeStep <= stepProcess
              ? `circle-movile-steps-selected`
              : `circle-movile-steps`
          }
          style={{ fontSize: "xx-large" }}
        >
          {steps[activeStep].icon}
        </div>
      </Paper>
      <Box sx={{ textAlign: "center", maxWidth: 400, width: "100%", p: 2 }}>
        {t(steps[activeStep].label)}
      </Box>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {t("main.next")}
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            {t("core.datatable.pagination.previous")}
          </Button>
        }
      />
    </Box>
  );
}
