import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import {
  callApi,
  getCodeIsValid,
  recoverPasswordNew,
} from "../../services/apiService";
import { useForm } from "react-hook-form";
import MainContainer from "../../layouts/container/mainContainer";
import Footer from "../../layouts/footer";

const NewPassword = () => {
  const { t } = useTranslation();
  const [botonActivo, setBotonActivo] = useState(false);
  const [email, setEmail] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tokenCode = urlParams.get("tk_id");
    const token = {
      token: tokenCode,
    };
    if (!tokenCode) {
      navigate("/");
      return;
    }
    callApi(
      () => getCodeIsValid(token),
      (data) => {
        if (data.email) {
          return setEmail(data);
        } else {
          NotificationManager.error(t("Token invalido"));
          navigate("/");
          return;
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    setBotonActivo("true");
    const newPassword = { ...data, email: email.email };

    callApi(
      () => recoverPasswordNew(newPassword),
      () => {
        setEmail("");
        navigate("/recover-password/success");
      }
    );

    setBotonActivo("false");
  };

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });

  const validations = {
    password: {
      required: { value: true, message: t("validationError.password.require") },
      minLength: { value: 5, message: t("validationError.password.short") },
      pattern: {
        value: /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/,
        message: t("validationError.password.items"),
      },
    },
  };

  return (
    <>
    <MainContainer class="ww-container-m">
      <h1 className="title">{t("recoverPassword.new.title")}</h1>
      <form className="form-m" onSubmit={handleSubmit(onSubmit)}>
        {/* Contraseña */}
        <label>{t("main.password")}</label>
        <input
          placeholder={t("placeholder.password")}
          className="form-control mb-2"
          name="password"
          type="password"
          {...register("password", validations.password)}
        />
        <span className="text-danger text-small d-block mb-2">
          {errors.password && errors.password.message}
        </span>
        {/* Contraseña repetición */}
        <label>{t("main.confirmPassword")}</label>
        <input
          placeholder={t("placeholder.passwordAgain")}
          className="form-control mb-2"
          name="password_confirmation"
          type="password"
          {...register("password_confirmation", {
            required: t("validationError.password.confirm.require"),
            validate: (value) =>
              value === watch("password") || t("validationError.password.confirm.error"),
          })}
        />
        <span className="text-danger text-small d-block mb-2">
          {errors.password_confirmation && errors.password_confirmation.message}
        </span>
        <br />
        <button
          disabled={botonActivo}
          type="submit"
          className="btn btn-dark ww-btn"
        >
         {t("main.change")}
        </button>
      </form>
    </MainContainer>
    <Footer />
    </>
  );
};

export default NewPassword;
