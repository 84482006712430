import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RoleEnum } from "../../constants/enum";
import { isRole, signOut } from "../../services/authService";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { HiMenu } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import "../../styles/layouts/header.scss";

const Header = (props) => {
  let navigate = useNavigate();
  const isAgency = isRole(RoleEnum.agency);

  const { t } = useTranslation();

  const close = () => {
    signOut();
    navigate("/");
  };

  const [anchorElUser, setAnchorElUser] = useState(null);
  const headerItems = [
    { name: t("menu.home"), href: "/home" },
    { name: t("menu.aplicateCourses"), href: "/registration-form" },
  ];
  const headerItemsII = [{ name: "Perfil", href: "/profile" }];
  const AgencyItems = [
    { name: t("userList.title"), href: "/user-list" },
    { name: t("main.employers"), href: "/employers" },
    { name: t("menu.courses"), href: "/courses" },
    { name: t("menu.reports"), href: "/reports" },
  ];
  const handleOpenUserMenu = () => {
    setAnchorElUser(true);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box className="header-container">
      <Tooltip
        onClick={handleOpenUserMenu}
        className="header-btn"
        title="Open settings"
      >
        <HiMenu />
      </Tooltip>
      <Menu
        className="header-menu"
        sx={{ mt: "45px", margin: "80px 0 0 -10px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {headerItems.map((x) => (
          <Link className="header-link" to={x.href}>
            <MenuItem
              style={{ padding: "8px 0 8px 20px" }}
              key={x.name}
              onClick={handleCloseUserMenu}
            >
              <Typography textAlign="center">{x.name}</Typography>
            </MenuItem>
          </Link>
        ))}
        <Divider style={{ height: "0px", margin: "8px 0 8px 0" }} />
        {isAgency &&
          AgencyItems.map((x) => (
            <Link
              onClick={handleCloseUserMenu}
              className="header-link"
              to={x.href}
            >
              <MenuItem
                style={{ padding: "8px 0 8px 20px" }}
                key={x.name}
                onClick={handleCloseUserMenu}
              >
                <Typography textAlign="center">{x.name}</Typography>
              </MenuItem>
            </Link>
          ))}
        {isAgency && (
          <Divider style={{ height: "0px", margin: "8px 0 8px 0" }} />
        )}
        {headerItemsII.map((x) => (
          <Link className="header-link" to={x.href}>
            <MenuItem
              style={{ padding: "8px 0 8px 20px" }}
              key={x.name}
              onClick={handleCloseUserMenu}
            >
              <Typography textAlign="center">{x.name}</Typography>
            </MenuItem>
          </Link>
        ))}
        <Link className="header-link" to="/">
          <MenuItem
            style={{ padding: "8px 0 8px 20px" }}
            key="close"
            onClick={close}
          >
            <Typography textAlign="center">{t("menu.closeSesion")}</Typography>
          </MenuItem>
        </Link>
      </Menu>
    </Box>
  );
};

export default Header;
