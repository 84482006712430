import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import MainContainer from "../../layouts/container/mainContainer";
import "../../styles/pages/login.scss";
import Button from "../../components/buttons/button";
import Modal from "@mui/material/Modal";
import {
  callApi,
  getPositionsByEmployer,
  deletePosition,
} from "../../services/apiService";
import Table, { defaultOptions, defaultCol } from "../../components/table";
import { AiOutlinePlus } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { BsPencilFill } from "react-icons/bs";
import AddEmployerPositionModal from "./addEmployerPositionModal";

const EmployerPositionModal = ({ open, onClose, currentEmployer }) => {
  const { t } = useTranslation();
  const [botonActivo, setBotonActivo] = useState(false);
  const [addPositionModal, setAddPositionModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [positions, setPositions] = useState([]);
  const [currentPosition, setCurrentPosition] = useState([]);

  const getObj = (tableMeta) =>
    positions.find((x) => x.id === tableMeta.rowData[0]);

  const getNameSkillById = (id) => {
    if (id == null || id == "") {
      return "";
    }
    const skillListTmp = [
      { id: "BASIC", name: t("language.basic") },
      { id: "PRE_INTERMEDIATE", name: t("language.preIntermediate") },
      { id: "INTERMEDIATE", name: t("language.intermediate") },
      { id: "HIGH_MIDIUM", name: t("language.highMidium") },
      { id: "ADVANCED", name: t("language.advanced") },
    ];
    var skill = skillListTmp.find((l) => l.id == id);
    return (skill = !null ? skill.name : "");
  };

  useEffect(() => {
    if (!currentEmployer && !open) {
      return;
    }
    loadPositions(currentEmployer.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, addPositionModal]);

  const loadPositions = (currentEmployerId) => {
    callApi(
      () => getPositionsByEmployer({ employerId: parseInt(currentEmployerId) }),
      (data) => {
        data.map((item) => {
          item.englishLevel = getNameSkillById(item.englishLevel);
        });
        setPositions(data);
      }
    );
  };

  const handleOpenAddPositionModal = () => setAddPositionModal(true);

  const onCloseHandleAddPosition = () => {
    setAddPositionModal(false);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const onCloseHandle = () => {
    onClose();
  };

  const fnDeleteFile = () => {
    const data = {
      id: deleteId != "" ? parseInt(deleteId):0,
    };
    if(data.id == 0){
      return;
    }
    callApi(
      () => deletePosition(data),
      () => {
        loadPositions(currentEmployer.id);
        setDeleteModal(false);
      }
    );
  };

  const columnsModal = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    defaultCol("position", "employer.position"),
    defaultCol("englishLevel", "employer.englishLevel"),
    {
      name: "Actions",
      label: t("userList.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, positions, updateValue) => {
          var rowObj = getObj(positions);
          return (
            <div className="table-btn-group">
              <Button
                disabled={botonActivo}
                label={<BsPencilFill />}
                onClick={() => {
                  setCurrentPosition(rowObj);
                  handleOpenAddPositionModal();
                }}
              />
              <Button
                disabled={botonActivo}
                label={<MdDelete />}
                onClick={() => {
                  setDeleteId(rowObj.id);
                  setDeleteModal(true);
                }}
              />
            </div>
          );
        },
      },
    },
  ];

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseHandle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer class="ww-container-xxl" showImage={false}>
          <h1 style={{ margin: "20px 0 20px 0" }} className="title">
            {currentEmployer
              ? t("employer.positionList") + "-" + currentEmployer.company_name
              : ""}
          </h1>
          <Button
            label={<AiOutlinePlus />}
            onClick={() => {
              setCurrentPosition(null);
              handleOpenAddPositionModal();
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <Table
              data={positions}
              columns={columnsModal}
              options={defaultOptions()}
            />
            <Button
              type="ww-btn-secondary"
              style={{ margin: "10px" }}
              size="btn-s secondary"
              label={t("main.close")}
              onClick={() => {
                onCloseHandle();
              }}
            />
          </div>
        </MainContainer>
      </Modal>
      {/* DELETE FILE */}
      <Modal
        open={deleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m" showImage={false}>
          <br />
          <br />
          <p>{t("alert.deletedocument")}</p>
          <div style={{ display: "flex" }}>
            <Button
              type="ww-btn-secondary"
              style={{ margin: "10px" }}
              size="btn-sx secondary"
              label={t("main.cancel")}
              onClick={() => setDeleteModal(false)}
            />
            <Button
              style={{ margin: "10px" }}
              size="btn-sx"
              label={t("main.delete")}
              onClick={() => fnDeleteFile()}
            />
          </div>
        </MainContainer>
      </Modal>
      {/* MODAL ADDPosition */}
      <AddEmployerPositionModal
        open={addPositionModal}
        onClose={onCloseHandleAddPosition}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        currentPosition={currentPosition}
        currentEmployer={currentEmployer}
      />
    </>
  );
};

export default EmployerPositionModal;
