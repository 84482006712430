import React from "react";
import { Navigate } from "react-router-dom";
import { getUserInfo } from "../services/authService";

const isBrowser = () => typeof window !== "undefined";

const isRoleUser = (role, userInfo) => {
  if (!userInfo) return false;
  return role.find((x) => userInfo.role === x);
};

const ProtectedRoute = ({ path, children, role }) => {
  let unprotectedRoutes = [
    "/",
    "/login",
    "/recover-password",
    "/recover-password-new",
    "/recover-password-send",
    "/recover-password-success",
    "/register",
    "/register/success",
    "/500",
    "/404",
    "*",
    "/unauthorized",
  ];

  let pathIsProtected = unprotectedRoutes.indexOf(path) === -1;

  if (isBrowser() && getUserInfo() === null && pathIsProtected) {
    return <Navigate to="/" replace />;
  } else if (!role || !isRoleUser(role, getUserInfo())) {
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

export default ProtectedRoute;
