import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  statePostulation: null,
};

const postulationReduce = createSlice({
  name: "postulationReduce",
  initialState,
  reducers: {
    setPostulationReduce: (state, action) => {
        state.statePostulation = action.payload;
    },
  },
});

export const { setPostulationReduce} = postulationReduce.actions;
export const selectPostulationReduce = (state) => state.postulationState.statePostulation;


export default postulationReduce.reducer;
