import React, { useState, useEffect } from "react";
import {
  callApi,
  getUsers,
  uploadImages,
  getAlertNotification,
  sendNotification,
  sendNotificationB,
  sendNotificationAgency,
} from "../../services/apiService";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { getUserInfo, isRole } from "../../services/authService";
import { useForm, FormProvider } from "react-hook-form";
import { RoleEnum } from "../../constants/enum";
import MainContainer from "../../layouts/container/mainContainer";
import Footer from "../../layouts/footer";
import Button from "../../components/buttons/button";
import Modal from "@mui/material/Modal";
import { getTokenMsg } from "../../firebase/methods";
import VideoPlayer from "../../components/videoPlayer";

//APP
const HomePage = () => {
  const [open, setOpen] = useState(false);
  const [alertToken, setAlertToken] = useState("");

  const { t } = useTranslation();
  //USER INFO
  var userInfo = getUserInfo();
  const isTraveler = isRole(RoleEnum.traveler);
  const isAdmin = isRole(RoleEnum.admin);
  //const isAgency = isRole(RoleEnum.agency);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    callApi(getUsers, (data) => {});
  }, []);

  const pushNotification = async () => {
    var token = await getTokenMsg();
    console.log("TOKEN", token);
    callApi(
      () => getAlertNotification({ alertToken: token, user_id: userInfo.id }),
      (data) => {
        if (data.length === 0) {
          handleOpen();
        }
      }
    );
  };

  const setNotifications = async (confirm) => {
    const token = await getTokenMsg();
    if (confirm === false) {
      callApi(
        () =>
          sendNotification({
            user_id: userInfo.id,
            notification_permission: confirm,
            token: token,
          }),
        NotificationManager.warning(t("notifications.deactivated"))
      );
      setOpen(false);
    } else {
      callApi(
        () =>
          sendNotification({
            user_id: userInfo.id,
            notification_permission: confirm,
            token: token,
          }),
        NotificationManager.success(t("notifications.active"))
      );
      setOpen(false);
    }
  };

  useEffect(() => {
    pushNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    register,
    handleSubmit,
    //formState: { errors },
  } = methods;

  const onSubmit = handleSubmit((data) => {
    var formData = new FormData();
    formData.append("userId", userInfo.id);
    formData.append("image", data.image[0]);

    callApi(
      () => uploadImages(formData),
      () => {
        NotificationManager.success(t("template.uploadSucces"));
      }
    );
  });

  const roleType = () => {
    if (isTraveler) {
      return <p>{t("home.welcomeTraveler")}</p>;
    } else if (isAdmin) {
      return <p>{t("home.welcomeAgency")}</p>;
    } else {
      return <p>{t("home.welcomeAgency")}</p>;
    }
  };

  return (
    <>
      <MainContainer>
        <div>{roleType()}</div>
        {isTraveler && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <a href="/registration-form">
                <Button
                  size="btn-xs secondary"
                  label={t("menu.aplicateCourses")}
                />
              </a>
            </div>
            <VideoPlayer
              url="/videos/Worldwide 2024 HomeWeb.mp4"
              title={t("videos.home")}
            />
          </>
        )}
      </MainContainer>
      <Footer />

      {/* NOTIFICATION*/}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m">
          <h1 className="title">{t("OfficeUse.authorizationC")}</h1>
          <p>{t("notifications.message")}</p>

          <div>
            <Button
              size="btn-sx"
              label={t("main.yes")}
              onClick={() => setNotifications(true)}
            />
            <Button
              type="ww-btn-secondary"
              size="btn-sx"
              label={t("main.no")}
              onClick={() => {
                setNotifications(false);
              }}
            />
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default HomePage;
