import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MainContainer from "../../layouts/container/mainContainer";
import Button from "../../components/buttons/button";
import Footer from "../../layouts/footer";
import ModalPWA from "../../components/modalPWA";
import "../../styles/pages/login.scss";
import VideoPlayer from "../../components/videoPlayer";

const HomePageWW = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  return (
    <>
      <MainContainer>
        <p>{t("homeWW.welcome")}</p>
        <VideoPlayer
          url="/videos/Worldwide 2024 HomeWeb.mp4"
          title={t("videos.home")}
        />
        <a href="/login">
          <Button size="btn-s" label={t("main.getInto")} />
        </a>
        <br />
        <div className="forgot-password">
          <div>
            <span>{t("login.registerQuestion")}</span>
            <Link to="/register">{t("login.registerHere")}</Link>
          </div>
        </div>
      </MainContainer>
      <ModalPWA />
      <Footer />
    </>
  );
};

export default HomePageWW;
