import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import {
  callApi,
  getCodeIsValid,
  confirmEmail,
} from "../../services/apiService";
import "../../styles/pages/login.scss";
import { MdCheckCircle } from "react-icons/md";
import Button from "../../components/buttons/button";
import MainContainer from "../../layouts/container/mainContainer";
import Footer from "../../layouts/footer";

const ConfirmationSuccess = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tokenCode = urlParams.get("tk_id");
    const token = {
      token: tokenCode,
    };
    if (!tokenCode) {
      navigate("/");
      return;
    }
    callApi(
      () => getCodeIsValid(token),
      (data) => {
        if (data.email) {
          return setEmail(data.email);
        } else {
          NotificationManager.error(t("Token invalido"));
          navigate("/");
          return;
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ConfirmEmailBtn = (data) => {
    const info = {
      email: data,
    };
    callApi(confirmEmail(info), () => {
      NotificationManager.success("Email confirmado");
      navigate("/");
      return;
    });
  };

  return (
    <>
      <MainContainer class="ww-container-m">
        <h1 className="icon-xl">
          <MdCheckCircle />
        </h1>
        <p>{t("register.confirm.success")}</p>
        <Button
          size="btn-s"
          label={t("register.confirm")}
          onClick={() => {
            ConfirmEmailBtn(email);
            navigate("/");
          }}
        />
      </MainContainer>
      <Footer />
    </>
  );
};

export default ConfirmationSuccess;
