import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../layouts/footer";
import InterviewWwcs from "./interviewWwcs";
import FormMaterial from "./formMaterial";
import VideoAndCv from "./videoAndCv";
import MainContainer from "../../layouts/container/mainContainer";
import Button from "../../components/buttons/button";
import { generalStatesPostulation } from "../../constants/enum";
import { getPostulationState, getProgramId } from "../../services/authService";
import UploadingFiles from "./uploadingFiles";
import General from "./general";
import InterviewConsular from "./interviewConsular";
import EmployerSelection from "./employerSelection";
import InterviewWork from "./interviewWork";
import JobOffer from "./jobOffer";
import FlightAndAccommodation from "./flightAndAccommodation";
import PreTripTraining from "./preTripTraining";
import PendingAnswer from "./pendingAnswer";
import MockInterview from "./mockInterview";

const EducationalMaterial = () => {
  const { t } = useTranslation();
  const statePostulation = getPostulationState();
  const programId = getProgramId();

  const materialPage = (postulationState, programId) => {
    switch (postulationState) {
      case generalStatesPostulation.postulationFormPending:
      case generalStatesPostulation.postulationForm:
      case generalStatesPostulation.uploadingFilesPending:
      case generalStatesPostulation.uploadingFiles:
        return (
          <MainContainer showImage={false}>
            <FormMaterial courseId={programId} />
          </MainContainer>
        );
      // return (
      //   <MainContainer showImage={false}>
      //     <UploadingFiles />
      //   </MainContainer>
      // );
      case generalStatesPostulation.interviewWwcePending:
      case generalStatesPostulation.interviewWwce:
        return (
          <MainContainer showImage={false}>
            <InterviewWwcs />
          </MainContainer>
        );
      case generalStatesPostulation.videoAndCvPending:
      case generalStatesPostulation.videoAndCv:
        return (
          <MainContainer showImage={false}>
            <VideoAndCv />
          </MainContainer>
        );
      case generalStatesPostulation.employerSelectionPending:
      case generalStatesPostulation.employerSelection:
        return (
          <MainContainer showImage={false}>
            <EmployerSelection />
          </MainContainer>
        );
      case generalStatesPostulation.interviewWorkPending:
      case generalStatesPostulation.interviewWork:
        return (
          <MainContainer showImage={false}>
            <InterviewWork />
          </MainContainer>
        );
      case generalStatesPostulation.jobOffer:
        return (
          <MainContainer showImage={false}>
            <JobOffer />
          </MainContainer>
        );
      case generalStatesPostulation.mockInterviewPending:
      case generalStatesPostulation.mockInterview:
        return (
          <MainContainer showImage={false}>
            <MockInterview />
          </MainContainer>
        );
      case generalStatesPostulation.interviewConsularPending:
      case generalStatesPostulation.interviewConsular:
        return (
          <MainContainer showImage={false}>
            <InterviewConsular />
          </MainContainer>
        );
      case generalStatesPostulation.flightAndAccommodationPending:
      case generalStatesPostulation.flightAndAccommodation:
        return (
          <MainContainer showImage={false}>
            <FlightAndAccommodation />
          </MainContainer>
        );
      case generalStatesPostulation.preTripTraining:
        return (
          <MainContainer showImage={false}>
            <PreTripTraining />
          </MainContainer>
        );
      case generalStatesPostulation.pendingAnswer:
        return (
          <MainContainer showImage={false}>
            <PendingAnswer />
          </MainContainer>
        );
      default:
        return (
          <MainContainer class="ww-container-xxl" showImage={false}>
            <General />
          </MainContainer>
        );
    }
  };

  const handleCloseTab = () => {
    window.close();
  };

  return (
    <>
      {materialPage(statePostulation, programId)}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <a href="" onClick={handleCloseTab}>
          <Button size="btn-m" label={t("main.back")} />
        </a>
        {/* <a href="/registration-form" target="_blank" onClick={handleCloseTab}>
          <Button size="btn-m" label={t("main.back")} />
        </a> */}
      </div>
      <Footer />
    </>
  );
};

export default EducationalMaterial;
