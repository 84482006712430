import React, { useEffect, useState } from "react";
import { AiOutlinePlus, AiOutlineFileAdd } from "react-icons/ai";
import { BsPencilFill } from "react-icons/bs";
import {
  callApi,
  getEmployers,
  deleteEmployees,
} from "../../services/apiService";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import Modal from "@mui/material/Modal";
import MainContainer from "../../layouts/container/mainContainer";
import Footer from "../../layouts/footer";
import Table, { defaultOptions, defaultCol } from "../../components/table";
import Button from "../../components/buttons/button";
import "../../styles/pages/employee.scss";
import FilesModal from "./filesModal";
import AddEmployerModal from "./addEmployer";
import EmployerPositionModal from "./employerPostitionModal";

const EmployeesPage = () => {
  const { t } = useTranslation();

  const getObj = (tableMeta) =>
    employers.find((x) => x.id === tableMeta.rowData[0]);

  const [openAddModal, setOpenAddModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [alertModal, setAlertModal] = useState(false);
  const [employers, setEmployers] = useState([]);
  const [botonActivo, setBotonActivo] = useState(false);
  const [currentEmployer, setCurrentEmployer] = useState([]);
  const [openFilesModal, setOpenFilesModal] = useState(false);
  const [openPositionModal, setOpenPositionModal] = useState(false);

  const handleOpenAddModal = () => setOpenAddModal(true);
  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    loadEmployees();
  };
  const handleCloseFilesModal = () => setOpenFilesModal(false);
  const handleCloseAlertModal = () => setAlertModal(false);
  const handleClosePositionModal = () => setOpenPositionModal(false);

  useEffect(() => {
    loadEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStateById = (id) => {
    if (id == null) {
      return "";
    }
    const stateTmp = [
      { id: 1, name: t("employer.active") },
      { id: 2, name: t("employer.inactive") },
    ];
    var stateSelected = stateTmp.find((l) => l.id == id);
    return (stateSelected = !null ? stateSelected.name : "");
  };

  const loadEmployees = () => {
    callApi(
      () => getEmployers(),
      (data) => {
        data.map((item) => {
          item.state = getStateById(item.state);
        });
        setEmployers(data);
      }
    );
  };

  const onDelete = (data) => {
    setBotonActivo(true);
    const id = {
      id: data,
    };
    callApi(
      () => deleteEmployees(id),
      () => {
        NotificationManager.warning(t("employer.Delete"));
        loadEmployees();
      }
    );
    setBotonActivo(false);
    setAlertModal(false);
  };

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    defaultCol("state", "employer.state"),
    defaultCol("company_name", "employer.text"),
    {
      name: "website",
      label: t("employer.page"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value) => (
          <a href={value} target="_blank" rel="noopener noreferrer">
            {t("employer.toPage")}
          </a>
        ),
      },
    },
    {
      name: "Actions",
      label: t("userList.actions"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, employers, updateValue) => {
          var rowObj = getObj(employers);
          return (
            <div className="table-btn-group">
              <Button
                disabled={botonActivo}
                label={<BsPencilFill />}
                onClick={() => {
                  setCurrentEmployer(rowObj);
                  handleOpenAddModal();
                }}
              />
              <Button
                disabled={botonActivo}
                label={<AiOutlinePlus />}
                onClick={() => {
                  setCurrentEmployer(rowObj);
                  setOpenPositionModal(true);
                }}
              />
              {/* <Button
                disabled={botonActivo}
                label={<MdDelete />}
                onClick={() => {
                  setDeleteId(rowObj.id);
                  setAlertModal(true);
                }}
              /> */}
              <Button
                disabled={botonActivo}
                label={<AiOutlineFileAdd />}
                onClick={() => {
                  setCurrentEmployer(rowObj);
                  setOpenFilesModal(true);
                }}
              />
            </div>
          );
        },
      },
    },
  ];

  return (
    <>
      <MainContainer class="ww-container-xxl" showImage={false}>
        <h1 style={{ margin: "20px 0 20px 0" }} className="title">
          {t("main.employers")}
        </h1>
        <div>
          <Button
            label={<AiOutlinePlus />}
            onClick={() => {
              setCurrentEmployer(null);
              handleOpenAddModal();
            }}
          />
          <Table
            data={employers}
            columns={columns}
            options={defaultOptions()}
          />
        </div>
        <AddEmployerModal
          currentEmployer={currentEmployer}
          open={openAddModal}
          onClose={handleCloseAddModal}
        />

        <Modal
          open={alertModal}
          onClose={handleCloseAlertModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MainContainer class="ww-container-m">
            <p>{t("alert.deleteEmployer")}</p>
            <div>
              <Button
                type="ww-btn-secondary"
                style={{ margin: "10px" }}
                size="btn-s secondary"
                label={t("main.cancel")}
                onClick={() => setAlertModal(false)}
              />
              <Button
                style={{ margin: "10px" }}
                size="btn-s"
                label={t("main.delete")}
                onClick={() => onDelete(deleteId)}
              />
            </div>
          </MainContainer>
        </Modal>
      </MainContainer>
      <Footer />
      {/* MODAL files */}
      <FilesModal
        open={openFilesModal}
        onClose={handleCloseFilesModal}
        currentEmployer={currentEmployer}
      />
      {/* MODAL Position */}
      <EmployerPositionModal
        open={openPositionModal}
        onClose={handleClosePositionModal}
        currentEmployer={currentEmployer}
        setCurrentEmployer={setCurrentEmployer}
        loadEmployees={loadEmployees}
      />
    </>
  );
};

export default EmployeesPage;
