import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  callApi,
  saveTravelerFormation,
  getTravelerFormation,
} from "../../services/apiService";
import { getUserInfo } from "../../services/authService";
import { NotificationManager } from "react-notifications";
import Button from "../../components/buttons/button";
import { FormProvider, useForm } from "react-hook-form";
import DateInput from "../../components/forms/inputs/dateInput";
import { FormSteps, generalStatesPostulation } from "../../constants/enum";
import HelpButton from "../../components/buttons/helpButton/index";

const UniversityInformation = ({
  setState,
  postulationState,
  setUniversityData,
  programName,
}) => {
  const { t } = useTranslation();
  const [idTravelerFormation, setIdTravelerFormation] = useState({});

  const defaultValues = {};
  const loadDataForm = (obj) => {
    setValue(
      "universityName",
      !obj || !obj.name_university ? "" : obj.name_university
    );
    setValue(
      "universityField",
      !obj || !obj.field_study ? "" : obj.field_study
    );
    setValue(
      "lengthCourse",
      !obj || !obj.length_course ? "" : obj.length_course
    );
    setValue(
      "yearInUniversity",
      !obj || !obj.year_of_education ? "" : obj.year_of_education
    );
    setValue(
      "uniMatriculationDate",
      !obj || !obj.matriculation_date ? "" : new Date(obj.matriculation_date)
    );
    setValue(
      "uniGraduationDate",
      !obj || !obj.graduation_date ? "" : new Date(obj.graduation_date)
    );
    setValue(
      "UniBreakBegins",
      !obj || !obj.university_break_begin
        ? ""
        : new Date(obj.university_break_begin)
    );
    setValue(
      "UniBreakEnd",
      !obj || !obj.university_break_end
        ? ""
        : new Date(obj.university_break_end)
    );
  };
  const methods = useForm({ defaultValues });
  const {
    control,
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    const uId = getUserInfo().id;
    callApi(
      () => getTravelerFormation({ uId: uId }),
      (data) => {
        loadDataForm(data);
        setIdTravelerFormation(data.id);
      }
    );
  }, []);

  const validations = {
    universityName: {
      required: {
        value: true,
        message: t("validationError.universityName.required"),
      },
    },
    universityField: {
      required: {
        value: true,
        message: t("validationError.universityField.required"),
      },
    },
    lengthCourse: {
      required: {
        value: true,
        message: t("validationError.lengthCourse.required"),
      },
    },
    yearInUniversity: {
      required: {
        value: true,
        message: t("validationError.yearInUniversity.required"),
      },
      pattern: {
        value: /^[0-9]+$/,
        message: t("validationError.yearInUniversity.numeric"),
      },
    },
    uniMatriculationDate: {
      required: {
        value: true,
        message: t("validationError.uniMatriculationDate.required"),
      },
    },
    uniGraduationDate: {
      required: {
        value: true,
        message: t("validationError.uniGraduationDate.required"),
      },
    },
    UniBreakBegins: {
      required: {
        value: true,
        message: t("validationError.UniBreakBegins.required"),
      },
    },
    UniBreakEnd: {
      required: {
        value: true,
        message: t("validationError.UniBreakEnd.required"),
      },
    },
  };

  const onSubmitFormation = handleSubmit((data) => {
    const uId = getUserInfo().id;

    data.uniMatriculationDate = new Date(data.uniMatriculationDate);
    data.uniMatriculationDate.setHours(0, 0, 0, 0);
    data.uniGraduationDate = new Date(data.uniGraduationDate);
    data.uniGraduationDate.setHours(0, 0, 0, 0);
    data.UniBreakBegins = new Date(data.UniBreakBegins);
    data.UniBreakBegins.setHours(0, 0, 0, 0);
    data.UniBreakEnd = new Date(data.UniBreakEnd);
    data.UniBreakEnd.setHours(0, 0, 0, 0);

    const defaultDate = new Date("1900-01-01T00:00:00.000Z");

    if (!data.uniMatriculationDate) {
      data.uniMatriculationDate = defaultDate;
    }
    if (!data.uniGraduationDate) {
      data.uniGraduationDate = defaultDate;
    }
    if (!data.UniBreakBegins) {
      data.UniBreakBegins = defaultDate;
    }
    if (!data.UniBreakEnd) {
      data.UniBreakEnd = defaultDate;
    }

    callApi(
      () =>
        saveTravelerFormation({
          ...data,
          uId,
          idTravelerFormation,
          postulationState: postulationState,
        }),
      () => {
        setUniversityData(data);
        NotificationManager.success(t("form.success"));
        setState(FormSteps.VisaInformation);
      }
    );
  });

  const backForm = () => {
    setState(FormSteps.ContactDetails);
  };

  const [setShowBreakBeginsMessage] = useState(false);

  const handleBreakBeginsHelpClick = () => {
    setShowBreakBeginsMessage(true);
  };
  const uniBreakBeginsValue = watch("UniBreakBegins");
  const uniBreakEndValue = watch("UniBreakEnd");
  useEffect(() => {
    if (uniBreakEndValue && uniBreakBeginsValue > uniBreakEndValue) {
      setValue("UniBreakEnd", "");
    }
  }, [uniBreakBeginsValue]);

  return (
    <>
      <h2 className="title">{t("form.program") + programName}</h2>
      <h2>{t("form.universityTitle")}</h2>
      <FormProvider {...methods}>
        <form>
          <div className="container-inputs">
            {/* UniversityName */}
            <div className="inputs-style">
              <label>
                {t("form.university.name")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("form.university.name.ph")}
                className="form-control mb-2"
                name="universityName"
                {...register("universityName", validations.universityName)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.universityName && errors.universityName.message}
              </span>
            </div>
            {/* StudyField */}
            <div className="inputs-style">
              <label>
                {t("form.university.field")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("form.university.field.ph")}
                className="form-control mb-2"
                name="universityField"
                {...register("universityField", validations.universityField)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.universityField && errors.universityField.message}
              </span>
            </div>
          </div>
          <div className="container-inputs">
            {/*LengthCourse */}
            <div className="inputs-style">
              <label>
                {t("form.university.lengthCourse")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("form.university.lengthCourse.ph")}
                className="form-control mb-2"
                name="lengthCourse"
                {...register("lengthCourse", validations.lengthCourse)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.lengthCourse && errors.lengthCourse.message}
              </span>
            </div>
            {/*YearEducation */}
            <div className="inputs-style">
              <label>
                {t("form.university.yearInUniversity")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("form.university.yearInUniversity")}
                className="form-control mb-2"
                name="yearInUniversity"
                {...register("yearInUniversity", validations.yearInUniversity)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.yearInUniversity && errors.yearInUniversity.message}
              </span>
            </div>
          </div>
          {/* Matriculation date */}
          <div className="container-inputs">
            <div className="inputs-style">
              <DateInput
                name="uniMatriculationDate"
                control={control}
                placeholder={t("form.university.matriculation.ph")}
                label={
                  <label>
                    {t("form.university.matriculation.ph")}
                    <span className="text-danger"> *</span>
                  </label>
                }
                {...register(
                  "uniMatriculationDate",
                  validations.uniMatriculationDate
                )}
              />
              <span className="text-danger text-small d-block mb-2">
                {errors.uniMatriculationDate &&
                  errors.uniMatriculationDate.message}
              </span>
            </div>
            {/* Graduation date */}
            <div className="inputs-style">
              <DateInput
                minDate={watch("uniMatriculationDate")}
                name="uniGraduationDate"
                control={control}
                placeholder={t("form.university.graduation.ph")}
                label={
                  <label>
                    {t("form.university.graduation.ph")}
                    <span className="text-danger"> *</span>
                  </label>
                }
                {...register(
                  "uniGraduationDate",
                  validations.uniGraduationDate
                )}
              />
              <span className="text-danger text-small d-block mb-2">
                {errors.uniGraduationDate && errors.uniGraduationDate.message}
              </span>
            </div>
          </div>
          <div className="container-inputs">
            {/* break begins */}
            <div className="inputs-style">
              <DateInput
                name="UniBreakBegins"
                control={control}
                placeholder={t("form.university.breackB")}
                label={
                  <div className="labelWithButton">
                    <label className="labelButton">
                      {t("form.university.breackB")}
                      <span className="text-danger"> *</span>
                    </label>
                    <HelpButton
                      message={"Colocar fecha aproximada del 2024 a confirmar"}
                      onClick={handleBreakBeginsHelpClick}
                    />
                  </div>
                }
                {...register("UniBreakBegins", validations.UniBreakBegins)}
              />
              <span className="text-danger text-small d-block mb-2">
                {errors.UniBreakBegins && errors.UniBreakBegins.message}
              </span>
            </div>
            {/* break ends */}
            <div className="inputs-style">
              <DateInput
                name="UniBreakEnd"
                minDate={watch("UniBreakBegins")}
                control={control}
                placeholder={t("form.university.breackE")}
                label={
                  <div className="labelWithButton">
                    <label className="labelButton">
                      {t("form.university.breackE")}
                      <span className="text-danger"> *</span>
                    </label>
                    <HelpButton
                      message={"Colocar fecha aproximada del 2025 a confirmar"}
                      onClick={handleBreakBeginsHelpClick}
                    />
                  </div>
                }
                {...register("UniBreakEnd", validations.UniBreakEnd)}
              />
              <span className="text-danger text-small d-block mb-2">
                {errors.UniBreakBegins && errors.UniBreakBegins.message}
              </span>
            </div>
          </div>
        </form>
      </FormProvider>
      <div className="container-inputs">
        <Button
          size="btn-sx"
          type="ww-btn-secondary"
          label={t("main.back")}
          onClick={() => backForm()}
        />
        <Button
          size="btn-sx"
          onClick={onSubmitFormation}
          label={t("main.next")}
        />
      </div>
      {postulationState >= generalStatesPostulation.uploadingFiles && (
        <Button
          type="ww-btn-secondary"
          onClick={() => {
            setState(FormSteps.FinishedForm);
          }}
          label={t("main.backPostulation")}
        />
      )}
    </>
  );
};

export default UniversityInformation;
