import axios from "axios";
import { getUserInfo } from "./authService";

function getTokenInfo() {
  var userInfo = getUserInfo();
  if (userInfo) {
    return { token: userInfo.token };
  }
  return { token: "" };
}

axios.interceptors.request.use(
  function (config) {
    const { token } = getTokenInfo();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};

export default http;
