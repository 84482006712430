import React, { useEffect, useState } from "react";
import { AiFillEye, AiOutlinePlus } from "react-icons/ai";
import { MdDelete, MdOutlineFileDownload } from "react-icons/md";
import {
  callApi,
  uploadImages,
  getFilesNoConditions,
  deleteImage,
  changeState,
} from "../../services/apiService";
import Modal from "@mui/material/Modal";
import MainContainer from "../../layouts/container/mainContainer";
import { FormProvider, useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { getUserInfo } from "../../services/authService";
import { useTranslation } from "react-i18next";
import { FileTypeId, FormSteps, fileType } from "../../constants/enum";
import Button from "../../components/buttons/button";
import AutocompleteSelect from "../../components/forms/inputsMui/autocomplete";
import Table, { defaultOptions } from "../../components/table";

const urlBase = process.env.REACT_APP_URL_BASE;
const urlApi = process.env.REACT_APP_API_URL;

const UploadFiles = ({ setState, postulationState, programName }) => {
  const { t } = useTranslation();
  const isAndroid = /Android/i.test(navigator.userAgent);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deletePath, setDeletePath] = useState("");
  const [alertModal, setAlertModal] = useState(false);
  const [previewView, setPreviewModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [botonActivo, setBotonActivo] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  var userInfo = getUserInfo();

  const backForm = () => {
    setState(FormSteps.GeneralConditions);
  };

  const nextForm = (array) => {
    if (!array.find((element) => element.file_type_id === FileTypeId.Picture)) {
      NotificationManager.warning(t("files.validations.picture"));
      return;
    }
    if (
      !array.find(
        (element) => element.file_type_id === FileTypeId.PassportVigent
      )
    ) {
      NotificationManager.warning(t("files.validations.passport"));
      return;
    }
    const uId = getUserInfo().id;
    const submitData = {
      uId: uId,
      state: FormSteps.SignStep,
      postulationState: postulationState,
    };
    callApi(() => changeState(submitData));
    NotificationManager.success(t("form.success"));
    setState(FormSteps.SignStep);
  };

  const userId = {
    userId: userInfo.id,
  };

  const defaultValues = {};
  const methods = useForm({ defaultValues });

  const getObj = (tableMeta) =>
    documents.find((x) => x.id === tableMeta.rowData[0]);

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const validations = {
    file_type_id: {
      required: {
        value: true,
        message: t("validationError.file_type_id.require"),
      },
    },
    file: {
      required: {
        value: true,
        message: t("image.requireImage"),
      },
    },
  };

  const onSubmit = handleSubmit((data) => {
    var formData = new FormData();
    formData.append("userId", userInfo.id);
    formData.append("image", data.image[0]);
    formData.append("file_type_id", data.file_type_id);
    if (
      documents.find(
        (element) =>
          element.file_type_id == data.file_type_id &&
          (data.file_type_id == FileTypeId.Picture ||
            data.file_type_id == FileTypeId.PassportVigent ||
            data.file_type_id == FileTypeId.Certify ||
            data.file_type_id == FileTypeId.Cv)
      )
    ) {
      NotificationManager.warning(t("files.validations.duplicated"));
      return;
    } else {
      setBotonActivo(true);
      callApi(
        () => uploadImages(formData),
        () => {
          NotificationManager.success(t("template.uploadSucces"));
          loadData(userId);
          handleClose();
        }
      );
    }
    setBotonActivo(false);
  });

  const callDeleteImage = (id, path) => {
    const data = {
      imageId: id,
      imagePath: path,
    };
    callApi(
      () => deleteImage(data),
      (data) => {
        loadData(userId);
        setAlertModal(false);
      }
    );
  };

  const loadData = (userId) => {
    callApi(
      () => getFilesNoConditions(userId),
      (data) => {
        setDocuments(data);
      }
    );
  };

  useEffect(() => {
    loadData(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cleanInputs = () => {
    setValue("file_type_id", "");
    setValue("file", "");
  };

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    //defaultCol("file_type_id", "files.type"),
    {
      name: "file_type_id",
      label: t("files.type"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, documents, updateValue) => {
          var rowObj = getObj(documents);

          switch (rowObj.file_type_id) {
            case FileTypeId.Picture:
              return t("form.picture");
            case FileTypeId.PassportVigent:
              return t("form.passportvigent");
            case FileTypeId.Certify:
              return t("form.certify");
            case FileTypeId.ExVisaJ1:
              return t("form.ExVisaJ1");
            case FileTypeId.Other:
              return t("form.other");
            case FileTypeId.Cv:
              return t("form.CV");
            default:
              return t("form.other");
          }
        },
      },
    },
    {
      name: "Actions",
      //label: t("Link"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, documents, updateValue) => {
          var rowObj = getObj(documents);
          return (
            <div className="table-btn-group">
              {!isAndroid && (
                <Button
                  // disabled={botonActivo}
                  label={<AiFillEye />}
                  onClick={() => {
                    setPreviewModal(true);
                    setDeletePath(rowObj.path);
                    // loadDocumentData(rowObj);
                  }}
                />
              )}

              <Button
                // disabled={botonActivo}
                label={<MdDelete />}
                onClick={() => {
                  setDeleteId(rowObj.id);
                  setDeletePath(rowObj.path);
                  setAlertModal(true);
                }}
              />
              <a
                href={urlBase + "/" + rowObj.path.replace("WorldWideFiles", "")}
                download
                target="_blank"
              >
                <Button size="btn-m" label={<MdOutlineFileDownload />} />
              </a>
            </div>
          );
        },
      },
    },
  ];

  return (
    <>
      <h2 className="title">{t("form.program") + programName}</h2>
      <h2>{t("form.fileTitle")}</h2>
      <div>
        {t("uploadFiles.message.1")}
        <ul>
          <li>{t("uploadFiles.message.1.1")}</li>
          <li>{t("uploadFiles.message.1.2")}</li>
          <li>{t("uploadFiles.message.1.3")}</li>
        </ul>
      </div>
      <Button
        label={t("uploadFiles.upload")}
        onClick={() => {
          setBotonActivo(false);
          cleanInputs();
          handleOpen();
        }}
      />

      <Table data={documents} columns={columns} options={defaultOptions()} />
      <div className="container-inputs container-inputs-topmargen">
        <Button
          size="btn-sx"
          type="ww-btn-secondary"
          label={t("main.back")}
          onClick={() => backForm()}
        />
        <Button
          size="btn-sx"
          onClick={() => nextForm(documents)}
          label={t("main.next")}
        />
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m">
          <br />
          <FormProvider {...methods}>
            <form encType="multipart/form-data" className="form-m">
              <div className="inputs-style">
                <label>{t("form.fileLabel")}</label>
                <AutocompleteSelect
                  name="file_type_id"
                  label={t("files.add")}
                  options={fileType()}
                  register={register}
                  registerOptions={validations}
                  watch={watch}
                  setValue={setValue}
                />
                <span className="text-danger text-small d-block mb-2">
                  {errors.file_type_id && errors.file_type_id.message}
                </span>
                <p>{t("form.labelII")}</p>
              </div>
              <div className="mb-3">
                <input
                  className="form-control"
                  type="file"
                  id="image"
                  name="image"
                  {...register("image", validations.file)}
                />
                <span
                  style={{ margin: "10px 0 0 0" }}
                  className="text-danger text-small d-block mb-2"
                >
                  {errors.image && errors.image.message}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  type="ww-btn-secondary"
                  size="btn-s"
                  label={t("main.cancel")}
                  onClick={() => handleClose()}
                  disabled={botonActivo}
                />
                <Button
                  size="btn-s"
                  label={t("uploadFiles.upload")}
                  onClick={onSubmit}
                  disabled={botonActivo}
                />
              </div>
            </form>
          </FormProvider>
        </MainContainer>
      </Modal>
      {/* DELETE FILE */}
      <Modal
        open={alertModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m">
          <p>{t("alert.deletedocument")}</p>
          <div style={{ display: "flex" }}>
            <Button
              type="ww-btn-secondary"
              style={{ margin: "10px" }}
              size="btn-sx secondary"
              label={t("main.cancel")}
              onClick={() => setAlertModal(false)}
            />
            <Button
              style={{ margin: "10px" }}
              size="btn-sx"
              label={t("main.delete")}
              onClick={() => callDeleteImage(deleteId, deletePath)}
            />
          </div>
        </MainContainer>
      </Modal>

      <Modal
        open={previewView}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer class="ww-container-m" Nologo={true}>
          <p>{t("form.previewView")}</p>
          <embed
            src={urlBase + "/" + deletePath.replace("WorldWideFiles", "")}
            className="file-preview-view"
          />
          <div>
            <Button
              style={{ margin: "10px" }}
              size="btn-s secondary"
              label={t("main.close")}
              onClick={() => setPreviewModal(false)}
            />
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default UploadFiles;
