import React from "react";
import { useTranslation } from "react-i18next";

const PreTripTraining = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className="title">{t("general.preTripTraining")}</h2>
    </>
  );
};

export default PreTripTraining;
