import React from "react";
import { useNavigate } from "react-router-dom";
import { MdMarkEmailRead } from "react-icons/md";
import { useTranslation } from "react-i18next";
import MainContainer from "../../layouts/container/mainContainer";
import Footer from "../../layouts/footer";
import Button from "../../components/buttons/button";
import "../../styles/pages/login.scss";

const EmailSend = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  return (
    <>
      <MainContainer class="ww-container-m">
        <h1 className="icon-xl">
          <MdMarkEmailRead />
        </h1>
        <p className="text-center">{t("recoverPassword.send.text")}</p>
        <Button
          size="btn-s"
          label={t("main.goToStart")}
          onClick={() => navigate("/")}
        />
      </MainContainer>
      <Footer />
    </>
  );
};

export default EmailSend;
