import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BsPencilFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import Modal from "@mui/material/Modal";
import MainContainer from "../../layouts/container/mainContainer";
import {
  callApi,
  editRelative,
  saveTravelerRelative,
  getTravelerRelatives,
  deleteRelative,
} from "../../services/apiService";
import { getUserInfo } from "../../services/authService";
import { NotificationManager } from "react-notifications";
import Button from "../../components/buttons/button";
import { FormProvider, useForm } from "react-hook-form";
import Table, { defaultOptions, defaultCol } from "../../components/table";

const CloseRelatives = ({ setActiveListRelative }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [botonActivo, setBotonActivo] = useState(false);
  const [relativesList, setRelativesList] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [editId, seteditId] = useState("");
  const [alertModal, setAlertModal] = useState(false);
  const [edit, setEdit] = useState(false);

  const handleClose = () => setOpen(false);

  const getObj = (tableMeta) =>
    relativesList.find((x) => x.id === tableMeta.rowData[0]);

  const loadRelativesData = (obj) => {
    setValue2("closeRelativesName", !obj || !obj.name ? "" : obj.name);
    setValue2("closeRelativesCity", !obj || !obj.relationship ? "" : obj.city);
    setValue2(
      "closeRelativesRelation",
      !obj || !obj.relationship ? "" : obj.relationship
    );
  };
  const cleanInputs = () => {
    setValue2("closeRelativesName", "");
    setValue2("closeRelativesCity", "");
    setValue2("closeRelativesRelation", "");
  };
  const loadRelatives = (id) => {
    callApi(
      () => getTravelerRelatives({ uId: id }),
      (data) => {
        setRelativesList(data);
      }
    );
  };

  useEffect(() => {
    setActiveListRelative(relativesList.length === 0);
  }, [relativesList]);

  const defaultValues = {};
  const methods2 = useForm({ defaultValues });
  const {
    setValue: setValue2,
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
  } = methods2;

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    defaultCol("name", "form.relatives.names"),
    defaultCol("relationship", "form.relatives.relation"),
    defaultCol("city", "form.relatives.city"),
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, employee, updateValue) => {
          var rowObj = getObj(employee);
          return (
            <div className="table-btn-group">
              <Button
                disabled={botonActivo}
                label={<BsPencilFill />}
                onClick={() => {
                  setEdit(true);
                  seteditId(rowObj.id);
                  setOpen(true);
                  loadRelativesData(rowObj);
                }}
              />
              <Button
                disabled={botonActivo}
                label={<MdDelete />}
                onClick={() => {
                  setDeleteId(rowObj.id);
                  setAlertModal(true);
                }}
              />
            </div>
          );
        },
      },
    },
  ];

  useEffect(() => {
    const uId = getUserInfo().id;
    callApi(
      () => getTravelerRelatives({ uId: uId }),
      (data) => {
        setRelativesList(data);
      }
    );
  }, []);

  const onSubmitRelative = handleSubmit2((data) => {
    const uId = getUserInfo().id;
    callApi(
      () => saveTravelerRelative({ ...data, uId }),
      (data) => {
        NotificationManager.success(t("form.relative.created"));
        setRelativesList(data);
        loadRelatives(uId);
        cleanInputs();
        handleClose();
      }
    );
  });

  const onEdit = handleSubmit2((data) => {
    const uId = getUserInfo().id;
    setBotonActivo(true);
    callApi(
      () => editRelative({ ...data, id: editId }),
      NotificationManager.success(t("form.success")),
      cleanInputs(),
      handleClose()
    );
    setBotonActivo(false);
    loadRelatives(uId);
  });

  const onDelete = (data) => {
    const uId = getUserInfo().id;
    setBotonActivo(true);
    const id = {
      id: data,
    };
    callApi(
      () => deleteRelative(id),
      () => {
        NotificationManager.warning(t("form.relative.delete"));
        loadRelatives(uId);
      }
    );
    setBotonActivo(false);
    setAlertModal(false);
  };

  const validations2 = {
    closeRelativesName: {
      required: { value: true, message: t("form.visa.error") },
    },
    closeRelativesRelation: {
      required: { value: true, message: t("form.visa.error") },
    },
    closeRelativesCity: {
      required: { value: true, message: t("form.visa.error") },
    },
  };
  return (
    <>
      <Button
        disabled={botonActivo}
        label={t("relative.addnew")}
        onClick={() => {
          setEdit(false);
          cleanInputs();
          setOpen(true);
        }}
      />

      <Table
        data={relativesList}
        columns={columns}
        options={defaultOptions()}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer>
          {edit ? (
            <h2 className="title">{t("form.relatives.editTitle")}</h2>
          ) : (
            <h2 className="title">{t("form.relatives.addTitle")}</h2>
          )}
          <FormProvider {...methods2}>
            <form>
              <div className="container-inputs">
                {/* closeRelativeName */}
                <div className="inputs-style">
                  <label>{t("form.visa.relatives.name")}</label>
                  <input
                    placeholder={t("form.visa.relatives.name")}
                    className="form-control mb-2"
                    name="closeRelativesName"
                    {...register2(
                      "closeRelativesName",
                      validations2.closeRelativesName
                    )}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors2.closeRelativesRelation &&
                      errors2.closeRelativesRelation.message}
                  </span>
                </div>
                {/* closeRelativesRelation*/}
                <div className="inputs-style">
                  <label>{t("form.visa.relatives.relation")}</label>
                  <input
                    placeholder={t("form.visa.relatives.relation")}
                    className="form-control mb-2"
                    name="closeRelativesRelation"
                    {...register2(
                      "closeRelativesRelation",
                      validations2.closeRelativesRelation
                    )}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors2.closeRelativesRelation &&
                      errors2.closeRelativesRelation.message}
                  </span>
                </div>
              </div>
              <div className="container-inputs">
                {/* closeRelativesCity */}
                <div className="inputs-style">
                  <label>{t("form.visa.relatives.city")}</label>
                  <input
                    placeholder={t("form.visa.relatives.city")}
                    className="form-control mb-2"
                    name="closeRelativesCity"
                    {...register2(
                      "closeRelativesCity",
                      validations2.closeRelativesCity
                    )}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors2.closeRelativesCity &&
                      errors2.closeRelativesCity.message}
                  </span>
                </div>
              </div>
              <br />
              {edit ? (
                <Button
                  size="btn-s"
                  disabled={botonActivo}
                  label={t("form.visa.relatives.edit")}
                  onClick={() => onEdit(editId)}
                />
              ) : (
                <Button
                  size="btn-s"
                  disabled={botonActivo}
                  onClick={onSubmitRelative}
                  label={t("form.visa.relatives.add")}
                />
              )}
            </form>
          </FormProvider>
        </MainContainer>
      </Modal>
      <Modal
        open={alertModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m">
          <div style={{ padding: "0 0 0 30px" }}>
            <p>{t("person.alert")}</p>
          </div>
          <div style={{display:"flex"}}>
            <Button
              style={{ margin: "10px" }}
              type="ww-btn-secondary"
              size="btn-sx secondary"
              label={t("main.cancel")}
              onClick={() => setAlertModal(false)}
            />
            <Button
              style={{ margin: "10px" }}
              size="btn-sx"
              label={t("main.delete")}
              onClick={() => onDelete(deleteId)}
            />
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default CloseRelatives;
