import React from "react";
import "../../styles/components/buttons.scss"
import { Tooltip } from "@mui/material";

const Button = (props) => {
  const size = !props.size? "ww-bt" : props.size
  const type = !props.type? "ww-btn-primary" : props.type
  const atr = { className:  `btn ${size} ${type}` , ...props };
  
  return <Tooltip title={props.tooltip??""}><button {...atr}>{props.label}</button></Tooltip>;
};

export default Button;
