import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/buttons/button";
import "../../styles/pages/postulationState.scss";
import {
  callApi,
  getWorkPreferencesByPostulation,
} from "../../services/apiService";
import MainContainer from "../../layouts/container/mainContainer";

import Modal from "@mui/material/Modal";
import Table, { defaultOptions, defaultCol } from "../../components/table";
import CvResume from "../registrationForm/courseSelect/cv/cvResume";

const CvModal = ({ open, close, postulationId, userId, isBlocked }) => {
  const { t } = useTranslation();
  const [cvId, setCvId] = useState(null);

  const handleClose = () => {
    close();
  };

  return (
    <>
      {/* MODAL Job Preferences */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer showImage={false} class="ww-container-xxl">
          <CvResume
            postulationId={postulationId}
            handleCloseVideoCvModal={close}
            openVideoCvModal={open}
            userId={userId}
            setCvId={setCvId}
            isBlocked = {isBlocked}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              type="ww-btn-secondary"
              size="btn-xs secondary"
              label={t("main.close")}
              onClick={handleClose}
            />
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default CvModal;
