import React from "react";

const FloatButton = (props) => {
  const onClick = () => {
    console.log("click");
  };

  return (
    <div className="floating-button-icon">
      <a href={props.href} target="_blank">
        <img
          className="d-block w-100"
          src={"/images/WorldWideHelpIcon.png"}
          alt="First slide"
        />
      </a>
    </div>
  );
};

export default FloatButton;
