import React from "react";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateInputRange = ({
  label,
  control,
  placeholder,
  name,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  maxDate,
  minDate,
}) => {
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  return (
    <>
      <label>{label}</label>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <DatePicker
            placeholderText={placeholder}
            onChange={onChange}
            selected={startDate}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            startDate={startDate}
            endDate={endDate}
            selectsRange
            minDate={minDate}
            maxDate={maxDate}

          />
        )}
      />
    </>
  );
};

export default DateInputRange;
