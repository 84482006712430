import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/buttons/button";
import "../../../../styles/pages/postulationState.scss";
import { callApi, saveCvEducation } from "../../../../services/apiService";
import MainContainer from "../../../../layouts/container/mainContainer";
import Modal from "@mui/material/Modal";
import { NotificationManager } from "react-notifications";
import { FormProvider, useForm } from "react-hook-form";
import DateInput from "../../../../components/forms/inputs/dateInput";

const CvEducationModal = ({
  userId,
  cvId,
  open,
  close,
  dataCvEducation,
  setDataCvEducation,
  loadDataCv,
}) => {
  const { t } = useTranslation();
  const [botonActivo, setBotonActivo] = useState(false);

  useEffect(() => {
    if (!open) {
      setDataCvEducation(null);
      return;
    }
    if (dataCvEducation != null) {
      setValue("institutionName", dataCvEducation.institutionName);
      setValue("location", dataCvEducation.location);
      setValue("degree", dataCvEducation.degree);
      setValue(
        "startDate",
        !dataCvEducation || !dataCvEducation.startDate
          ? ""
          : new Date(dataCvEducation.startDate)
      );
      setValue(
        "endDate",
        !dataCvEducation || !dataCvEducation.endDate
          ? ""
          : new Date(dataCvEducation.endDate)
      );
    }
  }, [open]);

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const validations = {
    institutionName: {
      required: { value: true, message: t("healthInfo.required") },
    },
    location: {
      required: { value: true, message: t("healthInfo.required") },
    },
    startDate: {
      required: { value: true, message: t("healthInfo.required") },
    },
    degree: {
      required: { value: true, message: t("healthInfo.required") },
    },
  };

  const clearData = () => {
    setValue("institutionName", "");
    setValue("location", "");
    setValue("startDate", "");
    setValue("endDate", "");
    setValue("degree", "");
  };

  const handleClose = () => {
    clearData();
    setDataCvEducation(null);
    reset();
    close();
  };

  const onSubmit = handleSubmit((data) => {
    const dataToSave = {
      id: dataCvEducation == null ? 0 : dataCvEducation.id,
      cvId: cvId == null ? 0 : cvId,
      userId: parseInt(userId),
      institutionName: data.institutionName,
      location: data.location,
      startDate: data.startDate,
      endDate: data.endDate,
      degree: data.degree,
    };
    callApi(
      () => saveCvEducation(dataToSave),
      () => {
        NotificationManager.success(t("form.success"));
        loadDataCv();
        handleClose();
      }
    );
  });

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer showImage={false} class="ww-container-xl">
          <h1 className="title">{}</h1>
          <FormProvider {...methods}>
            <form>
              <div className="container-inputs">
                <div className="inputs-style">
                  <label>
                    {t("cvEducation.institution")}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    placeholder={t("cvEducation.institution")}
                    className="form-control mb-2"
                    name="institutionName"
                    {...register(
                      "institutionName",
                      validations.institutionName
                    )}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors.institutionName && errors.institutionName.message}
                  </span>
                </div>
                <div className="inputs-style">
                  <label>
                    {t("cvEducation.city")}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    placeholder={t("cvEducation.city")}
                    className="form-control mb-2"
                    name="location"
                    {...register("location", validations.location)}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors.location && errors.location.message}
                  </span>
                </div>
              </div>
              <div className="container-inputs">
                <div className="inputs-style">
                  <DateInput
                    name="startDate"
                    maxDate={new Date()}
                    control={control}
                    placeholder={t("cvEducation.startDate")}
                    label={
                      <label>
                        {t("cvEducation.startDate")}
                        <span className="text-danger"> *</span>
                      </label>
                    }
                    {...register("startDate", validations.startDate)}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors.startDate && errors.startDate.message}
                  </span>
                </div>
                <div className="inputs-style">
                  <DateInput
                    name="endDate"
                    minDate={watch("startDate")}
                    control={control}
                    placeholder={t("cvEducation.endDate")}
                    label={<label>{t("cvEducation.endDate")}</label>}
                    {...register("endDate", validations.endDate)}
                  />
                </div>
              </div>
              <div className="container-inputs">
                <div className="inputs-style">
                  <label>
                    {t("cvEducation.degree")}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    placeholder={t("cvEducation.degree")}
                    className="form-control mb-2"
                    name="degree"
                    {...register("degree", validations.degree)}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors.degree && errors.degree.message}
                  </span>
                </div>
              </div>
              <div className="btn-container">
                <Button
                  type="ww-btn-secondary"
                  size="btn-sx"
                  label={t("main.close")}
                  onClick={() => {
                    handleClose();
                  }}
                />
                <Button
                  size="btn-sx"
                  label={t("main.getInto")}
                  onClick={onSubmit}
                />
              </div>
            </form>
          </FormProvider>
        </MainContainer>
      </Modal>
    </>
  );
};

export default CvEducationModal;
