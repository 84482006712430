import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/buttons/button";
import "../../styles/pages/postulationState.scss";
import { callApi, savePostulationOrder } from "../../services/apiService";
import MainContainer from "../../layouts/container/mainContainer";
import Modal from "@mui/material/Modal";
import { FormProvider, useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";

const OrderModal = ({
  open,
  close,
  postulationId,
  loadDataTable,
  fullName,
  orderPostulation
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if(!open) return;
    loadData();
  }, [open])

  const handleClose = () => {
    reset();
    close();
  };

  const validations = {
    orderPostulation: {
      required: { value: true, message: t("healthInfo.required") },
    },
  };
  const loadData = () => {
    setValue("orderPostulation", !orderPostulation ? "" : orderPostulation);
  };
  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const submitData = {
      postulationId: parseInt(postulationId),
      order: data.orderPostulation,
    };
    try {
      const response = await callApi(
        () => savePostulationOrder(submitData),
        (data) => {
          console.log("response data", data);
          if (data) {
            NotificationManager.success(t("form.success"));
            handleClose();
            loadDataTable();
          } else {
            NotificationManager.error(t("orderModal.errorDuplicated"));
          }
        }
      );
    } catch (error) {
        console.log(error)
    }
  });

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer class="ww-container-m" showImage={false}>
          <h1 style={{ margin: "20px 0 20px 0" }} className="title">
            {t("orderModal.title")}
            {fullName}
          </h1>
          <FormProvider {...methods}>
            <form>
              <div className="container-inputs">
                <div className="inputs-style">
                  <label>
                    {t("userList.order")}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    placeholder={t("userList.order")}
                    className="form-control mb-2"
                    name="orderPostulation"
                    {...register(
                      "orderPostulation",
                      validations.orderPostulation
                    )}
                  />
                  <span className="text-danger text-small d-block mb-2 validation-margin">
                    {errors.orderPostulation && errors.orderPostulation.message}
                  </span>
                </div>
              </div>
            </form>
          </FormProvider>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              type="ww-btn-secondary"
              size="btn-xs secondary"
              label={t("main.close")}
              onClick={handleClose}
            />
            <Button
              size="btn-xs"
              label={t("main.send")}
              onClick={() => {
                onSubmit();
              }}
            />
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default OrderModal;
