import React, { useState, useEffect } from "react";
import { FormHelperText, FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/system";
//import { CssFormControlField, useStyles } from "../../../../styles/DoctorStyles/ui/form";

const AutocompleteStyled = styled(Autocomplete)({
  //  background: "black",
  width: "0px !important",
  margin: "-17px 0 19px 0px !important",
  width: "100%",
  height: "calc(2.25rem) !important",
  
  "& .MuiInputLabel-root": {
    margin:"0 0 -10px 0",
  },
  "& .MuiAutocomplete-input": {
    borderColor: "#FFF !important",
    height:"18px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "1.5px",
  },
  "& .MuiSvgIcon-root": {
    color: "#dddddd",
  },
  "& .MuiInputLabel-shrink":{
    display:"none !important",
    with:"0px !important",
  },
  
});

const getSelectValue = (value, props, options) => {
  if (!value) return props.multiple ? [] : null;

  if (props.multiple) {
    return options.filter((x) => value.includes(x.value));
  } else {
    const item = options.find((x) => x.value === value);
    return !item ? null : item;
  }
};

const getChangeValue = (values, props) => {
  if (!values) return props.multiple ? [] : null;
  return props.multiple ? values.map((c) => c.value) : values.value;
};

const AutocompleteSelect = (props) => {
  const {
    name,
    label,
    options,
    required,
    register,
    registerOptions,
    errors,
    //value = null,
    watch,
    setValue,
    ...remaining
  } = props;
  const [newData, setNewData] = useState([]);
  //const classes = useStyles();

  useEffect(() => {
    const newOptions = options.map((data, index) => ({
      label: data.name,
      value: data.id,
    }));
    setNewData(newOptions);
  }, [options]);

  const registerOption =
    registerOptions && registerOptions[name]
      ? registerOptions[name]
      : undefined;

  //let isError = false;
  let errorMessage = "";
  if (errors && errors.hasOwnProperty(name)) {
    /*     isError = true */
    errorMessage = errors[name].message;
  }

  const handleInvalidDefault = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const selectReg = register(name, registerOption);
  return (
    <div className="autocomplete-styled-box">
      <FormControl
        className="mb-2"
        size="small"
        variant="outlined"
        style={{ width: "inherit" }}
      >
        <AutocompleteStyled
          id={name}
          name={name}
          size="small"
          defaultValue={props.multiple ? [] : null}
          getOptionLabel={(option) => (option.label ? option.label : "")}
          onInvalid={handleInvalidDefault}
          disableClearable
          options={newData}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              margin="normal"
              variant="outlined"
              InputProps={{ ...params.InputProps, type: "search" }}
            />
          )}
          onChange={(e, values) => {
            setValue(name, getChangeValue(values, props, newData));
          }}
          onBlur={selectReg.onBlur}
          ref={selectReg.ref}
          value={getSelectValue(watch(name), props, newData)}
          {...remaining}
        />
        <FormHelperText id={`${name}-helper-text`}>
          {errorMessage && (
            <div className="div-horizontal-form">
              <img
                src="/doctor/error-icon.svg"
                className="error-icon"
                alert="Error icon"
                alt="icon"
              />
              {errorMessage}
            </div>
          )}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

export default AutocompleteSelect;
