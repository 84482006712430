import React, { useState } from "react";
import { callApi, uploadImages } from "../../../../services/apiService";
import Modal from "@mui/material/Modal";
import MainContainer from "../../../../layouts/container/mainContainer";
import { FormProvider, useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import { FileTypeId } from "../../../../constants/enum";
import Button from "../../../../components/buttons/button";

const UploadFile = ({ open, onClose, userId }) => {
  const { t } = useTranslation();
  const [botonActivo, setBotonActivo] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const defaultValues = {};
  const methods = useForm({ defaultValues });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  const validations = {
    file_type_id: {
      required: {
        value: true,
        message: t("validationError.file_type_id.require"),
      },
    },
    file: {
      required: {
        value: true,
        message: t("image.requireImage"),
      },
    },
  };

  const onSubmit = handleSubmit((data) => {
    var formData = new FormData();
    formData.append("userId", userId);
    formData.append("image", data.image[0]);
    formData.append("file_type_id", FileTypeId.PassportVigent2);

    setBotonActivo(true);
    callApi(
      () => uploadImages(formData),
      () => {
        NotificationManager.success(t("template.uploadSucces"));
        setBotonActivo(false);
        handleClose();
      }
    );
    setBotonActivo(false);
  });

  const cleanInputs = () => {
    setValue("file_type_id", "");
    setValue("file", "");
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m" showImage={false}>
          <br />
          <FormProvider {...methods}>
            <form encType="multipart/form-data" className="form-m">
              <div className="inputs-style">
                <label>{t("uploadFile.title")}</label>
                <p>{t("uploadFile.label")}</p>
              </div>
              <div className="mb-3">
                <input
                  className="form-control"
                  type="file"
                  id="image"
                  name="image"
                  {...register("image", validations.file)}
                />
                <span
                  style={{ margin: "10px 0 0 0" }}
                  className="text-danger text-small d-block mb-2"
                >
                  {errors.image && errors.image.message}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  type="ww-btn-secondary"
                  size="btn-s"
                  label={t("main.cancel")}
                  onClick={() => handleClose()}
                  disabled={botonActivo}
                />
                <Button
                  size="btn-s"
                  label={t("uploadFiles.upload")}
                  onClick={onSubmit}
                  disabled={botonActivo}
                />
              </div>
            </form>
          </FormProvider>
        </MainContainer>
      </Modal>
    </>
  );
};

export default UploadFile;
