import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/buttons/button";
import MainContainer from "../../layouts/container/mainContainer";
import { useTranslation } from "react-i18next";
import "../../styles/pages/errors.scss";

const Page500 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <MainContainer class="ww-container-m">
      <h1 className="errorTitle">500</h1>
      <h2 className="title">¡UPS!</h2>
      <h2 className="title">{t("error500.title")}</h2>
      <br />
      <p style={{ textAlign: "center" }}>{t("error500.message")}</p>
      <Button
        style={{
          textTransform: "uppercase",
        }}
        label={t("main.return")}
        onClick={() => {
          navigate(-1);
        }}
      />
    </MainContainer>
  );
};

export default Page500;
