import React from "react";
import { useTranslation } from "react-i18next";
import VideoPlayer from "../../components/videoPlayer";

const General = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className="title">{t("material.general")}</h2>
      <VideoPlayer
        url="/videos/Worldwide 2024 HomeWeb.mp4"
        title={t("videos.formWorkAndTravel")}
      />
    </>
  );
};

export default General;
