import React, { useEffect, useState } from "react";
import MainContainer from "../../layouts/container/mainContainer";
import Footer from "../../layouts/footer";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import Table, {
  defaultOptions,
  defaultCol,
  dateColumn,
} from "../../components/table";
import { NotificationManager } from "react-notifications";
import Button from "../../components/buttons/button";
import { AiOutlinePlus } from "react-icons/ai";
import "react-datepicker/dist/react-datepicker.css";
import {
  callApi,
  addCourse,
  getCourse,
  deleteCourse,
  editCourse,
} from "../../services/apiService";
import { BsPencilFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { courseState } from "../../constants/enum";
import DateInput from "../../components/forms/inputs/dateInput";
import AutocompleteSelect from "../../components/forms/inputsMui/autocomplete";
import * as dayjs from "dayjs";

const CoursesPage = () => {
  const { t } = useTranslation();

  const defaultValues = {};
  const methods = useForm({ defaultValues });

  const getObj = (tableMeta) =>
    courses.find((x) => x.id === tableMeta.rowData[0]);

  const {
    register,
    setValue,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [courses, setCourses] = useState([]);
  const [alertModal, setAlertModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [botonActivo, setBotonActivo] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    loadCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadCourses = () => {
    callApi(
      () => getCourse(),
      (data) => {
        setCourses(data);
      }
    );
  };

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    defaultCol("name", "register.name"),
    dateColumn("start_date", "main.startDate"),
    defaultCol("state", "main.state"),
    {
      name: "Actions",
      //label: t("Link"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, employee, updateValue) => {
          var rowObj = getObj(employee);

          return (
            <div className="table-btn-group">
              <Button
                disabled={botonActivo}
                label={<BsPencilFill />}
                onClick={() => {
                  setEdit(true);
                  setOpen(true);
                  loadCourseData(rowObj);
                }}
              />
              <Button
                disabled={botonActivo}
                label={<MdDelete />}
                onClick={() => {
                  setDeleteId(rowObj.id);
                  setAlertModal(true);
                }}
              />
            </div>
          );
        },
      },
    },
  ];

  const loadCourseData = (obj) => {
    setValue("id", !obj || !obj.id ? "" : obj.id);
    setValue("name", !obj || !obj.name ? "" : obj.name);
    setValue(
      "start_date",
      !obj || !obj.start_date ? "" : new Date(obj.start_date)
    );
    setValue("state", !obj || !obj.state ? "" : obj.state);
  };

  const cleanInputs = () => {
    setValue("name", "");
    setValue("start_date", "");
    setValue("state", "");
  };

  const onSubmit = handleSubmit((data) => {
    var obj = {
      name: data.name,
      start_date: !data.start_date
        ? null
        : dayjs(data.startDstart_dateate).format("YYYY-MM-DDT00:00:00"),
      state: data.state,
    };
    setBotonActivo(true);
    callApi(
      () => addCourse(obj),
      () => {
        NotificationManager.success(t("courses.AddSuccess"));
        cleanInputs();
        handleClose();
        loadCourses();
      }
    );
    setBotonActivo(false);
  });

  const onEdit = handleSubmit((data) => {
    setBotonActivo(true);
    callApi(
      () => editCourse(data),
      () => {
        NotificationManager.success(t("courses.Edit"));
        cleanInputs();
        handleClose();
        loadCourses();
      }
    );
    setBotonActivo(false);
  });

  const onDelete = (data) => {
    setBotonActivo(true);
    const id = {
      id: data,
    };
    callApi(
      () => deleteCourse(id),
      () => {
        NotificationManager.warning(t("courses.Delete"));
        loadCourses();
      }
    );
    setBotonActivo(false);
    setAlertModal(false);
  };

  const validations = {
    name: {
      required: { value: true, message: t("validationError.name.require") },
    },
    state: {
      required: {
        value: true,
        message: t("validationError.courseState.require"),
      },
    },
    start_date: {
      required: {
        value: true,
        message: t("validationError.courseDate.require"),
      },
    },
  };

  return (
    <>
      <MainContainer class="ww-container-xxl" showImage={false}>
        <h1 style={{ margin: "20px 0 20px 0" }} className="title">
          {t("main.courses")}
        </h1>
        <div>
          <Button
            label={<AiOutlinePlus />}
            onClick={() => {
              setEdit(false);
              cleanInputs();
              handleOpen();
            }}
          />
          <Table data={courses} columns={columns} options={defaultOptions()} />
        </div>
      </MainContainer>
      <Footer />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer>
          <h1 className="title">{t("courses.Add")}</h1>

          <FormProvider {...methods}>
            <form>
              <div className="container-inputs">
                <div className="inputs-style">
                  <label>{t("register.name")}</label>
                  <input
                    placeholder={t("placeholder.courseName")}
                    className="form-control mb-2"
                    name="name"
                    {...register("name", validations.name)}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors.name && errors.name.message}
                  </span>
                </div>
                <div className="inputs-style">
                  <DateInput
                    name="start_date"
                    control={control}
                    placeholder={t("main.startDate")}
                    label={t("main.startDate")}
                    {...register("start_date", validations.start_date)}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors.start_date && errors.start_date.message}
                  </span>
                </div>
              </div>
              <div className="container-inputs">
                <div className="inputs-style">
                  <label>{t("main.state")}</label>
                  <AutocompleteSelect
                    name="state"
                    label="state"
                    options={courseState}
                    register={register}
                    registerOptions={validations}
                    watch={watch}
                    setValue={setValue}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors.state && errors.state.message}
                  </span>
                </div>
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="ww-btn-secondary"
                  size="btn-xs secondary"
                  label={t("main.close")}
                  onClick={handleClose}
                />
                {edit ? (
                  <Button
                    size="btn-xs"
                    disabled={botonActivo}
                    label={t("main.edit")}
                    onClick={onEdit}
                  />
                ) : (
                  <Button
                    size="btn-xs"
                    disabled={botonActivo}
                    label={t("courses.Add")}
                    onClick={onSubmit}
                  />
                )}
              </div>
            </form>
          </FormProvider>
        </MainContainer>
      </Modal>

      <Modal
        open={alertModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m">
          <p>{t("alert.deleteCourse")}</p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              type="ww-btn-secondary"
              style={{ margin: "10px" }}
              size="btn-xs secondary"
              label={t("main.cancel")}
              onClick={() => setAlertModal(false)}
            />
            <Button
              style={{ margin: "10px" }}
              size="btn-xs"
              label={t("main.delete")}
              onClick={() => onDelete(deleteId)}
            />
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default CoursesPage;
