import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import AutocompleteSelect from "../../components/forms/inputsMui/autocomplete";
import { ImEye, ImEyeBlocked } from "react-icons/im";
import Modal from "@mui/material/Modal";
import { getUserInfo } from "../../services/authService";
import { NotificationManager } from "react-notifications";
import {
  callApi,
  editUserInfo,
  changePassword,
  getUserInfoBd,
  getCountries
} from "../../services/apiService";
import { useTranslation } from "react-i18next";
import Footer from "../../layouts/footer";
import MainContainer from "../../layouts/container/mainContainer";
import Button from "../../components/buttons/button";

const Profile = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [passwordNew, setPassword] = useState(true);
  const [botonActivo, setBotonActivo] = useState(false);
  const [countryList, setCountryList] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const loadData = (obj) => {
    setValue("name", !obj || !obj.name ? "" : obj.name);
    setValue("last_name", !obj || !obj.last_name ? "" : obj.last_name);
    setValue("email", !obj || !obj.email ? "" : obj.email);
    setValue("address", !obj || !obj.address ? "" : obj.address);
    setValue(
      "address_correspondence",
      !obj || !obj.address_correspondence ? "" : obj.address_correspondence
    );
    setValue(
      "countryNationality",
      !obj || !obj.country_nationality_id ? "" : obj.country_nationality_id
    );
    setValue(
      "countryPermanence",
      !obj || !obj.country_permanence_id ? "" : obj.country_permanence_id
    );
    setValue("phone", !obj || !obj.telephone ? "" : obj.telephone);
    if (obj.address === obj.address_correspondence) {
      setValue("check_address", true);
    }
  };

  const limpiarCamposPassword = () => {
    setValue2("current_password", "");
    setValue2("passwordNew", "");
    setValue2("passwordNewConfirmation", "");
  };

  useEffect(() => {
    const uInfo = getUserInfo();
    callApi(
      () => getUserInfoBd(uInfo),
      (data) => {
        loadData(data[0]);
      }
    );
    callApi(getCountries, (data) => {
      data.sort((a, b) => a.name.localeCompare(b.name));
      setCountryList(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultValues = {};
  const methods = useForm({ defaultValues });

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const methods2 = useForm({ defaultValues });
  const {
    setValue: setValue2,
    register: register2,
    watch: watch2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
  } = methods2;

  const onSubmit = handleSubmit((data) => {
    setBotonActivo(true);
    const uId = getUserInfo().id;
    if (watch("check_address")) {
      data.address_correspondence = watch("address");
    }
    callApi(
      () => editUserInfo({ ...data, uId }),
      NotificationManager.success("profile.notificationSuccess")
    );
    setBotonActivo(false);
    return;
  });

  const onSubmitPassword = handleSubmit2((data) => {
    const uId = getUserInfo().id;
    callApi(
      () => changePassword({ ...data, uId }),
      (response) => {
        if (response.success) {
          NotificationManager.success(t("recoverPassword.success"));
          limpiarCamposPassword();
          handleClose();
        }
      }
    );
    return;
  });

  const validations = {
    name: {
      required: { value: true, message: t("validationError.name.require") },
    },
    last_name: {
      required: { value: true, message: t("validationError.lastName.require") },
    },
    email: {
      required: { value: true, message: t("validationError.email.require") },
      pattern: {
        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
        message: t("validationError.email.valid"),
      },
    },
    current_password: {
      required: { value: true, message: t("validationError.password.require") },
      minLength: { value: 5, message: t("validationError.password.short") },
      pattern: {
        value: /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/,
        message: t("validationError.password.items"),
      },
    },
    passwordNew: {
      required: { value: true, message: t("validationError.password.require") },
      minLength: { value: 5, message: t("validationError.password.short") },
      pattern: {
        value: /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/,
        message: t("validationError.password.items"),
      },
    },
    phone: {
      required: { value: true, message: t("validationError.phone.require") },
      pattern: {
        value: /[0-9]+/,
        message: t("validationError.number"),
      },
    },
    countryNationality: {
      required: {
        value: true,
        message: t("form.validation.countryNationality"),
      },
    },
    countryPermanence: {
      required: {
        value: true,
        message: t("form.validation.countryPermanence"),
      },
    },
  };

  return (
    <>
      <MainContainer>
        <h1 className="title">{t("profile.title")}</h1>
        <FormProvider {...methods}>
          <form>
            <div className="container-inputs">
              <div className="inputs-style">
                {/* Name */}
                <label>{t("register.name")}</label>
                <input
                  placeholder={t("placeholder.name")}
                  className="form-control mb-2"
                  name="name"
                  {...register("name", validations.name)}
                />
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.name && errors.name.message}
                </span>
              </div>
              <div className="inputs-style">
                {/* Last Name */}
                <label>{t("register.lastName")}</label>
                <input
                  placeholder={t("placeholder.lastname")}
                  className="form-control mb-2"
                  name="last_name"
                  {...register("last_name", validations.last_name)}
                />
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.last_name && errors.last_name.message}
                </span>
              </div>
            </div>
            <div className="container-inputs">
              <div className="inputs-style">
                {/* Correo electronico */}
                <label>{t("main.email")}</label>
                <input
                  disabled
                  placeholder={t("placeholder.register.email")}
                  className="form-control mb-2"
                  name="email"
                  {...register("email", validations.email)}
                />
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.email && errors.email.message}
                </span>
              </div>
              <div className="inputs-style">
                {/* Número telefonico */}
                <label>{t("register.phone")}</label>
                <input
                  placeholder={t("placeholder.phone")}
                  className="form-control mb-2"
                  name="phone"
                  {...register("phone", validations.phone)}
                />
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.phone && errors.phone.message}
                </span>
              </div>
            </div>
            <div className="container-inputs">
            <div className="inputs-style">
                <label>{t("form.nationality")}</label>
                <AutocompleteSelect
                  name="countryNationality"
                  label={t("form.nationality")}
                  options={countryList}
                  register={register}
                  registerOptions={validations}
                  watch={watch}
                  setValue={setValue}
                />
                {watch("countryNationality") == "" && (
                  <span className="text-danger text-small d-block mb-2 validation-margin">
                    {errors.countryNationality &&
                      errors.countryNationality.message}
                  </span>
                )}
              </div>
              <div className="inputs-style">
                <label>{t("form.countryPermanence")}</label>
                <AutocompleteSelect
                  name="countryPermanence"
                  label={t("form.countryPermanence")}
                  options={countryList}
                  register={register}
                  registerOptions={validations}
                  watch={watch}
                  setValue={setValue}
                />
                {watch("countryPermanence") == "" && (
                  <span className="text-danger text-small d-block mb-2 validation-margin">
                    {errors.countryPermanence &&
                      errors.countryPermanence.message}
                  </span>
                )}
              </div>              
            </div>
            <br />
            <Button
              size="btn-s"
              disabled={botonActivo}
              label={t("profile.SaveChanges")}
              onClick={onSubmit}
            />
          </form>
        </FormProvider>
        <div
          style={{
            color: "#44a3d5",
            fontSize: "large",
            fontWeight: "bold",
            margin: "15px 0 25px 0",
          }}
        >
          <p style={{ cursor: "pointer" }} onClick={handleOpen}>
            {t("profile.changePassword")}
          </p>
        </div>
        {/* Reset Password*/}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MainContainer class="ww-container-m">
            <h1 className="title">{t("profile.changePassword.head")}</h1>

            <FormProvider {...methods2}>
              <form>
                {/* Contraseña actual */}
                <label>{t("profile.currentPassword.title")}</label>
                <input
                  placeholder={t("profile.currentPassword.title")}
                  className="form-control mb-2"
                  name="current_password"
                  type="password"
                  {...register2(
                    "current_password",
                    validations.current_password
                  )}
                />
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors2.current_password && errors2.current_password.message}
                </span>
                {/* Contraseña nueva 1 - Prueba */}
                <label>{t("recoverPassword.new.title")}</label>
                <div className="input-group">
                  <input
                    placeholder={t("profile.newPassword")}
                    className="form-control mb-2"
                    name={passwordNew}
                    type={passwordNew ? "password" : "text"}
                    {...register2("passwordNew", {
                      required: t("profile.newPassword.required"),
                      validate: (value) =>
                        value !== watch2("current_password") ||
                        t("profile.newPassword.different"),
                    })}
                  />
                  <div className="input-group-append">
                    <div
                      className="ButtonEyeContainer"
                      onClick={() => setPassword(!passwordNew)}
                    >
                      {passwordNew ? (
                        <ImEye className="ButtonEye" />
                      ) : (
                        <ImEyeBlocked className="ButtonEye" />
                      )}
                    </div>
                  </div>
                </div>
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors2.passwordNew && errors2.passwordNew.message}
                </span>
                {/* Contraseña Nueva 2 */}
                <label>{t("profile.newPassword.conffirmationTitle")}</label>
                <input
                  placeholder={t("profile.newPassword.conffirmation")}
                  className="form-control mb-2"
                  name="passwordNewConfirmation"
                  type="password"
                  {...register2("passwordNewConfirmation", {
                    required: {
                      value: true,
                      message: t("profile.newPassword.conffirmation.required"),
                    },
                    validate: (value) =>
                      value === watch2("passwordNew") ||
                      t("profile.newPassword.conffirmation.error"),
                  })}
                />
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors2.passwordNewConfirmation &&
                    errors2.passwordNewConfirmation.message}
                </span>
                <div>
                  <Button
                    size="btn-sx"
                    disabled={botonActivo}
                    label={t("main.change")}
                    onClick={onSubmitPassword}
                  />
                  <Button
                    size="btn-sx"
                    type="ww-btn-secondary"
                    disabled={botonActivo}
                    label={t("main.close")}
                    onClick={handleClose}
                  />
                </div>
              </form>
            </FormProvider>
          </MainContainer>
        </Modal>
      </MainContainer>
      <Footer />
    </>
  );
};

export default Profile;
