import React from "react";
import MainContainer from "../../layouts/container/mainContainer";
import Footer from "../../layouts/footer";
import { useParams } from "react-router-dom";
import "../../styles/pages/login.scss";

const UserDetail = () => {

 let {idUser}=useParams();

  return (
    <>
      <MainContainer>
        <h1>UserDetail</h1>
      </MainContainer>
      <Footer />
    </>
  );
};

export default UserDetail;
