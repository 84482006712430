import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { getUserInfo } from "../../services/authService";
import MainContainer from "../../layouts/container/mainContainer";
import { FormProvider, useForm } from "react-hook-form";
import "../../styles/pages/login.scss";
import Button from "../../components/buttons/button";
import Modal from "@mui/material/Modal";
import {
  callApi,
  saveChangeProgram,
  getCourseplanning,
} from "../../services/apiService";
import AutocompleteSelect from "../../components/forms/inputsMui/autocomplete";

const ChangeCourseModal = ({
  open,
  onClose,
  currentUser,
  loadDataTable,
}) => {
  const { t } = useTranslation();

  const [botonActivo, setBotonActivo] = useState(false);
  const [programList, setProgramList] = useState([]);

  const validations = {
    program: {
      required: { value: true, message: t("userList.required") },
    },
  };

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const cleanForm = () => {
    setValue("program", "");
  };

  useEffect(() => {
    const uInfo = getUserInfo();
    callApi(
      () => getCourseplanning(),
      (data) => {
        setProgramList(data);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = handleSubmit((data) => {
    const timeElapsed = Date.now();
    const uId = getUserInfo().id;
    setBotonActivo(true);

    const submitData = {
      userId: parseInt(currentUser.id),
      programId: parseInt(data.program),
      postulationId: parseInt(currentUser.postulation_id),
    };
    callApi(
      () => saveChangeProgram(submitData),
      () => {
        NotificationManager.success(t("form.success"));
        onCloseHandle();
        loadDataTable();
      }
    );
    setBotonActivo(false);
  });

  const onCloseHandle = () => {
    cleanForm();
    reset();
    onClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseHandle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m" showImage={false}>
          <h1 style={{ margin: "20px 0 20px 0" }} className="title">
            {t("listTraveler.ChangeCourse")}
          </h1>
          <p></p>
          <FormProvider {...methods}>
            <form>
              <div className="inputs-style">
                <label>
                  {t("listTraveler.ChangeCourseText") +
                    "" +
                    currentUser.lastName +
                    " " +
                    currentUser.name}
                </label>
                <AutocompleteSelect
                  name="program"
                  label={t("menu.courses")}
                  options={programList}
                  register={register}
                  registerOptions={validations}
                  watch={watch}
                  setValue={setValue}
                />
                {watch("program") == "" && (
                  <span className="text-danger text-small d-block mb-2 validation-margin">
                    {errors.program && errors.program.message}
                  </span>
                )}
              </div>
            </form>
          </FormProvider>
          <div>
            <div className="table-btn-group">
              <Button
                type="ww-btn-secondary"
                style={{ margin: "10px" }}
                size="btn-s secondary"
                label={t("main.close")}
                onClick={() => {
                  cleanForm();
                  onCloseHandle();
                }}
              />
              <Button
                size="btn-s"
                label={t("main.send")}
                onClick={() => {
                  onSubmit();
                }}
              />
            </div>
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default ChangeCourseModal;
