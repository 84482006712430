import React, { useEffect, useState } from "react";
import MainContainer from "../../layouts/container/mainContainer";
import Footer from "../../layouts/footer";
import { useTranslation } from "react-i18next";
import Button from "../../components/buttons/button";
import { FaFileDownload } from "react-icons/fa";
import {
  callApi,
  getReportActiveEmployers,
  getReportSelectedEmployers,
  getReportTravelers,
} from "../../services/apiService";
import { reportEnum } from "../../constants/enum";

const Reports = () => {
  const { t } = useTranslation();
  const [selectedReport, setSelectedReport] = useState(null);

  const handleSelectChange = (event) => {
    setSelectedReport(event.target.value);
  };

  const handleDownload = async () => {
    if (selectedReport == reportEnum.selectedEmployers) {
      callApi(
        () => getReportSelectedEmployers(),
        (data) => {
          const currentDate = new Date();
          const year = currentDate.getFullYear();
          const month = String(currentDate.getMonth() + 1).padStart(2, "0");
          const day = String(currentDate.getDate()).padStart(2, "0");
          const fileName = `selectedEmployers_${year}-${month}-${day}.xlsx`;
          downloadFileASync(data, fileName);
        }
      );
      return;
    }
    if (selectedReport == reportEnum.employersActived) {
      callApi(
        () => getReportActiveEmployers(),
        (data) => {
          const currentDate = new Date();
          const year = currentDate.getFullYear();
          const month = String(currentDate.getMonth() + 1).padStart(2, "0");
          const day = String(currentDate.getDate()).padStart(2, "0");
          const fileName = `activeEmployers_${year}-${month}-${day}.xlsx`;
          downloadFileASync(data, fileName);
        }
      );
      return;
    }
    if (selectedReport == reportEnum.travelers) {
      callApi(
        () => getReportTravelers(),
        (data) => {
          const currentDate = new Date();
          const year = currentDate.getFullYear();
          const month = String(currentDate.getMonth() + 1).padStart(2, "0");
          const day = String(currentDate.getDate()).padStart(2, "0");
          const fileName = `activeEmployers_${year}-${month}-${day}.xlsx`;
          downloadFileASync(data, fileName);
        }
      );
      return;
    }
    return;
  };

  const downloadFileASync = (data, fileName) => {
    try {
      const base64String = data.file;
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the report:", error);
    }
  };
  return (
    <>
      <MainContainer className="ww-container-xxl" showImage={false}>
        <h1 style={{ margin: "20px 0 20px 0" }} className="title">
          {t("report.title")}
        </h1>
        <div style={{ marginBottom: "20px" }}>
          <label htmlFor="reportSelect">{t("report.label")}</label>
          <select
            id="reportSelect"
            value={selectedReport}
            onChange={handleSelectChange}
            style={{ marginLeft: "10px" }}
          >
            <option value="">{t("report.choose")}</option>
            <option value={reportEnum.selectedEmployers}>
              {t("report.employersSelected")}
            </option>
            <option value={reportEnum.employersActived}>
              {t("report.employersActived")}
            </option>
            <option value={reportEnum.travelers}>
              {t("report.travelers")}
            </option>
          </select>
          <Button
            label={<FaFileDownload />}
            onClick={handleDownload}
            disabled={!selectedReport}
            tooltip={t("postulation.title")}
            style={{ marginLeft: "10px" }}
          />
        </div>
      </MainContainer>
      <Footer />
    </>
  );
};

export default Reports;
