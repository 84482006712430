import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { callApi, saveProcessPostulation } from "../../services/apiService";
import { getUserInfo } from "../../services/authService";
import MainContainer from "../../layouts/container/mainContainer";
import { FormProvider, useForm } from "react-hook-form";
import "../../styles/pages/login.scss";
import Button from "../../components/buttons/button";
import Modal from "@mui/material/Modal";
import {
  correctionOptions,
  generalStatesPostulation,
  videoCorrectionOptions,
} from "../../constants/enum";
import AutocompleteSelect from "../../components/forms/inputsMui/autocomplete";

const ModalTypeAndComment = ({
  open,
  onClose,
  currentUser,
  loadDataTable,
  desition,
  statePostulationGeneral,
}) => {
  const { t } = useTranslation();

  const [stateGnrlPostulacion, setGnrlPostulacion] = useState();
  const [botonActivo, setBotonActivo] = useState(false);

  const validations = {
    comment: {
      required: { value: true, message: t("userList.required") },
    },
    correctionType: {
      required: { value: true, message: t("userList.required") },
    },
  };

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const cleanForm = () => {
    setValue("comment", "");
    setValue("correctionType", "");
  };

  const getProp = (obj, prop) => {
    if (!obj) return "";
    const propValue = obj[prop];
    return propValue ? propValue : "";
  };

  const getState = (obj) => {
    if (!obj) return "";
    const propValue = obj;
    return propValue ? propValue : "";
  };

  const onSubmit = handleSubmit((data) => {
    const timeElapsed = Date.now();
    const uId = getUserInfo().id;
    setBotonActivo(true);

    var titleNotification = "";
    var bodyNotification = desition
      ? t("notificationBody.approved")
      : t("notificationBody.declined");

    switch (statePostulationGeneral) {
      case generalStatesPostulation.uploadingFiles:
        titleNotification =
          t("titleNotification.title") + " " + t("notification.titleFormFiles");
        break;
      case generalStatesPostulation.videoAndCv:
        titleNotification =
          t("titleNotification.title") + " " + t("general.videoAndCv");
        break;
      case generalStatesPostulation.employerSelection:
        titleNotification =
          t("titleNotification.title") + " " + t("general.employerSelection");
        break;
      case generalStatesPostulation.interviewWork:
        titleNotification =
          t("titleNotification.title") + " " + t("general.interviewWork");
        break;
      case generalStatesPostulation.mockInterview:
        titleNotification =
          t("titleNotification.title") + " " + t("general.mockInterview");
        break;
      case generalStatesPostulation.interviewConsular:
        titleNotification =
          t("titleNotification.title") + " " + t("general.interviewConsular");
        break;
      case generalStatesPostulation.flightAndAccommodation:
        titleNotification =
          t("titleNotification.title") +
          " " +
          t("general.flightAndAccommodation");
        break;
      case generalStatesPostulation.interviewWwce:
        titleNotification =
          t("titleNotification.title") + " " + t("general.interviewWwce");
        break;
      case generalStatesPostulation.jobOffer:
        titleNotification =
          t("titleNotification.title") + " " + t("general.jobOffer");
        break;
      case generalStatesPostulation.pendingAnswer:
        titleNotification =
          t("titleNotification.title") + " " + t("notification.postulation");
        break;
    }

    const submitData = {
      commentAnswer: data ? data.comment : "",
      commentType: !data.correctionType ? 0 : data.correctionType,
      postulationId: getProp(currentUser, "postulation_id"),
      stateGnrlPostulacion: stateGnrlPostulacion,
      statePostulationGeneral: statePostulationGeneral,
      desition: desition,
      date: new Date(timeElapsed),
      agencyId: uId,
      userId: getProp(currentUser, "id"),
      bodyNotification: bodyNotification,
      titleNotification: titleNotification,
    };

    callApi(
      () => saveProcessPostulation(submitData),
      () => {
        cleanForm();
        onClose();
        loadDataTable();
      }
    );
    setBotonActivo(false);
  });

  const getTitleModal = (state) => {
    switch (getState(state)) {
      case generalStatesPostulation.uploadingFiles:
        if (desition)
          return t("userList.title.aprove") + " - " + t("form.postulationForm");
        return t("userList.title.decline") + " - " + t("form.postulationForm");
      case generalStatesPostulation.interviewWwce:
        if (desition) return t("interview.answer.yes");
        return t("interview.answer.no");
      case generalStatesPostulation.videoAndCv:
        if (desition) return t("userlist.video.approve");
        return t("userlist.video.decline");
      case generalStatesPostulation.employerSelection:
        if (desition)
          return (
            t("userList.title.aprove") +
            " - " +
            t("postulationState.employerSelection")
          );
        return (
          t("userList.title.decline") +
          " - " +
          t("postulationState.employerSelection")
        );
      case generalStatesPostulation.interviewWork:
        if (desition)
          return (
            t("userList.title.aprove") + " - " + t("general.interviewWork")
          );
        return t("userList.title.decline") + " - " + t("general.interviewWork");
      case generalStatesPostulation.mockInterview:
        if (desition)
          return (
            t("userList.title.aprove") + " - " + t("general.mockInterview")
          );
        return t("userList.title.decline") + " - " + t("general.mockInterview");
      case generalStatesPostulation.jobOffer:
        if (desition)
          return t("userList.title.aprove") + " - " + t("general.jobOffer");
        return t("userList.title.decline") + " - " + t("general.jobOffer");
      case generalStatesPostulation.interviewConsular:
        if (desition)
          return (
            t("userList.title.aprove") + " - " + t("general.interviewConsular")
          );
        return (
          t("userList.title.decline") + " - " + t("general.interviewConsular")
        );
      case generalStatesPostulation.flightAndAccommodation:
        if (desition)
          return (
            t("userList.title.aprove") +
            " - " +
            t("general.flightAndAccommodation")
          );
        return (
          t("userList.title.decline") +
          " - " +
          t("general.flightAndAccommodation")
        );
      case generalStatesPostulation.preTripTraining:
        if (desition)
          return (
            t("userList.title.aprove") + " - " + t("general.preTripTraining")
          );
        return (
          t("userList.title.decline") + " - " + t("general.preTripTraining")
        );
      case generalStatesPostulation.pendingAnswer:
        if (desition)
          return (
            t("userList.title.aprove") + " - " + t("general.pendingAnswer")
          );
        return t("userList.title.decline") + " - " + t("general.pendingAnswer");
      default:
        return t("userList.withoutActions");
    }
  };
  const getAdvModal = (state) => {
    switch (getState(state)) {
      case generalStatesPostulation.uploadingFiles:
        if (desition) return t("userList.adv.aprove");
        return t("userList.adv.decline");
      case generalStatesPostulation.interviewWwce:
        if (desition) return t("interview.adv.yes");
        return t("interview.adv.no");
      case generalStatesPostulation.videoAndCv:
        if (desition) return t("userlist.video.adv.approve");
        return t("userlist.video.adv.decline");
      case generalStatesPostulation.employerSelection:
      case generalStatesPostulation.interviewWork:
      case generalStatesPostulation.jobOffer:
      case generalStatesPostulation.mockInterview:
      case generalStatesPostulation.interviewConsular:
      case generalStatesPostulation.flightAndAccommodation:
      case generalStatesPostulation.preTripTraining:
      case generalStatesPostulation.pendingAnswer:
        if (desition) return t("userList.generalAprove");
        return t("userList.generalDecline");
      default:
        return t("userList.withoutActions");
    }
  };

  const getContent = (state) => {
    switch (state) {
      case generalStatesPostulation.uploadingFiles:
        if (desition) return;
        return (
          <>
            <div className="container-inputs">
              <div className="inputs-style-2">
                <label> {t("userList.comments")}</label>
                <AutocompleteSelect
                  name="correctionType"
                  label={t("userList.correction.label")}
                  options={correctionOptions()}
                  register={register}
                  registerOptions={validations}
                  watch={watch}
                  setValue={setValue}
                />
                <span className="text-danger text-small d-block mb-2">
                  {errors.correctionType && errors.correctionType.message}
                </span>
              </div>
              <div className="inputs-style-2">
                <label>{t("userList.comments")}</label>
                <textarea
                  placeholder={t("userList.comments")}
                  className="form-control mb-2"
                  rows="3"
                  {...register("comment", validations.comment)}
                ></textarea>
                <span className="text-danger text-small d-block mb-2">
                  {errors.comment && errors.comment.message}
                </span>
              </div>
            </div>
          </>
        );
      case generalStatesPostulation.interviewWwce:
      case generalStatesPostulation.interviewWork:
      case generalStatesPostulation.interviewConsular:
      case generalStatesPostulation.flightAndAccommodation:
      case generalStatesPostulation.employerSelection:
      case generalStatesPostulation.jobOffer:
      case generalStatesPostulation.preTripTraining:
        if (desition) return;
        return (
          <>
            <div>
              <label>{t("userList.comments")}</label>
              <textarea
                placeholder={t("userList.comments")}
                className="form-control mb-2"
                rows="3"
                {...register("comment", validations.comment)}
              ></textarea>
              <span className="text-danger text-small d-block mb-2">
                {errors.comment && errors.comment.message}
              </span>
            </div>
          </>
        );
      case generalStatesPostulation.videoAndCv:
        if (desition) return;
        return (
          <>
            <div className="container-inputs">
              <div className="inputs-style-2">
                <AutocompleteSelect
                  name="correctionType"
                  label={t("userList.correction.label")}
                  options={videoCorrectionOptions()}
                  register={register}
                  registerOptions={validations}
                  watch={watch}
                  setValue={setValue}
                />
                <span className="text-danger text-small d-block mb-2">
                  {errors.correctionType && errors.correctionType.message}
                </span>
              </div>
              <div className="inputs-style-2">
                <label>{t("userList.correction.title")}</label>
                <textarea
                  placeholder={t("userList.correction.title")}
                  className="form-control mb-2"
                  rows="3"
                  {...register("comment", validations.comment)}
                ></textarea>
                <span className="text-danger text-small d-block mb-2">
                  {errors.comment && errors.comment.message}
                </span>
              </div>
            </div>
          </>
        );
      default:
        return;
    }
  };

  return (
    <>
      {/* MODAL DENEGAR  VIDEO CV*/}
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m" showImage={false}>
          <FormProvider {...methods}>
            <h1 style={{ margin: "20px 0 20px 0" }} className="title">
              {getTitleModal(statePostulationGeneral)}
            </h1>
            <p>{getAdvModal(statePostulationGeneral)}</p>
            <form>{getContent(statePostulationGeneral)}</form>
          </FormProvider>
          <div>
            <div className="table-btn-group">
              <Button
                type="ww-btn-secondary"
                style={{ margin: "10px" }}
                size="btn-s secondary"
                label={t("main.close")}
                onClick={() => {
                  cleanForm();
                  onClose();
                }}
              />
              {(statePostulationGeneral ==
                generalStatesPostulation.uploadingFiles ||
                statePostulationGeneral ==
                  generalStatesPostulation.interviewWwce ||
                statePostulationGeneral ==
                  generalStatesPostulation.videoAndCv ||
                statePostulationGeneral ==
                  generalStatesPostulation.mockInterview ||
                statePostulationGeneral ==
                  generalStatesPostulation.employerSelection ||
                statePostulationGeneral ==
                  generalStatesPostulation.interviewWork ||
                statePostulationGeneral == generalStatesPostulation.jobOffer ||
                statePostulationGeneral ==
                  generalStatesPostulation.interviewConsular ||
                statePostulationGeneral ==
                  generalStatesPostulation.flightAndAccommodation ||
                statePostulationGeneral ==
                  generalStatesPostulation.preTripTraining ||
                statePostulationGeneral ==
                  generalStatesPostulation.pendingAnswer) && (
                <Button
                  disabled={botonActivo}
                  size="btn-s"
                  label={t("main.send")}
                  onClick={() => {
                    onSubmit();
                  }}
                />
              )}
            </div>
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default ModalTypeAndComment;
