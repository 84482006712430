import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/buttons/button";
import MainContainer from "../../layouts/container/mainContainer";
import { useTranslation } from "react-i18next";
import "../../styles/pages/errors.scss";

const Page404 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <MainContainer class="ww-container-m">
      <h1 className="errorTitle">404</h1>
      <h2 className="title">{t("main.ups")}</h2>
      <h2 className="title">{t("error404.title")}</h2>
      <br />
      <p>{t("error404.message")}</p>

      <Button
        style={{
          textTransform: "uppercase",
        }}
        label={t("main.return")}
        onClick={() => {
          navigate(-1);
        }}
      />
    </MainContainer>
  );
};

export default Page404;
