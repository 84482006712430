import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/buttons/button";
import "../../../../styles/pages/postulationState.scss";
import {
  callApi,
  getCv,
  deleteCvEducationExperience,
  saveCv,
  getCvPdf,
} from "../../../../services/apiService";
import MainContainer from "../../../../layouts/container/mainContainer";
import Modal from "@mui/material/Modal";
import { NotificationManager } from "react-notifications";
import { FormProvider, useForm } from "react-hook-form";
import { AiFillSave, AiOutlinePlus } from "react-icons/ai";
import Table, {
  defaultCol,
  minimalOptions,
} from "../../../../components/table";
import { BsPencilFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { BsSave } from "react-icons/bs";
import CvExperienceModal from "./cvExperienceModal";
import CvEducationModal from "./cvEducationModal";
import HelpButton from "../../../../components/buttons/helpButton";

const urlBase = process.env.REACT_APP_URL_BASE;
const urlApi = process.env.REACT_APP_API_URL;

const CvResume = ({
  handleCloseVideoCvModal,
  openVideoCvModal,
  postulationId,
  userId,
  setCvId,
  isBlocked,
}) => {
  const { t } = useTranslation();
  const [viewPath, setViewPath] = useState("");
  const [cvData, setCvData] = useState(null);

  //modals
  const [cvEducationData, setCvEducationData] = useState([]);
  const [cvEducationObj, setCvEducationObj] = useState(null);
  const [cvExperienceObj, setCvExperienceObj] = useState(null);

  const [cvEducationModalOpen, setCvEducationModalOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleOpenCvEducationModal = () => {
    setCvEducationModalOpen(true);
  };
  const handleCloseCvEducationModal = () => {
    setCvEducationObj(null);
    setCvEducationModalOpen(false);
  };
  const [cvExperienceModalOpen, setCvExperienceModalOpen] = useState(false);
  const [cvExperienceData, setCvExperienceData] = useState(null);

  const handleOpenCvExperienceModal = () => {
    setCvExperienceModalOpen(true);
  };
  const handleCloseCvExperienceModal = () => {
    setCvExperienceObj(null);
    setCvExperienceModalOpen(false);
  };
  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  useEffect(() => {
    if (!openVideoCvModal) {
      setCvEducationData([]);
      setCvExperienceData([]);
      return;
    }
    if (postulationId == null || postulationId == 0 || !openVideoCvModal) {
      return;
    }
    loadDataCv();
  }, [openVideoCvModal]);

  const loadDataCv = () => {
    const dataToGet = {
      userId: userId,
      postulationId: postulationId,
    };
    callApi(
      () => getCv(dataToGet),
      (data) => {
        setCvEducationData(data.cvEducationtTable);
        setCvExperienceData(data.cvExperienceTable);
        setCvData(data);
        setValue3("interests", data.interests);
        setValue3("reasonEmployee", data.reasonEmployee);
        setValue3("specialAbilities", data.specialSkills);
        setCvId(data.id);
      }
    );
  };

  const validations = {
    specialAbilities: {
      required: { value: true, message: t("healthInfo.requiredEnglish") },
    },
    interests: {
      required: { value: true, message: t("healthInfo.requiredEnglish") },
    },
    reasonEmployee: {
      required: { value: true, message: t("healthInfo.requiredEnglish") },
    },
  };

  const defaultValues = {};
  const methods3 = useForm({ defaultValues });
  const {
    setValue: setValue3,
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
  } = methods3;

  const fnDelete = () => {
    if (cvEducationObj == null && cvExperienceObj == null) return;
    const data = {
      idCvEducation: cvEducationObj == null ? null : cvEducationObj.id,
      idCvExperience: cvExperienceObj == null ? null : cvExperienceObj.id,
    };
    callApi(
      () => deleteCvEducationExperience(data),
      () => {
        setCvEducationObj(null);
        setCvExperienceObj(null);
        loadDataCv();
        setDeleteModal(false);
      }
    );
  };

  const getObj = (tableMeta) => {
    return cvEducationData.find((x) => x.id === tableMeta.rowData[0]);
  };
  const getExpObj = (tableMeta) => {
    return cvExperienceData.find((x) => x.id === tableMeta.rowData[0]);
  };

  const columnsEducation = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    defaultCol("institutionName", "cvEducation.institutionEnglish"),
    {
      name: "Actions",
      label: t("userList.actionsEnglish"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, positions, updateValue) => {
          var rowObj = getObj(positions);
          return (
            <div className="table-btn-group">
              {!isBlocked && (
                <>
                  <div
                    className="styled-button"
                    onClick={() => {
                      setCvEducationObj(rowObj);
                      handleOpenCvEducationModal();
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <BsPencilFill className="button-icon" />
                  </div>
                  <div
                    className="styled-button"
                    onClick={() => {
                      setCvEducationObj(rowObj);
                      setDeleteModal(true);
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <MdDelete className="button-icon" />
                  </div>
                </>
              )}
            </div>
          );
        },
      },
    },
  ];
  const columnsExperience = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    defaultCol("companyName", "cvExperience.companyEnglish"),
    {
      name: "Actions",
      label: t("userList.actionsEnglish"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, positions, updateValue) => {
          var rowObj = getExpObj(positions);
          return (
            <div className="table-btn-group">
              {!isBlocked && (
                <>
                  <div
                    className="styled-button"
                    onClick={() => {
                      setCvExperienceObj(rowObj);
                      handleOpenCvExperienceModal();
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <BsPencilFill className="button-icon" />
                  </div>
                  <div
                    className="styled-button"
                    onClick={() => {
                      setCvExperienceObj(rowObj);
                      setDeleteModal(true);
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <MdDelete className="button-icon" />
                  </div>
                </>
              )}
            </div>
          );
        },
      },
    },
  ];

  const onSubmit = handleSubmit3((data) => {
    const dataToSave = {
      id: cvData == null ? 0 : cvData.id,
      userId: parseInt(userId),
      interests: data.interests,
      specialAbilities: data.specialAbilities,
      reasonEmployee: data.reasonEmployee,
    };
    callApi(
      () => saveCv(dataToSave),
      () => {
        NotificationManager.success(t("form.successEnglish"));
        loadDataCv();
      }
    );
  });

  const onSubmitToPdf = () => {
    const dataToQuery = {
      userId: parseInt(userId),
    };
    callApi(
      () => getCvPdf(dataToQuery),
      (data) => {
        const downloadUrl = urlBase + "/" + data.replace("WorldWideFiles", "");
        window.open(downloadUrl, "_blank");
      }
    );
  };

  return (
    <>
      {/* --------------------------------------CV ------------------------------------- */}
      <div>
        <hr />
        <div className="titleButtonContainer">
          <h4 className="m-3">{t("cv.title")}</h4>
          <HelpButton
            message={
              "Toda la información debe registrarse en ingles. Consulta el botón de ayuda"
            }
          />
        </div>
      </div>
      <div
        style={{
          flexDirection: "column",
          alignContent: "center",
        }}
      >
        <label>{t("cv.educationEnglish")} </label>
        {!isBlocked && (
          <>
            <div
              className="styled-button"
              onClick={() => {
                setCvEducationObj(null);
                handleOpenCvEducationModal();
              }}
              role="button"
              tabIndex={0}
              style={{ marginLeft: "18px" }}
            >
              <AiOutlinePlus className="button-icon" />
            </div>
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
        }}
      >
        <Table
          data={cvEducationData == null ? [] : cvEducationData}
          columns={columnsEducation}
          options={minimalOptions()}
        />
      </div>
      <br />
      <div
        style={{
          flexDirection: "column",
          alignContent: "center",
        }}
      >
        <label>{t("cv.experienceEnglish")}</label>
        {!isBlocked && (
          <>
            <div
              className="styled-button"
              onClick={handleOpenCvExperienceModal}
              role="button"
              tabIndex={0}
              style={{ marginLeft: "18px" }}
            >
              <AiOutlinePlus className="button-icon" />
            </div>
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
        }}
      >
        <Table
          data={cvExperienceData == null ? [] : cvExperienceData}
          columns={columnsExperience}
          options={minimalOptions()}
        />
      </div>
      <FormProvider {...methods3}>
        <form>
          <div className="container-inputs">
            <div className="inputs-style">
              <label>
                {t("cv.interestsEnglish")}
                <span className="text-danger"> *</span>
              </label>
              <textarea
                disabled={isBlocked}
                name="interests"
                placeholder={t("cv.interestsEnglish")}
                className="form-control mb-2"
                registerOptions={validations}
                rows="2"
                {...register3("interests", validations.interests)}
              ></textarea>
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors3.interests && errors3.interests.message}
              </span>
            </div>
            <div className="inputs-style">
              <label>
                {t("cv.specialAbilitiesEnglish")}
                <span className="text-danger"> *</span>
              </label>
              <textarea
                disabled={isBlocked}
                name="specialAbilities"
                placeholder={t("cv.specialAbilitiesEnglish")}
                className="form-control mb-2"
                registerOptions={validations}
                rows="2"
                {...register3("specialAbilities", validations.specialAbilities)}
              ></textarea>
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors3.specialAbilities && errors3.specialAbilities.message}
              </span>
            </div>
          </div>
          <div className="container-inputs">
            <div className="inputs-style">
              <label>
                {t("cv.reasonEmployeeEnglish")}
                <span className="text-danger"> *</span>
              </label>
              <textarea
                disabled={isBlocked}
                name="reasonEmployee"
                placeholder={t("cv.reasonEmployeeEnglish")}
                className="form-control mb-2"
                registerOptions={validations}
                rows="2"
                {...register3("reasonEmployee", validations.reasonEmployee)}
              ></textarea>
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors3.reasonEmployee && errors3.reasonEmployee.message}
              </span>
            </div>
          </div>
          <div
            className="container-inputs"
            style={{
              display: "flex",
              alignItems: "center",
              flexFlow: "row",
              justifyItems: "center",
              justifyContent: "center",
            }}
          >
            {!isBlocked && (
              <>
                <Button label={<AiFillSave />} onClick={onSubmit} />
              </>
            )}

            <div
              style={{
                height: "fit-content",
                display: "flex",
                alignSelf: "center",
              }}
              className="styled-button"
              onClick={() => {
                onSubmitToPdf();
              }}
              role="button"
              tabIndex={0}
            >
              <BsSave className="button-icon" />
            </div>
          </div>
        </form>
      </FormProvider>

      {/* DELETE FILE */}
      <Modal
        open={deleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m" showImage={false}>
          <br />
          <br />
          <p>{t("alert.deleteRegisterEnglish")}</p>
          <div style={{ display: "flex" }}>
            <Button
              type="ww-btn-secondary"
              style={{ margin: "10px" }}
              size="btn-sx secondary"
              label={t("main.cancelEnglish")}
              onClick={() => {
                setDeleteModal(false);
                setCvEducationObj(null);
                setCvExperienceObj(null);
              }}
            />
            <Button
              style={{ margin: "10px" }}
              size="btn-sx"
              label={t("main.deleteEnglish")}
              onClick={() => fnDelete()}
            />
          </div>
        </MainContainer>
      </Modal>
      <CvEducationModal
        open={cvEducationModalOpen}
        close={handleCloseCvEducationModal}
        userId={userId}
        cvId={cvData == null ? 0 : cvData.id}
        dataCvEducation={cvEducationObj == null ? null : cvEducationObj}
        setDataCvEducation={setCvEducationObj}
        loadDataCv={loadDataCv}
      />
      <CvExperienceModal
        open={cvExperienceModalOpen}
        close={handleCloseCvExperienceModal}
        userId={userId}
        cvId={cvData == null ? 0 : cvData.id}
        dataCvExperience={cvExperienceObj}
        setDataCvExperience={setCvExperienceObj}
        loadDataCv={loadDataCv}
      />
    </>
  );
};

export default CvResume;
