import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/buttons/button";
import UploadTemplate from "../../components/uploadTemplate";
import { RoleEnum } from "../../constants/enum";
import MainContainer from "../../layouts/container/mainContainer";
import Footer from "../../layouts/footer";
import { callApi, getTemplates, deleteImage } from "../../services/apiService";
import { isRole } from "../../services/authService";
const urlBase = process.env.REACT_APP_URL_BASE;

const AgencyTemplates = () => {
  const { t } = useTranslation();
  const [templates, setTemplates] = useState([]);

  const isAgency = isRole(RoleEnum.agency);

  useEffect(() => {
    callApi(
      () => getTemplates(),
      (data) => {
        setTemplates(data);
      }
    );
  });

  const callDeleteImage = (id, path) => {
    const data = {
      imageId: id,
      imagePath: path,
    };
    callApi(
      () => deleteImage(data),
      () => {}
    );
  };

  return (
    <>
      <MainContainer>
        <h1>{t("main.templates")}</h1>

        {isAgency && <UploadTemplate />}

        <div>
          {templates.map((m) => {
            return (
              <div className="card margin-s gallery-container" key={m.id}>
                <img
                  className="gallery-preview"
                  src={urlBase + m.path.replace("WorldWideFiles", "")}
                  alt="img"
                />
                <div className="card-body">
                  <h5 className="card-title">{m.file_name}</h5>
                  <p className="card-text">imagen</p>
                  {isAgency && (
                    <div>
                      <Button
                        size="btn-sx"
                        label={t("main.delete")}
                        onClick={() => callDeleteImage(m.id, m.path)}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </MainContainer>
      <Footer />
    </>
  );
};

export default AgencyTemplates;
