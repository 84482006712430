import React from "react";
import { useTranslation } from "react-i18next";
import FilesTable from "../../components/filesTable";

const InterviewWwcs = () => {
  const { t } = useTranslation();
  const files = [
    {
      name: "WW English-Evaluation-Ratings 2024",
      description: "help.interviewEvaluationRatingsDescription",
      url: "/download/WW English-Evaluation-Ratings 2024.pdf",
    },
  ];
  return (
    <>
      <h2 className="title">{t("material.interviewWWCE")}</h2>
      <FilesTable files={files} />

    </>
  );
};

export default InterviewWwcs;
