import { getToken, onMessage } from "firebase/messaging";
import { messaging, config } from "./firebase";

export const getTokenMsg = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey: config.vapidKey,
    });
    return token;
  } catch (error) {
    console.log(error);
  }
};

export const sendNotification = async () => {
  //const FIREBASE_API_KEY = "AAAAsOtw1k4:APA91bFjML2whQV8P9BOCKZ4rfZXLIGYeVfF23fuG2coZ6akMTKp-bLkKOPWmQumfejXWlI8xOJLwOKo-it-2hFiDkqxJcv9K4anIC61M4-5-UXsfXDVMnc6niEaLmTvMUGdYjvyV5ov";
  const FIREBASE_API_KEY = config.tokenAccess;
  //const FIREBASE_API_KEY = "BF7Vx80xyW7ObTfowDorzYS5EmycfjtxqTnKefauaCX4n2Ldfjgsf1HyZJzNqlUNyOLT4358YtsKzhz0ILEYR0E";

  const message = {
    //registration_ids:["fJ3oBkQbz6fAAByltYz54A:APA91bFwOJ0UB2Bef5e5c2LXiqqUCWWJeg4Yirdj7aWCccVaB9fHsoTXye9xJJCCiKzQC2E75x20YoNb4_UKVAes96Un6N8ZeOJuHiamu754d37u1OPlYAjx3D4DnXg0ao93mQ_vzohq"],
    // notification:{
    //   title:"Example from API Migueeeeeeeeeeeeee",
    //   body:"Example body",
    //   "vibrate":1,
    //   "sound":1,

    token:
      "fJ3oBkQbz6fAAByltYz54A:APA91bFwOJ0UB2Bef5e5c2LXiqqUCWWJeg4Yirdj7aWCccVaB9fHsoTXye9xJJCCiKzQC2E75x20YoNb4_UKVAes96Un6N8ZeOJuHiamu754d37u1OPlYAjx3D4DnXg0ao93mQ_vzohq",
    notification: {
      body: "body",
      title: "title",
    },
  };

  let headers = new Headers({
    "Content-Type": "application/json",
    Authorization: "Bearer " + FIREBASE_API_KEY,
    //"Authorization": "key=" + FIREBASE_API_KEY,
  });

  //let response = await fetch("https://fcm.googleapis.com/fcm/send",{method:"POST", headers, body: JSON.stringify(message)})
  let response = await fetch(
    "https://fcm.googleapis.com/v1/projects/notification-32f9c/messages:send",
    {
      method: "POST",
      headers,
      body: JSON.stringify({
        message: {
          token:
            "fJ3oBkQbz6fAAByltYz54A:APA91bFwOJ0UB2Bef5e5c2LXiqqUCWWJeg4Yirdj7aWCccVaB9fHsoTXye9xJJCCiKzQC2E75x20YoNb4_UKVAes96Un6N8ZeOJuHiamu754d37u1OPlYAjx3D4DnXg0ao93mQ_vzohq",
          notification: {
            title: "Background Message Title",
            body: "Background message body",
          },
          webpush: {
            fcm_options: {
              link: "https://dummypage.com",
            },
          },
        },
      }),
    }
  );
  response = await response.json();
};
