import React from "react";
//import { IntlGetText } from "util/IntlMessages";
import i18next from "../../i18n";
import MUIDataTable from "mui-datatables";
import * as dayjs from "dayjs";

export const dateColumn = (
  name,
  textId,
  format = "YYYY/MM/DD",
  filter = false
) => ({
  name: name,
  label: i18next.t(textId),
  options: {
    filter: filter,
    sort: true,
    customBodyRender: (value, tableMeta, updateValue) => {
      return value ? dayjs(value).format(format) : "";
    },
  },
});

export const defaultCol = (name, textId) => ({
  name: i18next.t(name),
  label: i18next.t(textId),
  options: {
    filter: true,
    sort: true,
  },
});

export const numberCol = (name, textId, locale, fnFormat) => ({
  name: name,
  label: i18next.t(textId),
  options: {
    filter: true,
    sort: true,
    customBodyRender: (value, tableMeta, updateValue) => {
      return value ? fnFormat(locale, value) : "";
    },
  },
});

export const defaultOptions = () => ({
  filterType: "dropdown",
  responsive: "simple",
  download: false,
  print: false,
  selectableRows: "none",
  textLabels: getTextLabels(),
});
export const minimalOptions = () => ({
  filter: false,
  responsive: "simple",
  download: false,
  print: false,
  selectableRows: "none",
  search: false,
  viewColumns: false,
  textLabels: getTextLabels(),
  pagination: false,
});

export const getTextLabels = () => {
  return {
    body: {
      noMatch: i18next.t("core.datatable.body.noMatch"),
      toolTip: i18next.t("core.datatable.body.toolTip"),
      columnHeaderTooltip: (column) =>
        i18next.t("core.datatable.body.columnHeaderTooltip") +
        `${column.label}`,
    },
    pagination: {
      next: i18next.t("core.datatable.pagination.next"),
      previous: i18next.t("core.datatable.pagination.previous"),
      rowsPerPage: i18next.t("core.datatable.pagination.rowsPerPage"),
      displayRows: i18next.t("core.datatable.pagination.displayRows"),
    },
    toolbar: {
      search: i18next.t("core.datatable.toolbar.search"),
      downloadCsv: i18next.t("core.datatable.toolbar.downloadCsv"),
      print: i18next.t("core.datatable.toolbar.print"),
      viewColumns: i18next.t("core.datatable.toolbar.viewColumns"),
      filterTable: i18next.t("core.datatable.toolbar.filterTable"),
    },
    filter: {
      all: i18next.t("core.datatable.filter.all"),
      title: i18next.t("core.datatable.filter.title"),
      reset: i18next.t("core.datatable.filter.reset"),
    },
    viewColumns: {
      title: i18next.t("core.datatable.viewColumns.title"),
      titleAria: i18next.t("core.datatable.viewColumns.titleAria"),
    },
    selectedRows: {
      text: i18next.t("core.datatable.selectedRows.text"),
      delete: i18next.t("core.datatable.selectedRows.delete"),
      deleteAria: i18next.t("core.datatable.selectedRows.deleteAria"),
    },
  };
};

const Table = (props) => {
  return <MUIDataTable {...props} />;
};

export default Table;
