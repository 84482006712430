import React from "react";
import { useTranslation } from "react-i18next";

const InterviewWork = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className="title">{t("material.interviewWork")}</h2>
    </>
  );
};

export default InterviewWork;
