import React from "react";
import { useTranslation } from "react-i18next";
import FilesTable from "../../components/filesTable";

const VideoAndCv = () => {
  const { t } = useTranslation();
  const files = [
    {
      name: "Tips para Un Resume Eficaz",
      description: "help.tipsResumeDescription",
      url: "/download/Tips para Un Resume Eficaz.pdf",
    },
    {
      name: "Ejemplo Resume - intranet WW 2024",
      description: "help.cvDescription",
      url: "/download/Ejemplo Resume - intranet WW 2024.pdf",
    },
    {
      name: "Tips para elaborar un Video de Presentación",
      description: "help.videoDescription",
      url: "/download/Tips para elaborar un Video de Presentación.pdf",
    },
  ];

  return (
    <>
      <h2 className="title">{t("material.videoAndCv")}</h2>
      <FilesTable files={files} />
    </>
  );
};

export default VideoAndCv;
