import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import WordWideWT from "./routes";
import "./styles/index.scss";
import "react-notifications/lib/notifications.css";

import "./i18n";
import { store } from "./redux/index";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <WordWideWT />
          <NotificationContainer />
        </BrowserRouter>
      </Provider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();
reportWebVitals();
