import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../../components/buttons/button";
import { getUserInfo } from "../../services/authService";
import {
  callApi,
  setOfficeUse,
  getOfficeUseInfo,
} from "../../services/apiService";
import { NotificationManager } from "react-notifications";
import { FormSteps, generalStatesPostulation } from "../../constants/enum";

const OfficeUse = ({ setState, postulationState, programName }) => {
  const { t } = useTranslation();

  const [postulationId, setPostulationId] = useState("");
  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    const uId = getUserInfo().id;
    callApi(
      () => getOfficeUseInfo({ uId: uId }),
      (data) => {
        loadpostulationData(data);
        setPostulationId(data.postulationId);
      }
    );
  }, []);

  const loadpostulationData = (obj) => {
    setValue(
      "extra_comments",
      !obj || !obj.extra_comments ? "" : obj.extra_comments
    );
  };

  const validations = {};

  const onSubmit = handleSubmit((data) => {
    const uId = getUserInfo().id;

    callApi(
      () =>
        setOfficeUse({
          ...data,
          id: postulationId,
          postulationState: postulationState,
        }),
      () => {
        NotificationManager.success(t("form.success"));
        setState(FormSteps.BillingInformation);
      }
    );
  });

  const backForm = () => {
    setState(FormSteps.HealthInformation);
  };

  return (
    <>
      <FormProvider {...methods}>
        <h2 className="title">{t("form.program") + programName}</h2>
        <h2>{t("OfficeUse.title")}</h2>{" "}
        <form>
          <div className="container-inputs">
            <div className="inputs-style-2" style={{ width: "-moz-available" }}>
              <label>{t("OfficeUse.comments")}</label>
              <textarea
                placeholder={t("OfficeUse.comments")}
                className="form-control mb-2"
                rows="3"
                {...register("extra_comments", validations.extra_comments)}
              ></textarea>
            </div>
          </div>
          <div className="inputs-style">
            <p style={{ textAlign: "center" }}>
              {t("OfficeUse.authorizationB")}
            </p>
          </div>
          <div className="container-inputs">
            <Button
              size="btn-sx"
              type="ww-btn-secondary"
              label={t("main.back")}
              onClick={() => backForm()}
            />
            <Button label={t("main.next")} size="btn-sx" onClick={onSubmit} />
          </div>
        </form>
        {postulationState >= generalStatesPostulation.uploadingFiles && (
          <Button
            type="ww-btn-secondary"
            onClick={() => {
              setState(FormSteps.FinishedForm);
            }}
            label={t("main.backPostulation")}
          />
        )}
      </FormProvider>
    </>
  );
};

export default OfficeUse;
