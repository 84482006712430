import React, { useEffect, useState } from "react";
import { callApi, getImages, deleteImage } from "../../services/apiService";
import { useTranslation } from "react-i18next";
import { getUserInfo } from "../../services/authService";
import MainContainer from "../../layouts/container/mainContainer";
import Footer from "../../layouts/footer";
import "../../styles/pages/gallery.scss";
import Button from "../../components/buttons/button";
const urlBase = process.env.REACT_APP_URL_BASE;


const Gallery = () => {
  const { t } = useTranslation();
  var userInfo = getUserInfo();
  const userId = {
    userId: userInfo.id,
  };

  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    callApi(
      () => getImages(userId),
      (data) => {
        setGallery(data);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callDeleteImage = (id, path) => {
    const data = {
      imageId: id,
      imagePath: path,
    };
    callApi(
      () => deleteImage(data),
      (data) => {
      }
    );
  };

  return (
    <>
      <MainContainer>
        <h1>{t("main.gallery")}</h1>
        <div>
          {gallery.map((m) => {
            return (
              <div className="card margin-s gallery-container" key={m.id}>
                <img
                  className="gallery-preview"
                  src={urlBase + m.path.replace("WorldWideFiles", "")}
                  alt="img"
                />
                <div className="card-body">
                  <h5 className="card-title">{m.file_name}</h5>
                  <p className="card-text">imagen</p>
                  <div>
                    <Button
                      size="btn-sx"
                      label={t("main.delete")}
                      onClick={() => callDeleteImage(m.id, m.path)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </MainContainer>
      <Footer />
    </>
  );
};

export default Gallery;
