import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  callApi,
  saveTravelerVisa,
  getTravelerVisa,
  getTravelerRelatives,
} from "../../services/apiService";
import { getUserInfo } from "../../services/authService";
import { NotificationManager } from "react-notifications";
import Button from "../../components/buttons/button";
import { FormProvider, useForm } from "react-hook-form";
import { FormSteps, generalStatesPostulation, yesNo } from "../../constants/enum";
import AutocompleteSelect from "../../components/forms/inputsMui/autocomplete";
import CloseRelatives from "./closeRelatives";

const VisaInformation = ({ setState, postulationState , programName}) => {
  const { t } = useTranslation();
  const [idTravelerVisa, setIdTravelerVisa] = useState("");
  const [activeListRelative, setActiveListRelative] = useState(true);
  const [relativesList, setRelativesList] = useState([]);
  const numberSi = 1;
  const numberNo = 2;
  const numberToBool = (number) => {
    if (number == numberSi) {
      return true;
    } else {
      return false;
    }
  };
  const BoolToNumber = (boolVariable) => {
    if (boolVariable) {
      return numberSi;
    } else {
      return numberNo;
    }
  };

  const loadDataFormVisa = (obj) => {
    obj.visited_usa = BoolToNumber(obj.visited_usa);
    obj.previous_j1 = BoolToNumber(obj.previous_j1);
    obj.refused_visa = BoolToNumber(obj.refused_visa);
    obj.relatives_usa = BoolToNumber(obj.relatives_usa);
    obj.arrested = BoolToNumber(obj.arrested);
    obj.other_type_visa = BoolToNumber(obj.other_type_visa);

    setValue("previousJ1", !obj || !obj.previous_j1 ? "" : obj.previous_j1);
    setValue("visitedUsa", !obj || !obj.visited_usa ? "" : obj.visited_usa);
    setValue(
      "visitedUsaYear",
      !obj || !obj.visited_usa_year ? "" : obj.visited_usa_year
    );
    setValue(
      "visitedUsaSponsor",
      !obj || !obj.visited_usa_sponsor ? "" : obj.visited_usa_sponsor
    );
    setValue("refusedVisa", !obj || !obj.refused_visa ? "" : obj.refused_visa);
    setValue(
      "refusedVisaExplain",
      !obj || !obj.refused_visa_explain ? "" : obj.refused_visa_explain
    );
    setValue("arrested", !obj || !obj.arrested ? "" : obj.arrested);
    setValue(
      "arrestedExplain",
      !obj || !obj.arrested_explain ? "" : obj.arrested_explain
    );
    setValue(
      "closeRelatives",
      !obj || !obj.relatives_usa ? "" : obj.relatives_usa == 2 ? 2 : 1
    );
    setValue(
      "otherTypeVisa",
      !obj || !obj.other_type_visa ? "" : obj.other_type_visa
    );
    setValue(
      "otherTypeVisaTypes",
      !obj || !obj.other_type_visa_types ? "" : obj.other_type_visa_types
    );
  };

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const validations = {
    previousJ1: {
      required: { value: true, message: t("form.visa.error.standar") },
    },
    visitedUsa: {
      required: { value: true, message: t("form.visa.error.standar") },
    },
    arrested: {
      required: { value: true, message: t("form.visa.error.standar") },
    },
    refusedVisa: {
      required: { value: true, message: t("form.visa.error.standar") },
    },
    closeRelatives: {
      required: { value: true, message: t("form.visa.error.standar") },
    },
    refusedVisaExplain: {
      required: { value: true, message: t("form.visa.error") },
      maxLength: { value: 80, message: t("lenghValidation.max80") },
    },
    arrestedExplain: {
      required: { value: true, message: t("form.visa.error") },
      maxLength: { value: 80, message: t("lenghValidation.max80") },
    },
    otherTypeVisa: {
      required: { value: true, message: t("form.visa.error.standar") },
    },
    otherTypeVisaTypes: {
      required: { value: true, message: t("form.visa.error") },
      maxLength: { value: 80, message: t("lenghValidation.max80") },
    },
  };

  useEffect(() => {
    const uId = getUserInfo().id;
    callApi(
      () => getTravelerVisa({ uId: uId }),
      (data) => {
        setIdTravelerVisa(data.id);
        loadDataFormVisa(data);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitVisa = handleSubmit((data) => {
    const uId = getUserInfo().id;
    data.arrested = numberToBool(data.arrested);
    data.previousJ1 = numberToBool(data.previousJ1);
    data.refusedVisa = numberToBool(data.refusedVisa);
    data.visitedUsa = numberToBool(data.visitedUsa);
    data.otherTypeVisa = numberToBool(data.otherTypeVisa);
    if (data.closeRelatives === 1) {
      if (activeListRelative) {
        NotificationManager.warning(t("visa.isNotRelatives"));
        return;
      }
      data.closeRelatives = true;
    } else {
      data.closeRelatives = false;
    }
    callApi(
      () =>
        saveTravelerVisa({
          ...data,
          uId,
          idTravelerVisa,
          postulationState: postulationState,
        }),
      () => {
        NotificationManager.success(t("form.success"));
        setState(FormSteps.LanguageSkills);
      }
    );
  });

  const backForm = () => {
    setState(3);
  };

  return (
    <>
     <h2 className="title">{t("form.program") + programName}</h2>
      <h2>{t("form.visa")}</h2>
      <FormProvider {...methods}>
        <form>
          <div className="container-inputs">
            {/*  previous_j1 */}
            <div className="inputs-style">
              <label>
                {t("form.visa.1")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="visitedUsa"
                label={t("form.visa.1")}
                options={yesNo()}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("visitedUsa") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.visitedUsa && errors.visitedUsa.message}
                </span>
              )}
            </div>
            {/* visitedUsa */}
            <div className="inputs-style">
              <label>
                {t("form.visa.2")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="previousJ1"
                label={t("form.visa.2")}
                options={yesNo()}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("previousJ1") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.previousJ1 && errors.visitedUsa.message}
                </span>
              )}
            </div>
          </div>
          {watch("previousJ1") === 1 ? (
            <div className="container-inputs">
              {/* visitedUsaYear */}
              <div className="inputs-style">
                <label>
                  {t("form.visa.year")}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  placeholder={t("form.visa.year")}
                  className="form-control mb-2"
                  name="visitedUsaYear"
                  {...register("visitedUsaYear", validations.visitedUsaYear)}
                />
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.visitedUsaYear && errors.visitedUsaYear.message}
                </span>
              </div>
              {/* visitedUsaSponsor */}
              <div className="inputs-style">
                <label>
                  {t("form.visa.sponsor")}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  placeholder={t("form.visa.sponsor")}
                  className="form-control mb-2"
                  name="visitedUsaSponsor"
                  {...register(
                    "visitedUsaSponsor",
                    validations.visitedUsaSponsor
                  )}
                />
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.visitedUsaSponsor && errors.visitedUsaSponsor.message}
                </span>
              </div>
            </div>
          ) : (
            <div className="inputs-style">
              {setValue("visitedUsaYear", "")}
              {setValue("visitedUsaSponsor", "")}
            </div>
          )}
          <div className="container-inputs">
            {/* otherTypeVisa */}
            <div className="inputs-style">
              <label>
                {t("form.visa.otherTypeVisa")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="otherTypeVisa"
                label={t("form.visa.otherTypeVisa")}
                options={yesNo()}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("otherTypeVisa") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.otherTypeVisa && errors.otherTypeVisa.message}
                </span>
              )}
            </div>
            {/*otherTypeVisaTypes */}
            {watch("otherTypeVisa") === numberSi ? (
              <div className="inputs-style">
                <label>
                  {t("form.visa.otherTypeVisaTypes")}
                  <span className="text-danger"> *</span>
                </label>
                <textarea
                  placeholder={t("form.visa.otherTypeVisaTypes")}
                  className="form-control mb-2"
                  rows="2"
                  registerOptions={validations}
                  name="otherTypeVisaTypes"
                  {...register(
                    "otherTypeVisaTypes",
                    validations.otherTypeVisaTypes
                  )}
                ></textarea>
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.otherTypeVisaTypes &&
                    errors.otherTypeVisaTypes.message}
                </span>
              </div>
            ) : (
              <div className="inputs-style">
                <label>{t("form.visa.otherTypeVisaTypes")}</label>
                <textarea
                  disabled
                  placeholder={t("form.visa.otherTypeVisaTypes")}
                  className="form-control mb-2"
                  rows="2"
                  name="otherTypeVisaTypes"
                ></textarea>
              </div>
            )}
          </div>
          {/* refusedVisa */}
          <div className="container-inputs">
            <div className="inputs-style">
              <label>
                {t("form.visa.4")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="refusedVisa"
                label={t("form.visa.4")}
                options={yesNo()}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("refusedVisa") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.refusedVisa && errors.refusedVisa.message}
                </span>
              )}
            </div>
            {/* refusedVisaExplain */}
            {watch("refusedVisa") === 1 ? (
              <div className="inputs-style">
                <label>
                  {t("form.visa.explain")}
                  <span className="text-danger"> *</span>
                </label>
                <textarea
                  placeholder={t("form.visa.explain")}
                  className="form-control mb-2"
                  rows="2"
                  registerOptions={validations}
                  name="refusedVisaExplain"
                  {...register(
                    "refusedVisaExplain",
                    validations.refusedVisaExplain
                  )}
                ></textarea>
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.refusedVisaExplain &&
                    errors.refusedVisaExplain.message}
                </span>
              </div>
            ) : (
              <div className="inputs-style">
                <label>{t("form.visa.explain")}</label>
                <textarea
                  disabled
                  placeholder={t("form.visa.explain")}
                  className="form-control mb-2"
                  rows="2"
                  name="refusedVisaExplain"
                ></textarea>
              </div>
            )}
          </div>
          <div className="container-inputs">
            {/* arrested */}
            <div className="inputs-style">
              <label>
                {t("form.visa.arrested")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="arrested"
                label={t("form.visa.arrested")}
                options={yesNo()}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("arrested") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.arrested && errors.arrested.message}
                </span>
              )}
            </div>
            {/* Explain arrested */}
            {watch("arrested") === 1 ? (
              <div className="inputs-style">
                <label>
                  {t("form.visa.explain")}
                  <span className="text-danger"> *</span>
                </label>
                <textarea
                  name="arrestedExplain"
                  placeholder={t("form.visa.explain")}
                  className="form-control mb-2"
                  registerOptions={validations}
                  rows="3"
                  {...register("arrestedExplain", validations.arrestedExplain)}
                ></textarea>
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.arrestedExplain && errors.arrestedExplain.message}
                </span>
              </div>
            ) : (
              <div className="inputs-style">
                <label>{t("form.visa.explain")}</label>
                <textarea
                  disabled
                  name="arrestedExplain"
                  placeholder={t("form.visa.explain")}
                  className="form-control mb-2"
                  rows="2"
                ></textarea>
              </div>
            )}
          </div>
          <div className="container-inputs">
            {/* closeRelatives */}
            <div className="inputs-style">
              <label>
                {t("form.visa.relatives")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="closeRelatives"
                id="closeRelatives-y/n"
                label={t("form.visa.relatives")}
                options={yesNo()}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
                disabled={!activeListRelative}
              />
              {watch("closeRelatives") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.closeRelatives && errors.closeRelatives.message}
                </span>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
      {watch("closeRelatives") === 1 && (
        <>
          <CloseRelatives setActiveListRelative={setActiveListRelative} />
        </>
      )}
      <div className="container-inputs">
        <Button
          size="btn-sx"
          type="ww-btn-secondary"
          label={t("main.back")}
          onClick={() => backForm()}
        />
        <Button size="btn-sx" onClick={onSubmitVisa} label={t("main.next")} />
      </div>
      {postulationState >= generalStatesPostulation.uploadingFiles && (
        <Button
          type="ww-btn-secondary"
          onClick={() => {
            setState(FormSteps.FinishedForm);
          }}
          label={t("main.backPostulation")}
        />
      )}
    </>
  );
};

export default VisaInformation;
