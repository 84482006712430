import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/buttons/button";
import "../../styles/pages/postulationState.scss";

import MainContainer from "../../layouts/container/mainContainer";
import Modal from "@mui/material/Modal";
import { FormProvider, useForm } from "react-hook-form";
import DateInput from "../../components/forms/inputs/dateInput";
import { callApi, getInformationFligth } from "../../services/apiService";

const FligthDataModal = ({ open, close, postulationId }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    close();
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    const dataToGet = {
      postulationId: parseInt(postulationId),
    };
    callApi(
      () => getInformationFligth(dataToGet),
      (data) => {
        loadData(data);
      }
    );
  }, [open]);

  const validations = {
    lodging: {
      required: { value: true, message: t("healthInfo.required") },
    },
    dateOutboundFlight: {
      required: { value: true, message: t("healthInfo.required") },
    },
  };
  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = methods;

  const loadData = (obj) => {
    setValue("lodging", !obj || !obj.lodging ? "" : obj.lodging);
    setValue(
      "dateOutboundFlight",
      !obj || !obj.dateOutbound ? "" : new Date(obj.dateOutbound)
    );
    setValue(
      "dateReturnFlight",
      !obj || !obj.dateReturn ? "" : new Date(obj.dateReturn)
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer showImage={false} class="ww-container-m">
          <h1 className="title">
            {t("postulationState.flightAndAccommodation")}
          </h1>
          <br />
          <FormProvider {...methods}>
            <form encType="multipart/form-data" className="form-m">
              <div className="mb-3">
                <label>{t("postulation.direction")}</label>
                <input
                  disabled
                  placeholder={t("register.direction")}
                  className="form-control mb-2"
                  name="lodging"
                  {...register("lodging", validations.lodging)}
                />
              </div>
              <div className="mb-3">
                <DateInput
                  disabled
                  name="dateOutboundFlight"
                  control={control}
                  placeholder={t("flight.outboundFlight")}
                  label={<label>{t("flight.outboundFlight")}</label>}
                  {...register(
                    "dateOutboundFlight",
                    validations.dateOutboundFlight
                  )}
                />
              </div>
              <div className="mb-3">
                <DateInput
                  name="dateReturnFlight"
                  control={control}
                  placeholder={t("flight.returnFlight")}
                  label={<label>{t("flight.returnFlight")}</label>}
                  disabled
                  {...register("dateReturnFlight", validations.dateInterview)}
                />
              </div>
            </form>
          </FormProvider>
          <Button
            type="ww-btn-secondary"
            size="btn-xs secondary"
            label={t("main.close")}
            onClick={handleClose}
          />
        </MainContainer>
      </Modal>
    </>
  );
};

export default FligthDataModal;
