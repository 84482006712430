import i18next from "../i18n";

export const RoleEnum = {
  traveler: "TRAVELER",
  agency: "AGENCY",
  admin: "ADMIN",
};

export const AccountState = () => [
  { id: 1, name: "Activo" },
  { id: 2, name: "Inactivo" },
  { id: 3, name: "Suspendida" },
];

export const courseState = [
  { id: "Planeado", name: "Planeado" },
  { id: "En curso", name: "En curso" },
  { id: "Finalizado", name: "Finalizado" },
  { id: "Cancelado", name: "Cancelado" },
];

export const genderEnum = () => [
  { id: 1, name: i18next.t("form.male") },
  { id: 2, name: i18next.t("form.female") },
  { id: 3, name: i18next.t("form.x") },
];

export const languages = () => [
  { id: "GERMAN", name: i18next.t("language.German") },
  { id: "KOREAN", name: i18next.t("language.Korean") },
  { id: "MANDARIN", name: i18next.t("language.Mandarin") },
  { id: "SPANISH", name: i18next.t("language.Spanish") },
  { id: "FRENCH", name: i18next.t("language.French") },
  { id: "JAPANESE", name: i18next.t("language.Japanese") },
  { id: "ENGLISH", name: i18next.t("language.English") },
  { id: "PORTUGUESE", name: i18next.t("language.Portuguese") },
  { id: "RUSSIAN", name: i18next.t("language.Russian") },
];

export const language_skills = () => [
  { id: "BASIC", name: i18next.t("language.basic") },
  { id: "PRE_INTERMEDIATE", name: i18next.t("language.preIntermediate") },
  { id: "INTERMEDIATE", name: i18next.t("language.intermediate") },
  { id: "HIGH_MIDIUM", name: i18next.t("language.highMidium") },
  { id: "ADVANCED", name: i18next.t("language.advanced") },
];

export const yesNo = () => [
  { id: 1, name: i18next.t("main.yes") },
  { id: 2, name: i18next.t("main.no") },
];

export const applicationState = () => [
  { id: 1, name: i18next.t("main.stateA") },
  { id: 2, name: i18next.t("main.stateB") },
];

export const cuitDniCuil = () => [
  { id: 1, name: "CUIT" },
  { id: 2, name: "DNI" },
  { id: 3, name: "CUIL" },
];

export const fileType = () => [
  { id: 1, name: i18next.t("form.picture") },
  { id: 2, name: i18next.t("form.passportvigent") },
  { id: 3, name: i18next.t("form.certify") },

  { id: 5, name: i18next.t("form.ExVisaJ1") },
  { id: 7, name: i18next.t("form.CV") },
  { id: 6, name: i18next.t("form.other") },
];


export const correctionOptions = () => [
  { id: 1, name: i18next.t("correction.form") },
  { id: 2, name: i18next.t("correction.files") },
];

export const videoCorrectionOptions = () => [
  { id: 1, name: i18next.t("video.stateII") }, //"Update video",
  { id: 2, name: i18next.t("video.stateIII") }, //"Update CV",
  { id: 3, name: i18next.t("video.stateIV") }, //"Update information",
];

export const FileTypeId = {
  Picture: "1",
  PassportVigent: "2",
  Certify:"3",
  GeneralConditions: "4",
  ExVisaJ1:"5",
  Other:"6",
  Cv:"7",
  PostulationForm:"8",
  Flight:"9",
  PassportVigent2: "10",
};

export const FormSteps = {
  PassportDetails: 1,
  ContactDetails: 2,
  UniversityInformation: 3,
  VisaInformation: 4,
  LanguageSkills: 5,
  AdditionalInfo: 6,
  WorkDates: 7,
  HealthInformation: 8,
  OfficeUse: 9,
  BillingInformation: 10,
  GeneralConditions: 11,
  UploadFiles: 12,
  SignStep: 13,
  DownloadStep: 14,
  FinishedForm:15,
};

export const generalStatesPostulation = {
  postulationFormPending: 1,
  postulationForm: 2,
  uploadingFilesPending: 3,
  uploadingFiles: 4,
  interviewWwcePending: 5,
  interviewWwce: 6,
  videoAndCvPending: 7,
  videoAndCv: 8,
  mockInterviewPending:9,
  mockInterview:10,
  employerSelectionPending:11,
  employerSelection:12,
  interviewWorkPending:13,
  interviewWork:14,
  jobOffer:15,
  interviewConsularPending:16, 
  interviewConsular:17, 
  flightAndAccommodationPending:18,
  flightAndAccommodation:19,
  preTripTraining:20,
  pendingAnswer: 21,
  aproved: 22,
  declined: 23,
  declinedClosed: 24,
};

export const formFilesStateEnum = {
  pendingToSend: 1,
  pendingToSendFiles: 2,
  approved: 3,
  revision: 4,
  toUpdate: 5,
  finalized: 6,
};

export const interviewStateEnum = {
  unscheduled: 1,
  scheduled: 2,
  interviewed: 3,
  inStudy: 4,
  aproved: 5,
  declined: 6,
};

export const videoState = {
  withoutSending: 0,
  review: 1,
  updateVideo: 2,
  updateCv: 3,
  updateInformation: 4,
  aproved: 5,
  declined: 6,
};

export const employerDestinyType = () => [
  { id: 1, name: i18next.t("employer.snow") },
  { id: 2, name: i18next.t("employer.noSnow") },
];

export const employerState = () => [
  { id: 1, name: i18next.t("employer.active") },
  { id: 2, name: i18next.t("employer.inactive") },
];

export const educationalMaterialStep = {
  form: 1,
  interviewWwce:2
};

export const ProgramId = {
  WorkTravelUSA: 5,
};

export const typeFiles = {
  pdf: 1,
  video: 2,
};


export const reportEnum = {
  selectedEmployers: 1,
  employersActived: 2,
  travelers:3
};