import React from "react";
import { useNavigate } from "react-router-dom";
import { MdCheckCircle } from "react-icons/md";
import { useTranslation } from "react-i18next";
import MainContainer from "../../layouts/container/mainContainer";
import Footer from "../../layouts/footer";
import "../../styles/pages/login.scss";
import Button from "../../components/buttons/button";

const ChangeSuccess = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  return (
    <>
      <MainContainer class="ww-container-m">
        <h1 className="icon-xl">
          <MdCheckCircle />
        </h1>
        <p className="text">{t("recoverPassword.success")}</p>
        <Button onClick={() => navigate("/")} label={t("main.goToStart")} />
      </MainContainer>
      <Footer />
    </>
  );
};

export default ChangeSuccess;
