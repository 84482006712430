import React, { useEffect, useState } from "react";
import { BsXLg } from "react-icons/bs";
import "../../styles/pages/login.scss";

const InstallPWA = () => {
  const [isShows, setIsShows] = useState(false);
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setIsShows(true);
      setPromptInstall(e);
    };

    window.addEventListener("beforeinstallprompt", handler);
    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  if (!supportsPWA) {
    return null;
  }

  return (
    <>
      {isShows && (
        <div
          className={isShows ? "ModalContainerPWA show" : "ModalContainerPWA"}
        >
          <div className="InnerModalContainerPWA">
            <img className="logoPWA" src="/images/WorldWide-OpenGraph.png" alt="" />
            <p
              className="DownloadPWA"
              id="setup_button"
              aria-label="Install app"
              title="Install app"
              onClick={onClick}
            >
              Agrega WWCE a la pantalla principal
            </p>
          </div>
          <div className="ButtonPWAClose" onClick={() => setIsShows(false)}>
            <BsXLg />
          </div>
        </div>
      )}
    </>
  );
};

export default InstallPWA;
