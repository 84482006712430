import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import JSZip from "jszip";
import Button from "../../../components/buttons/button";
import "../../../styles/pages/postulationState.scss";
import { FileTypeId, RoleEnum } from "../../../constants/enum";
import { callApi, getImages } from "../../../services/apiService";
import MainContainer from "../../../layouts/container/mainContainer";
import Modal from "@mui/material/Modal";
import Table, { defaultOptions } from "../../../components/table";
import { AiFillEye, AiOutlineDownload, AiFillPlusCircle } from "react-icons/ai";
import { getUserInfo, isRole } from "../../../services/authService";
import UploadFile from "./files/uploadFile";

const urlBase = process.env.REACT_APP_URL_BASE;

const FileListModal = ({
  closeHandleFileListModal,
  openFileListModal,
  postulationId,
}) => {
  const { t } = useTranslation();
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isTraveler = isRole(RoleEnum.traveler);
  const userId = getUserInfo().id;

  const [deletePath, setDeletePath] = useState("");
  const [documents, setDocuments] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const [previewView, setPreviewModal] = useState(false);
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const handleOpenUploadFile = () => {
    setOpenUploadFile(true);
  };
  const handleCloseUploadFile = () => {
    setOpenUploadFile(false);
    loadFiles(userId, postulationId);
  };

  const handleClose = () => {
    closeHandleFileListModal();
  };

  const handleClosePreview = () => {
    setPreviewModal(false);
  };

  function getCurrentDate() {
    const fechaActual = new Date();
    const año = fechaActual.getFullYear();
    const mes = (fechaActual.getMonth() + 1).toString().padStart(2, "0");
    const día = fechaActual.getDate().toString().padStart(2, "0");
    return `${año}-${mes}-${día}`;
  }
  const getObjFiles = (tableMeta) =>
    documents.find((x) => x.id === tableMeta.rowData[0]);

  useEffect(() => {
    if (!openFileListModal) {
      return;
    }
    if (userId == null) {
      return;
    }
    loadFiles(userId, postulationId);
  }, [openFileListModal]);

  const loadFiles = (userId, postulationId) => {
    callApi(
      () => getImages({ userId: userId, postulationId: postulationId }),
      (data) => {
        setDocuments(data);
      }
    );
  };

  const columnsModal = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    {
      name: "file_type_id",
      label: t("files.type"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, documents, updateValue) => {
          var rowObjFile = getObjFiles(documents);
          switch (rowObjFile.file_type_id) {
            case FileTypeId.Picture:
              return t("form.picture");
            case FileTypeId.PassportVigent:
              return t("form.passportvigent");
            case FileTypeId.Certify:
              return t("form.certify");
            case FileTypeId.GeneralConditions:
              return t("form.conditions");
            case FileTypeId.ExVisaJ1:
              return t("form.ExVisaJ1");
            case FileTypeId.Other:
              return t("form.other");
            case FileTypeId.Cv:
              return t("form.CV");
            case FileTypeId.PostulationForm:
              return t("form.postulationForm");
            case FileTypeId.Flight:
              return t("general.flight");
            case FileTypeId.PassportVigent2:
              return t("form.passportvigent2");
            default:
              return t("form.other");
          }
        },
      },
    },
    {
      name: "Actions",
      //label: t("Link"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, documents, updateValue) => {
          var rowObjFile = getObjFiles(documents);
          const hasFileTypePss2 = documents.tableData.some(doc => doc.file_type_id === FileTypeId.PassportVigent2);

          return (
            <div className="table-btn-group">
              {!isAndroid && (
                <Button
                  label={<AiFillEye />}
                  onClick={() => {
                    setPreviewModal(true);
                    setDeletePath(rowObjFile.path);
                  }}
                />
              )}
              <a
                href={urlBase + deletePath.replace("WorldWideFiles", "")}
                download
                target="_blank"
              >
                <Button
                  label={<AiOutlineDownload />}
                  onClick={() => {
                    setDeletePath(rowObjFile.path);
                  }}
                />
              </a>
              {rowObjFile.file_type_id == FileTypeId.PassportVigent &&
                isTraveler && !hasFileTypePss2 && (
                  <Button
                    label={<AiFillPlusCircle />}
                    onClick={() => {
                      handleOpenUploadFile();
                    }}
                  />
                )}
            </div>
          );
        },
      },
    },
  ];

  const handleDownloadZIP = async () => {
    const zip = new JSZip();
    const listaDePaths = documents.map((objeto) => ({
      path: urlBase + objeto.path.replace("WorldWideFiles", ""),
      name: objeto.file_name,
    }));
    try {
      await Promise.all(
        listaDePaths.map(async (archivo) => {
          const response = await axios.get(archivo.path, {
            responseType: "blob",
          });
          const ext = archivo.path.split(".").pop().toLowerCase();
          let nombreArchivo = archivo.name;
          if (ext === "pdf") {
            nombreArchivo = nombreArchivo + ".pdf";
          }
          zip.file(nombreArchivo, response.data);
        })
      );
      zip.generateAsync({ type: "blob" }).then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download =
          currentUser.course_name +
          "_" +
          currentUser.name +
          "_" +
          currentUser.lastName +
          "_" +
          getCurrentDate() +
          "_" +
          ".zip";
        a.click();
      });
    } catch (error) {
      console.error(`Error al descargar los archivos ficticios`, error);
    }
  };
  return (
    <>
      {/* MODAL TABLA DE DOCUMENTOS */}
      <Modal
        open={openFileListModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer showImage={false} class="ww-container-xxl">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <Table
              data={documents}
              columns={columnsModal}
              options={defaultOptions()}
            />
            <div className="table-btn-group">
              <Button
                style={{ margin: "10px" }}
                size="btn-s secondary"
                label={t("File.Zip")}
                onClick={handleDownloadZIP}
              />
              <Button
                type="ww-btn-secondary"
                style={{ margin: "10px" }}
                size="btn-s secondary"
                label={t("main.close")}
                onClick={handleClose}
              />
            </div>
          </div>
        </MainContainer>
      </Modal>
      {/* MODAL PREVISUALIZACIÓN DOCUMENTOS */}
      <Modal
        open={previewView}
        onClose={handleClosePreview}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer class="ww-container-m" Nologo={true}>
          <p>{t("form.previewView")}</p>
          <embed
            src={urlBase + "/" + deletePath.replace("WorldWideFiles", "")}
            className="file-preview-view"
          />
          <div>
            <Button
              type="ww-btn-secondary"
              style={{ margin: "10px" }}
              size="btn-s secondary"
              label={t("main.close")}
              onClick={() => handleClosePreview()}
            />
          </div>
        </MainContainer>
      </Modal>
      <UploadFile
        open={openUploadFile}
        onClose={handleCloseUploadFile}
        userId={userId}
      />
    </>
  );
};

export default FileListModal;
