import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  callApi,
  getCountries,
  getUserInfoBd,
  getTravelerPassport,
  saveTravelerPassport,
} from "../../services/apiService";
import { getUserInfo } from "../../services/authService";
import { NotificationManager } from "react-notifications";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../../components/buttons/button";
import { genderEnum, generalStatesPostulation } from "../../constants/enum";
import AutocompleteSelect from "../../components/forms/inputsMui/autocomplete";
import DateInput from "../../components/forms/inputs/dateInput";
import { FormSteps } from "../../constants/enum";

const PassportDetails = ({ setState, courseId, postulationState, programName }) => {
  const { t } = useTranslation();
  const [countryList, setCountryList] = useState([]);
  const [age, setAge] = useState(0);
  const [idPassportDetails, setIdPassportDetails] = useState(null);
  const [postulationId, setPostulationId] = useState(null);

  const defaultValues = {};
  const loadData = (obj) => {
    setValue("name", !obj || !obj.name ? "" : obj.name);
    setValue("familyName", !obj || !obj.last_name ? "" : obj.last_name);
  };
  const loadDataForm = (obj) => {
    setValue(
      "dateBirth",
      !obj || !obj.date_birth ? "" : new Date(obj.date_birth)
    );
    setValue(
      "dateExpiry",
      !obj || !obj.passport_expiry ? "" : new Date(obj.passport_expiry)
    );
    setValue(
      "dateIssue",
      !obj || !obj.passport_issue ? "" : new Date(obj.passport_issue)
    );
    setValue("genre", !obj || !obj.genre ? "" : obj.genre);
    setValue(
      "passportNumber",
      !obj || !obj.passport_number ? "" : obj.passport_number
    );
    setValue("dni", !obj || !obj.dni ? "" : obj.dni);
    setValue("cityBirth", !obj || !obj.city_birth ? "" : obj.city_birth);
    setValue(
      "countryBirth",
      !obj || !obj.country_birth_id ? "" : obj.country_birth_id
    );
    setValue(
      "countryCitizenship",
      !obj || !obj.country_citizenship_id ? "" : obj.country_citizenship_id
    );
    setValue(
      "countryPermanence",
      !obj || !obj.country_legal_permanent_id
        ? ""
        : obj.country_legal_permanent_id
    );
  };

  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    const uInfo = getUserInfo();
    callApi(
      () => getUserInfoBd(uInfo),
      (data) => {
        loadData(data[0]);
      }
    );
    const uId = parseInt(getUserInfo().id);
    var dataToGet = {
      uId: uInfo,
      postulationState: postulationState,
    };
    callApi(
      () => getTravelerPassport(dataToGet),
      (data) => {
        setIdPassportDetails(parseInt(data.id));
        setPostulationId(data.postulationId);
        loadDataForm(data);
      }
    );
    callApi(getCountries, (data) => {
      data.sort((a, b) => a.name.localeCompare(b.name));
      setCountryList(data);
    });
  }, []);

  useEffect(() => {
    setAge(0);
    if (watch("dateBirth") === undefined) {
    } else {
      ageResolve(watch("dateBirth"));
    }
  }, [watch("dateBirth")]);

  const ageResolve = (dateString) => {
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    setAge(edad);
    return;
  };

  const validations = {
    name: {
      required: { value: true, message: t("validationError.name.require") },
    },
    familyName: {
      required: { value: true, message: t("validationError.lastName.require") },
    },
    dni: {
      required: { value: true, message: t("form.validation.dni") },
    },
    dateBirth: {
      required: { value: true, message: t("form.validation.dateBirth") },
    },
    countryBirth: {
      required: { value: true, message: t("form.validation.countryBirth") },
    },
    genre: {
      required: { value: true, message: t("form.validation.genre") },
    },
    countryCitizenship: {
      required: {
        value: true,
        message: t("form.validation.countryCitizenship"),
      },
    },
    countryPermanence: {
      required: {
        value: true,
        message: t("form.validation.countryPermanence"),
      },
    },
    passportNumber: {
      required: { value: true, message: t("form.validation.passportNumber") },
      pattern: {
        value: /[0-9]+/,
        message: t("validationError.number"),
      },
    },
    dateIssue: {
      required: { value: true, message: t("form.validation.dateIssue") },
    },
    dateExpiry: {
      required: { value: true, message: t("form.validation.dateExpiry") },
    },
    cityBirth: {
      required: { value: true, message: t("form.validation.cityBirth") },
    },
  };

  const onSubmit = handleSubmit((data) => {
    const uId = parseInt(getUserInfo().id);
    callApi(
      () =>
        saveTravelerPassport({
          ...data,
          uId,
          idPassportDetails,
          programId: courseId,
          postulationId: postulationId,
          postulationState: postulationState,
        }),
      () => {
        NotificationManager.success(t("form.success"));
        setState(FormSteps.ContactDetails);
      }
    );
  });

  const backForm = () => {
    setState(0);
  };

  return (
    <>
      <h2 className="title">{t("form.program") + programName}</h2>
      <h2>{t("form.passportDetails")}</h2>
      <FormProvider {...methods}>
        <form>
          <div className="container-inputs">
            {/* Lastname */}
            <div className="inputs-style">
              <label>
                {t("form.familyName")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("placeholder.lastname")}
                className="form-control mb-2"
                name="familyName"
                {...register("familyName", validations.familyName)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.familyName && errors.familyName.message}
              </span>
            </div>
            {/* Names */}
            <div className="inputs-style">
              <label>
                {t("form.firstMiddleName")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("placeholder.name")}
                className="form-control mb-2"
                name="familyName"
                {...register("name", validations.name)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.name && errors.name.message}
              </span>
            </div>
          </div>
          <div className="container-inputs">
            {/* DNI */}
            <div className="inputs-style">
              <label>
                {t("form.DNI")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("form.placeHolder.DNI")}
                className="form-control mb-2"
                name="dni"
                {...register("dni", validations.dni)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.dni && errors.dni.message}
              </span>
            </div>
            {/*Date of birth */}
            <div className="inputs-style">
              <DateInput
                name="dateBirth"
                maxDate={new Date()}
                control={control}
                placeholder={t("form.dateBirth")}
                label={
                  <label>
                    {t("form.dateBirth")}
                    <span className="text-danger"> *</span>
                  </label>
                }
                {...register("dateBirth", validations.dateBirth)}
              />
              <span className="text-danger text-small d-block mb-2">
                {errors.dateBirth && errors.dateBirth.message}
              </span>
            </div>
          </div>
          <div className="container-inputs">
            {/* Age */}
            <div className="inputs-style">
              <label>{t("form.age")}</label>
              <input
                placeholder={t("form.age")}
                className="form-control mb-2"
                name="age"
                defaultValue={0}
                value={age == NaN ? 0 : age < 0 ? 0 : age}
                disabled={true}
              />
            </div>
            {/* genre */}
            <div className="inputs-style">
              <label>
                {t("form.genre")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="genre"
                label={t("form.genre")}
                options={genderEnum()}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("genre") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.genre && errors.genre.message}
                </span>
              )}
            </div>
          </div>
          <div className="container-inputs">
            {/* countryBirth */}
            <div className="inputs-style">
              <label>
                {t("form.countryBirth")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="countryBirth"
                label={t("form.countryBirth")}
                options={countryList}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("countryBirth") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.countryBirth && errors.countryBirth.message}
                </span>
              )}
            </div>
            {/* cityBirth */}
            <div className="inputs-style">
              <label>
                {t("form.cityBirth")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("form.cityBirth")}
                className="form-control mb-2"
                name="cityBirth"
                {...register("cityBirth", validations.cityBirth)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.cityBirth && errors.cityBirth.message}
              </span>
            </div>
          </div>
          {/* countryCitizenship */}
          <div className="container-inputs">
            <div className="inputs-style">
              <label>
                {t("form.countryCitizenship")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="countryCitizenship"
                label={t("form.countryCitizenship")}
                options={countryList}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("countryCitizenship") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.countryCitizenship &&
                    errors.countryCitizenship.message}
                </span>
              )}
            </div>
            {/* countryPermanence */}
            <div className="inputs-style">
              <label>
                {t("form.countryPermanence")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="countryPermanence"
                label={t("form.countryPermanence")}
                options={countryList}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("countryPermanence") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.countryPermanence && errors.countryPermanence.message}
                </span>
              )}
            </div>
          </div>
          <div className="container-inputs">
            {/* passportNumber */}
            <div className="inputs-style">
              <label>
                {t("form.passport")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("form.placeHolder.passport")}
                className="form-control mb-2"
                name="passportNumber  "
                {...register("passportNumber", validations.passportNumber)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.passportNumber && errors.passportNumber.message}
              </span>
            </div>
            {/* dateIssue */}
            <div className="inputs-style">
              <DateInput
                name="dateIssue"
                control={control}
                placeholder={t("form.dateIssue")}
                label={
                  <label>
                    {t("form.dateIssue")}
                    <span className="text-danger"> *</span>
                  </label>
                }
                {...register("dateIssue", validations.dateIssue)}
              />
              <span className="text-danger text-small d-block mb-2">
                {errors.dateIssue && errors.dateIssue.message}
              </span>
            </div>
          </div>
          <div className="container-inputs">
            {/* dateExpiry */}
            <div className="inputs-style">
              <DateInput
                minDate={watch("dateIssue")}
                name="dateExpiry"
                control={control}
                placeholder={t("form.dateExpiry")}
                label={
                  <label>
                    {t("form.dateExpiry")}
                    <span className="text-danger"> *</span>
                  </label>
                }
                {...register("dateExpiry", validations.dateExpiry)}
              />
              <span className="text-danger text-small d-block mb-2">
                {errors.dateExpiry && errors.dateExpiry.message}
              </span>
            </div>
          </div>
        </form>
      </FormProvider>
      <div className="container-inputs">
        <a href="/registration-form">
          <Button size="btn-sx" label={t("main.back")} />
        </a>
        <Button size="btn-sx" onClick={onSubmit} label={t("main.next")} />
      </div>
      {postulationState >= generalStatesPostulation.uploadingFiles && (
        <Button
          type="ww-btn-secondary"
          onClick={() => {
            setState(FormSteps.FinishedForm);
          }}
          label={t("main.backPostulation")}
        />
      )}
    </>
  );
};

export default PassportDetails;
