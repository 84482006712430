import React, { useEffect, useState } from "react";
import Button from "../../components/buttons/button";
import { getUserInfo } from "../../services/authService";
import { useTranslation } from "react-i18next";
import {
  callApi,
  changeState,
  getTokenAgency,
  updateFormPostulation,
} from "../../services/apiService";
import MainContainer from "../../layouts/container/mainContainer";
import Modal from "@mui/material/Modal";
import { FormSteps } from "../../constants/enum";
import { NotificationManager } from "react-notifications";

const urlBase = process.env.REACT_APP_URL_BASE;
const urlApi = process.env.REACT_APP_API_URL;

const DowloadStep = ({ setState, postulationState, programName }) => {
  const { t } = useTranslation();
  const [downloadBtn, setDownloadBtn] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [open, setOpen] = useState(false);

  const backForm = () => {
    setState(FormSteps.SignStep);
  };

  const nextForm = () => {
    const uId = getUserInfo().id;
    const submitData = {
      uId: uId,
      state: FormSteps.FinishedForm,
      postulationId: postulationState,
    };

    callApi(
      () => changeState(submitData),
      () => {
        setState(FormSteps.FinishedForm);
        NotificationManager.success(t("template.uploadSucces"));
        setAlertModal(false);
        window.location.reload();
      }
    );
  };

  const handleClose = () => setOpen(false);
  const tokensAgency = () => {
    // callApi(() =>
    //   getTokenAgency({
    //     titleMessage: t("pushNotification.title.registeredToAgency"),
    //     bodyMessage: t("pushNotification.body.registeredToAgency"),
    //   })
    // );
    nextForm();
  };

  useEffect(() => {
    setInterval(() => {
      setDownloadBtn(true);
    }, 3000);
  }, []);

  const uploadForm = () => {
    const uId = getUserInfo().id;
    const submitData = {
      uId: uId,
      path: `WorldWideFiles/${getUserInfo().id}/finalForm.pdf`,
      file_type_id: 8,
    };
    callApi(
      () => updateFormPostulation(submitData),
      () => {}
    );
  };

  return (
    <>
    <h2 className="title">{t("form.program") + programName}</h2>
      <br />
      <br />
      <div>{t("downloadStep.message")}</div>
      {downloadBtn ? (
        <div className="inputs-style" style={{ placeSelf: "center" }}>
          <div
            className="container-inputs"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <a
              href={`${urlBase}/${getUserInfo().id}/finalForm.pdf`}
              target="_blank"
              download="w3logo"
            >
              <Button size="btn-s" label={t("downloadStep.link.download")} />
            </a>
          </div>
        </div>
      ) : (
        <div class="spinner-border text-info" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      )}
      <div>
        <br />
        <br />
        <div>{t("downloadStep.message.2")}</div>
        <br />
        <div
            style={{
              textAlign: "-webkit-center",
            }}
          >
          <Button
            size="btn-sx"
            type="ww-btn-secondary"
            label={t("main.back")}
            onClick={() => backForm()}
          />
          <Button
            size="btn-sx"
            onClick={() => {
              uploadForm();
              setAlertModal(true);
            }}
            label={t("main.send")}
          />
        </div>
      </div>
      <Modal
        open={alertModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m">
          <p>{t("pushNotification.alert")}</p>
          <div
            style={{
              textAlign: "-webkit-center",
            }}
          >
            <Button
              style={{ margin: "10px" }}
              type="ww-btn-secondary"
              size="btn-s secondary"
              label={t("main.cancel")}
              onClick={() => setAlertModal(false)}
            />
            <Button
              style={{ margin: "10px" }}
              size="btn-s"
              label={t("pushNotification.BtnConfirmation")}
              onClick={() => tokensAgency()}
            />
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default DowloadStep;
