import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import MainContainer from "../../layouts/container/mainContainer";
import "../../styles/pages/login.scss";
import Button from "../../components/buttons/button";
import Modal from "@mui/material/Modal";
import { callApi, declinePostulation } from "../../services/apiService";

const DeclinePostulationModal = ({
  open,
  onClose,
  currentUser,
  loadDataTable,
}) => {
  const { t } = useTranslation();

  const [botonActivo, setBotonActivo] = useState(false);

  const onSubmit = (data) => {
    var titleNotification = t("titleNotification.declinePostulation");
    var bodyNotification = t("bodyNotification.declinePostulation");

    setBotonActivo(true);
    const submitData = {
      postulationId: parseInt(currentUser.postulation_id),
      userId: parseInt(currentUser.id),
      titleNotification: titleNotification,
      bodyNotification: bodyNotification
    };

    callApi(
      () => declinePostulation(submitData),
      () => {
        NotificationManager.success(t("form.success"));
        onCloseHandle();
        loadDataTable();
      }
    );
    setBotonActivo(false);
  };

  const onCloseHandle = () => {
    onClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseHandle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m" showImage={false}>
          <h1 style={{ margin: "20px 0 20px 0" }} className="title">
            {t("listTraveler.declinePostulation")}
          </h1>
          <p></p>
          <div className="inputs-style">
            <label>
              {t("listTraveler.declinePostulationConfirmation") +
                "" +
                currentUser.lastName +
                " " +
                currentUser.name +
                "?"}
            </label>
          </div>
          <div>
            <div className="table-btn-group">
              <Button
                type="ww-btn-secondary"
                style={{ margin: "10px" }}
                size="btn-s secondary"
                label={t("main.close")}
                onClick={() => {
                  onCloseHandle();
                }}
              />
              <Button
                size="btn-s"
                label={t("listTraveler.decline")}
                onClick={() => {
                  onSubmit();
                }}
              />
            </div>
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default DeclinePostulationModal;
