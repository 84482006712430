import React from "react";
// import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import "../../styles/layouts/footer.scss";

const Footer = () => {
  //const { t } = useTranslation();
  return (
    <>
    {/* <footer>
      <div className="footer-box">
        <Link to="/home">
          <p></p>
        </Link>
        <p>{t("main.footer")}</p>
      </div>
    </footer> */}
    </>
  );
};

export default Footer;
