import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/buttons/button";
import "../../../../styles/pages/postulationState.scss";
import {
  callApi,
  getVideoAndCv,
  saveCvAndVideoState,
  saveVideo,
} from "../../../../services/apiService";
import MainContainer from "../../../../layouts/container/mainContainer";
import Modal from "@mui/material/Modal";
import { NotificationManager } from "react-notifications";
import {
  generalStatesPostulation,
} from "../../../../constants/enum";
import { getUserInfo } from "../../../../services/authService";
import { FormProvider, useForm } from "react-hook-form";
import { AiFillSave } from "react-icons/ai";
import { Grid } from "@mui/material";
import CvResume from "./cvResume";
import UploadFile from "./uploadFile";

const urlBase = process.env.REACT_APP_URL_BASE;
const urlApi = process.env.REACT_APP_API_URL;

const VideoAndCvModal = ({
  handleCloseVideoCvModal,
  openVideoCvModal,
  postulationId,
  setGeneralState,
}) => {
  const { t } = useTranslation();
  const isAndroid = /Android/i.test(navigator.userAgent);
  const [videoUrlModal, setVideoUrlModal] = useState(false);
  const [viewPath, setViewPath] = useState("");
  const [cvId, setCvId] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const uInfo = getUserInfo();

  useEffect(() => {
    if (!openVideoCvModal) {
      setVideoUrl(null);
      return;
    }
    if (postulationId == null || postulationId == 0 || !openVideoCvModal) {
      return;
    }
    const dataToGet = {
      userId: parseInt(getUserInfo().id),
      postulationId: postulationId,
    };
    callApi(
      () => getVideoAndCv(dataToGet),
      (data) => {
        loadData(data);
        setVideoUrl(data.video);
        setViewPath(data.cv);
      }
    );
  }, [openVideoCvModal]);

  const validations = {
    video_url: {
      required: { value: true, message: t("validationError.videoUrl.require") },
    },
  };
  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const loadData = (obj) => {
    setValue("video_url", !obj || !obj.video ? "" : obj.video);
  };

  const onSubmitVideo = handleSubmit((data) => {
    const dataToSend = {
      userId: uInfo.id,
      videoUrl: data.video_url,
      postulationId: postulationId,
    };
    callApi(
      () => saveVideo(dataToSend),
      (data) => {
        setVideoUrl(data.video);
        NotificationManager.success(t("form.success"));
      }
    );
  });

  const next = () => {
    if (videoUrl == "" || videoUrl == null || viewPath == "" || cvId == null) {
      NotificationManager.warning(t("jobPreferences.WarningCvVideo"));
      return;
    }
    callApi(
      () => saveCvAndVideoState({ postulationId: postulationId }),
      () => {
        handleClose();
        setGeneralState(generalStatesPostulation.videoAndCv);
        setVideoUrlModal(false);
      }
    );
  };

  const handleClose = () => {
    handleCloseVideoCvModal();
  };

  return (
    <>
      <Modal
        open={openVideoCvModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer showImage={false} class="ww-container-xl-nf">
          <FormProvider {...methods}>
            <form>
              <div>
                <h4>{t("cv.presentation")}</h4>
              </div>
              <div>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} md={4}>
                    <label>{t("register.video_url")}</label>
                  </Grid>
                  <Grid item xs={9} md={6}>
                    <input
                      placeholder={t("placeholder.video_url")}
                      className="form-control"
                      name="video_url"
                      {...register("video_url", validations.video_url)}
                    />
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <Button label={<AiFillSave />} onClick={onSubmitVideo} />
                  </Grid>
                </Grid>
              </div>
            </form>
          </FormProvider>
          {/* --------------------------------------CV ------------------------------------- */}
          <CvResume
            postulationId={postulationId}
            handleCloseVideoCvModal={handleCloseVideoCvModal}
            openVideoCvModal={openVideoCvModal}
            userId={uInfo.id}
            setCvId={setCvId}
            isBlocked={false}
          />
          {/* ---------------------------------Certificado alumno -------------------------------- */}
          <UploadFile
            viewPath={viewPath}
            setViewPath={setViewPath}
            uId={uInfo.id}
            postulationId={postulationId}
          />
          <div className="container-inputs row-files">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                alignItems: "self-end",
              }}
            ></div>
          </div>
          <div className="btn-container">
            <Button
              type="ww-btn-secondary"
              size="btn-sx"
              label={t("main.closeEnglish")}
              onClick={() => {
                handleClose();
              }}
            />
            <Button
              size="btn-sx"
              label={t("main.nextEnglish")}
              onClick={() => {
                next();
              }}
            />
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default VideoAndCvModal;
