import React from "react";
import { useTranslation } from "react-i18next";
import FilesTable from "../../components/filesTable";
import VideoPlayer from "../../components/videoPlayer";
import { ProgramId } from "../../constants/enum";

const FormMaterial = ({ courseId }) => {
  const { t } = useTranslation();
  const files = [
    {
      name: "home.presentation",
      description: "home.presentationDescription",
      url: "/download/Presentacion2024-25.pdf",
    },
    {
      name: "home.guide",
      description: "home.guideDescription",
      url: "/download/Guia paso a paso 2024.pdf",
    },
    {
      name: "home.glossary",
      description: "home.glossaryDescription",
      url: "/download/Glosario.pdf",
    },
  ];

  return (
    <>
      <h2 className="title">{t("material.form")}</h2>
      {courseId == ProgramId.WorkTravelUSA && (
        <>
          <VideoPlayer
            url="/videos/Work And Travel 2024 Web.mp4"
            title={t("videos.formWorkAndTravel")}
          />
          <FilesTable files={files} />
        </>
      )}
    </>
  );
};

export default FormMaterial;
