import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../../styles/pages/postulationState.scss";
import { generalStatesPostulation } from "../../../constants/enum";
import CarruselProgressMovil from "./carruselProgressMovil";
import CarruselProgress from "./carruselProgress";

const CourseSelectLineProcess = ({ generalState }) => {
  const { t } = useTranslation();

  const getProcessStep = (state) => {
    switch (state) {
      case generalStatesPostulation.postulationFormPending:
      case generalStatesPostulation.postulationForm:
      case generalStatesPostulation.uploadingFilesPending:
      case generalStatesPostulation.uploadingFiles:
        return 0;
      case generalStatesPostulation.interviewWwcePending:
      case generalStatesPostulation.interviewWwce:
        return 1;
      case generalStatesPostulation.videoAndCvPending:
      case generalStatesPostulation.videoAndCv:
        return 2;
      case generalStatesPostulation.mockInterviewPending:
      case generalStatesPostulation.mockInterview:
        return 3;
      case generalStatesPostulation.employerSelectionPending:
      case generalStatesPostulation.employerSelection:
        return 4;
      case generalStatesPostulation.interviewWorkPending:
      case generalStatesPostulation.interviewWork:
        return 5;
      case generalStatesPostulation.jobOffer:
        return 6;
      case generalStatesPostulation.interviewConsularPending:
      case generalStatesPostulation.interviewConsular:
        return 7;
      case generalStatesPostulation.flightAndAccommodationPending:
      case generalStatesPostulation.flightAndAccommodation:
        return 8;
      case generalStatesPostulation.preTripTraining:
        return 9;
      case generalStatesPostulation.pendingAnswer:
        return 10;
      default:
        return 0;
    }
  };
  return (
    <>
      <div
        className="movil"
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CarruselProgressMovil stepProcess={getProcessStep(generalState)} />
      </div>
      <div
        className="desktop"
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CarruselProgress stepProcess={getProcessStep(generalState)} />
      </div>
    </>
  );
};

export default CourseSelectLineProcess;
