import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import DateInput from "../../components/forms/inputs/dateInput";
import Button from "../../components/buttons/button";
import { getUserInfo } from "../../services/authService";
import {
  callApi,
  getWorkDatesInfo,
  setWorkDates,
} from "../../services/apiService";
import { NotificationManager } from "react-notifications";
import { FormSteps, generalStatesPostulation } from "../../constants/enum";
import HelpButton from "../../components/buttons/helpButton/index";

const WorkDates = ({
  setState,
  postulationState,
  universityData,
  programName,
}) => {
  const [postulationId, setPostulationId] = useState("");
  const { t } = useTranslation();
  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const validations = {};

  const onSubmit = handleSubmit((data) => {
    const uId = getUserInfo().id;
    data.start_date = data.start_date == "" ? null : data.start_date;
    data.end_date = data.end_date == "" ? null : data.end_date;
    callApi(
      () =>
        setWorkDates({
          ...data,
          id: postulationId,
          postulationState: postulationState,
        }),
      () => {
        NotificationManager.success(t("form.success"));
        setState(FormSteps.HealthInformation);
      }
    );
  });

  useEffect(() => {
    const uId = getUserInfo().id;
    callApi(
      () => getWorkDatesInfo({ uId: uId }),
      (data) => {
        loadpostulationData(data);
        setPostulationId(data.postulationId);
      }
    );
  }, []);

  const loadpostulationData = (obj) => {
    setValue(
      "start_date",
      !obj || !obj.startDate ? "" : new Date(obj.startDate)
    );
    setValue("end_date", !obj || !obj.endDate ? "" : new Date(obj.endDate));
  };

  const backForm = () => {
    setState(FormSteps.AdditionalInfo);
  };

  return (
    <>
      <FormProvider {...methods}>
        <h2 className="title">{t("form.program") + programName}</h2>
        <div className="HContainer">
          <h2 style={{ margin: "0 15px 0 0" }}>{t("work.title")}</h2>
          <HelpButton
            message={
              "Las fechas de trabajo solo pueden estar dentro del rango de vacaciones"
            }
          />
        </div>
        <p style={{ margin: "0 30px 30px 30px" }}>{t("work.subtitle")}</p>
        <form>
          <div className="container-inputs">
            <div className="inputs-style">
              <DateInput
                name="start_date"
                control={control}
                placeholder={t("work.startDate")}
                label={t("work.startDate")}
                maxDate={Date.parse(universityData.UniBreakEnd)}
                minDate={Date.parse(universityData.UniBreakBegins)}
                {...register("start_date", validations.start_date)}
              />
            </div>
            <div className="inputs-style">
              <DateInput
                name="end_date"
                control={control}
                placeholder={t("work.endDate")}
                label={t("work.endDate")}
                maxDate={Date.parse(universityData.UniBreakEnd)}
                minDate={Date.parse(universityData.UniBreakBegins)}
                {...register("end_date", validations.end_date)}
              />
            </div>
          </div>
        </form>
        <div className="container-inputs">
          <Button
            size="btn-sx"
            type="ww-btn-secondary"
            label={t("main.back")}
            onClick={() => backForm()}
          />
          <Button label={t("main.next")} size="btn-sx" onClick={onSubmit} />
        </div>
      </FormProvider>
      {postulationState >= generalStatesPostulation.uploadingFiles && (
        <Button
          type="ww-btn-secondary"
          onClick={() => {
            setState(FormSteps.FinishedForm);
          }}
          label={t("main.backPostulation")}
        />
      )}
    </>
  );
};

export default WorkDates;
