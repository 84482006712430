import React from "react";
import { useTranslation } from "react-i18next";
import VideoPlayer from "../../components/videoPlayer";
import { ProgramId } from "../../constants/enum";
import FilesTable from "../../components/filesTable";

const Presentation = ({ courseId }) => {
  const { t } = useTranslation();
  const filesWorkAndTravel = [
    {
      name: "home.presentation",
      description: "home.presentationDescription",
      url: "/download/Presentacion2024-25.pdf",
    },
    {
      name: "home.guide",
      description: "home.guideDescription",
      url: "/download/Guia paso a paso 2024.pdf",
    },
    {
      name: "home.glossary",
      description: "home.glossaryDescription",
      url: "/download/Glosario.pdf",
    },
  ];
  return (
    <>
        {courseId == ProgramId.WorkTravelUSA && (
          <>
            <VideoPlayer
              url="/videos/Work And Travel 2024 Web.mp4"
              title={t("videos.formWorkAndTravel")}
            />
             <FilesTable files={filesWorkAndTravel} />
          </>
        )}
    </>
  );
};

export default Presentation;
