import React from "react";
import { useTranslation } from "react-i18next";

const PendingAnswer = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className="title">{t("general.pendingAnswer")}</h2>
    </>
  );
};

export default PendingAnswer;
