import React from "react";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateInput = ({
  label,
  control,
  placeholder,
  name,
  disabled,
  maxDate,
  minDate,
}) => {
  return (
    <>
      <label>{label}</label>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <DatePicker
            placeholderText={placeholder}
            onChange={(date) => field.onChange(date)}
            selected={field.value}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            disabled={disabled}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
      />
    </>
  );
};

export default DateInput;
