import React from "react";
import { Routes, Route } from "react-router-dom";

//Layouts
import Header from "../layouts/header";
//Pages
import HomePage from "../pages/homePage";
import Login from "../pages/login";
import Gallery from "../pages/gallery";
import AgencyTemplates from "../pages/agencyTemplates";
import Profile from "../pages/profile";
import Register from "../pages/register";
import ConfirmationEmail from "../pages/register/confirmationEmail";
import ConfirmationSuccess from "../pages/register/confirmationSuccess";
import RecoverPassword from "../pages/recoverPassword";
import EmailSend from "../pages/recoverPassword/emailSend";
import NewPassword from "../pages/recoverPassword/newPassword";
import ChangeSuccess from "../pages/recoverPassword/changeSuccess";
import UserList from "../pages/userList";
import UserDetail from "../pages/userDetail";
import EmployeesPage from "../pages/employees";
import RegistrationForm from "../pages/registrationForm/index";
import EducationalMaterial from "../pages/educationalMaterial/index";
import Unauthorized from "../pages/errorPages/unauthorized";
import CoursesPage from "../pages/courses";
import Page404 from "../pages/errorPages/404";
import Page500 from "../pages/errorPages/500";
import HomePageWW from "../pages/homePageWW";
import Reports from "../pages/reports";

//firebase
import { getAuth, signInAnonymously } from "firebase/auth";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../firebase/firebase";
import { NotificationManager } from "react-notifications";

//Constants
import ProtectedRoute from "./protectedRoutes";
import { RoleEnum } from "../constants/enum";

const WordWideWT = () => {
  const activarMensajes = async () => {
    const token = await getToken(messaging, {
      vapidKey:
        "BAOsH1wG6jexf8r01AOt3cDgV_ZmWAlK-SuKxn56OrBuCBh95dK9bQMGfo7r6N1-l3Vzph7sG0yj7_UWvfOOmzk",
    }).catch((error) => console.log(error));
    if (token) console.log("tu token:", token);
    if (!token) console.log("no tienes token");
  };

  // React.useEffect(() => {
  //   onMessage(messaging, (message) => {
  //     NotificationManager.info(
  //       message.notification.title,
  //       message.notification.body
  //     );
  //   });
  // }, []);

  const sendNotification = async () => {
    //const FIREBASE_API_KEY = "AAAAsOtw1k4:APA91bFjML2whQV8P9BOCKZ4rfZXLIGYeVfF23fuG2coZ6akMTKp-bLkKOPWmQumfejXWlI8xOJLwOKo-it-2hFiDkqxJcv9K4anIC61M4-5-UXsfXDVMnc6niEaLmTvMUGdYjvyV5ov";
    const FIREBASE_API_KEY =
      "ya29.c.b0AXv0zTMqfa9EB_I1gS64tzCXzsFvCJi8b2i_dNfIQuk7ttmnjJJpo9gXfM1bO8M3R6tSO02rX-H0e9477YrpCRMTR8LQucrR0gTB-MluaXRaULnrOzQONbmSv9kzPDz1EIyoKmJyHCGhJ0u7FqWL1LWhXFS5SU1SaE49AC2kzV81Z7BJuDbAyuCXfmoA0ktC1KJIm30onr6TsNGJ0j5PKx9eufVsxdg";
    //const FIREBASE_API_KEY = "BF7Vx80xyW7ObTfowDorzYS5EmycfjtxqTnKefauaCX4n2Ldfjgsf1HyZJzNqlUNyOLT4358YtsKzhz0ILEYR0E";

    const message = {
      //registration_ids:["fJ3oBkQbz6fAAByltYz54A:APA91bFwOJ0UB2Bef5e5c2LXiqqUCWWJeg4Yirdj7aWCccVaB9fHsoTXye9xJJCCiKzQC2E75x20YoNb4_UKVAes96Un6N8ZeOJuHiamu754d37u1OPlYAjx3D4DnXg0ao93mQ_vzohq"],
      // notification:{
      //   title:"Example from API Migueeeeeeeeeeeeee",
      //   body:"Example body",
      //   "vibrate":1,
      //   "sound":1,

      token:
        "fJ3oBkQbz6fAAByltYz54A:APA91bFwOJ0UB2Bef5e5c2LXiqqUCWWJeg4Yirdj7aWCccVaB9fHsoTXye9xJJCCiKzQC2E75x20YoNb4_UKVAes96Un6N8ZeOJuHiamu754d37u1OPlYAjx3D4DnXg0ao93mQ_vzohq",
      notification: {
        body: "body",
        title: "title",
      },
    };

    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + FIREBASE_API_KEY,
    });

    let response = await fetch(
      "https://fcm.googleapis.com/v1/projects/notification-32f9c/messages:send",
      {
        method: "POST",
        headers,
        body: JSON.stringify({
          message: {
            token:
              "fJ3oBkQbz6fAAByltYz54A:APA91bFwOJ0UB2Bef5e5c2LXiqqUCWWJeg4Yirdj7aWCccVaB9fHsoTXye9xJJCCiKzQC2E75x20YoNb4_UKVAes96Un6N8ZeOJuHiamu754d37u1OPlYAjx3D4DnXg0ao93mQ_vzohq",
            notification: {
              title: "Background Message Title",
              body: "Background message body",
            },
            webpush: {
              fcm_options: {
                link: "https://dummypage.com",
              },
            },
          },
        }),
      }
    );
    response = await response.json();
    console.log("RESPONSE", response);
  };

  return (
    <div className="main-div">
      <Routes>
        <Route
          path="/home"
          element={
            <ProtectedRoute
              path="/home"
              role={[RoleEnum.agency, RoleEnum.traveler]}
            >
              <Header />
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gallery"
          element={
            <ProtectedRoute
              path="/gallery"
              role={[RoleEnum.agency, RoleEnum.traveler]}
            >
              <Header />
              <Gallery />
            </ProtectedRoute>
          }
        />
        <Route
          path="/templates"
          element={
            <ProtectedRoute
              path="/home"
              role={[RoleEnum.agency, RoleEnum.traveler]}
            >
              <Header />
              <AgencyTemplates />
            </ProtectedRoute>
          }
        />
        <Route
          path="/employers"
          element={
            <ProtectedRoute path="/employers" role={[RoleEnum.agency]}>
              <Header />
              <EmployeesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/courses"
          element={
            <ProtectedRoute
              path="/courses"
              role={[RoleEnum.agency, RoleEnum.traveler]}
            >
              <Header />
              <CoursesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports"
          element={
            <ProtectedRoute
              path="/reports"
              role={[RoleEnum.agency]}
            >
              <Header />
              <Reports />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<HomePageWW />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/profile"
          element={
            <ProtectedRoute
              path="/profile"
              role={[RoleEnum.agency, RoleEnum.traveler]}
            >
              <Header />
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-list"
          element={
            <ProtectedRoute path="/user-list" role={[RoleEnum.agency]}>
              <Header />
              <UserList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-list/user-detail/:idUser"
          element={
            <ProtectedRoute
              path="/user-list/user-detail/:idUser"
              role={[RoleEnum.agency]}
            >
              <Header />
              <UserDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/registration-form"
          element={
            <>
              <Header />
              <RegistrationForm />
            </>
          }
        />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/registration-form" element={<RegistrationForm />} />
        <Route path="/educational-material" element={<EducationalMaterial />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register-success" element={<ConfirmationEmail />} />
        <Route path="/recover-password" element={<RecoverPassword />} />
        <Route path="/recover-password/email-sent" element={<EmailSend />} />
        <Route path="/email-confirmation" element={<ConfirmationSuccess />} />
        <Route path="/recover-password/success" element={<ChangeSuccess />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/500" element={<Page500 />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </div>
  );
};

export default WordWideWT;
