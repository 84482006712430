import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/buttons/button";
import "../../../../styles/pages/postulationState.scss";
import {
  callApi,
  saveCvAndVideo,
} from "../../../../services/apiService";
import MainContainer from "../../../../layouts/container/mainContainer";
import Modal from "@mui/material/Modal";
import { NotificationManager } from "react-notifications";
import {
  FileTypeId,
} from "../../../../constants/enum";
import { FormProvider, useForm } from "react-hook-form";
import { AiFillSave } from "react-icons/ai";
import { BsSave } from "react-icons/bs";
import { Grid } from "@mui/material";
import i18next from "i18next";

const urlBase = process.env.REACT_APP_URL_BASE;
const urlApi = process.env.REACT_APP_API_URL;

const UploadFile = ({ viewPath, setViewPath, uId, postulationId }) => {
  const { t } = useTranslation();
  const isAndroid = /Android/i.test(navigator.userAgent);
  const [previewView, setPreviewModal] = useState(false);

  const validations = {
    file: {
      required: {
        value: true,
        message: t("image.requireImageEnglish"),
      },
    },
  };
  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmitFile = handleSubmit((data) => {
    var formData = new FormData();
    formData.append("userId", uId);
    formData.append("file_type_id", FileTypeId.Certify);
    formData.append("postulationId", postulationId);
    formData.append("image", data.cv_image[0]);

    callApi(
      () => saveCvAndVideo(formData),
      (data) => {
        setViewPath(data.cv);
        NotificationManager.success(i18next.t("form.success"));
      }
    );
  });

  const handleCloseViewModal = () => {
    setPreviewModal(false);
  };

  return (
    <>
      {/* ---------------------------------Certificado alumno -------------------------------- */}
      <FormProvider {...methods}>
        <form>
          <div>
            <hr />
            <h4>{t("form.certify")}</h4>
          </div>
          <div>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} md={4}>
                <label>{t("form.certify")}</label>
              </Grid>
              <Grid item xs={6} md={6}>
                <input
                  className="form-control"
                  type="file"
                  id="cv_image"
                  name="cv_image"
                  {...register("cv_image", validations.file)}
                />
                <span
                  style={{ margin: "10px 0 0 0" }}
                  className="text-danger text-small d-block"
                >
                  {errors.cv_image && errors.cv_image.message}
                </span>
              </Grid>
              <Grid item xs={6} md={2}>
                <div className="row-files">
                  <Button label={<AiFillSave />} onClick={onSubmitFile} />
                  {viewPath != "" && (
                    <div
                      style={{
                        height: "fit-content",
                        display: "flex",
                        alignSelf: "center",
                      }}
                      className="styled-button"
                      onClick={() => {
                        const downloadUrl =
                          urlBase +
                          "/" +
                          viewPath.replace("WorldWideFiles", "");
                        window.open(downloadUrl, "_blank");
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      <BsSave className="button-icon" />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </form>
      </FormProvider>

      <div className="btn-container" style={{ width: "70%" }}>
        {isAndroid && viewPath != "" && (
          <a
            href={urlBase + "/" + viewPath.replace("WorldWideFiles", "")}
            download
            target="_blank"
          >
            <Button size="btn-m" label={t("postulation.downloadCvEnglish")} />
          </a>
        )}
      </div>
      <div className="container-inputs row-files">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "self-end",
          }}
        ></div>
      </div>
      {/* Modal Preview */}
      <Modal
        open={previewView}
        onClose={handleCloseViewModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer showImage={false} class="ww-container-m">
          <p>{t("form.previewView")}</p>
          {viewPath != "" && (
            <>
              <embed
                src={urlBase + "/" + viewPath.replace("WorldWideFiles", "")}
                className="file-preview-view"
              />
            </>
          )}
          <div>
            <Button
              style={{ margin: "10px" }}
              size="btn-s secondary"
              label={t("main.closeEnglish")}
              onClick={handleCloseViewModal}
            />
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default UploadFile;
