import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import MainContainer from "../../layouts/container/mainContainer";
import { FormProvider, useForm } from "react-hook-form";
import "../../styles/pages/login.scss";
import Button from "../../components/buttons/button";
import Modal from "@mui/material/Modal";
import {
  callApi,
  getPosition,
  savePositionEmployer,
} from "../../services/apiService";
import { language_skills } from "../../constants/enum";
import AutocompleteSelect from "../../components/forms/inputsMui/autocomplete";

const AddEmployerPositionModal = ({
  open,
  onClose,
  currentEmployer,
  currentPosition,
}) => {
  const { t } = useTranslation();
  const [botonActivo, setBotonActivo] = useState(false);

  const validations = {
    file: {
      required: {
        value: true,
        message: t("image.requireImage"),
      },
    },
  };

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    setBotonActivo(false);
    if (!currentPosition || !open) {
      return;
    }
    loadData(currentPosition.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onSubmit = handleSubmit((data) => {
    setBotonActivo(true);
    var positionData = {
      id: currentPosition == null ? 0 : parseInt(currentPosition.id),
      employerId: currentEmployer == null ? 0 : parseInt(currentEmployer.id),
      position: data.position,
      payRate: data.payRate,
      englishLevel: data.englishLevel,
      details: data.positionDetails,
    };
    if (positionData === null) {
      setBotonActivo(false);
      return;
    }
    callApi(
      () => savePositionEmployer(positionData),
      () => {
        NotificationManager.success(t("template.uploadSucces"));
        onCloseHandleAdd();
      }
    );
    setBotonActivo(false);
  });

  const loadData = (currentPositionId) => {
    if (currentPositionId == undefined) {
      return;
    }
    callApi(
      () => getPosition({ id: parseInt(currentPositionId) }),
      (data) => {
        loadFields(data);
      }
    );
  };
  const loadFields = (data) => {
    setValue(
      "englishLevel",
      data.englishLevel != null ? data.englishLevel : ""
    );
    setValue("position", data.position);
    setValue("payRate", data.payRate);
    setValue("positionDetails", data.details);
  };

  const onCloseHandleAdd = () => {
    reset();
    onClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseHandleAdd}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-xxl" showImage={false}>
          <h1 className="title">
            {currentPosition ? "" : t("employer.positionAdd")}
          </h1>

          <FormProvider {...methods}>
            <form>
              <div className="container-inputs">
                <div className="inputs-style">
                  <label>{t("employer.position")}</label>
                  <input
                    placeholder={t("employer.position")}
                    className="form-control mb-2"
                    name="position"
                    {...register("position", validations.name)}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors.position && errors.position.message}
                  </span>
                </div>
                <div className="inputs-style">
                  <label>{t("employer.payRate")}</label>
                  <input
                    placeholder={t("employer.payRate")}
                    className="form-control mb-2"
                    name="payRate"
                    {...register("payRate", validations.payRate)}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors.payRate && errors.payRate.message}
                  </span>
                </div>
              </div>
              <div className="container-inputs">
                <div className="inputs-style">
                  <label>{t("employer.englishLevel")}</label>
                  <AutocompleteSelect
                    name="englishLevel"
                    label={t("employer.englishLevel")}
                    options={language_skills()}
                    register={register}
                    registerOptions={validations}
                    watch={watch}
                    setValue={setValue}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors.destinyId && errors.destinyId.message}
                  </span>
                </div>
                <div className="inputs-style">
                  <label>
                    {t("employer.positionDetails")}
                  </label>
                  <textarea
                    name="positionDetails"
                    placeholder={t("employer.positionDetails")}
                    className="form-control mb-2"
                    registerOptions={validations}
                    rows="2"
                    {...register(
                      "positionDetails",
                      validations.positionDetails
                    )}
                  ></textarea>
                </div>
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="ww-btn-secondary"
                  size="btn-xs secondary"
                  label={t("main.close")}
                  onClick={onCloseHandleAdd}
                />
                <Button
                  size="btn-xs"
                  disabled={botonActivo}
                  label={t("profile.SaveChanges")}
                  onClick={onSubmit}
                />
              </div>
            </form>
          </FormProvider>
        </MainContainer>
      </Modal>
    </>
  );
};

export default AddEmployerPositionModal;
