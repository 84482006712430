import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import MainContainer from "../../layouts/container/mainContainer";
import { FormProvider, useForm } from "react-hook-form";
import "../../styles/pages/login.scss";
import Button from "../../components/buttons/button";
import Modal from "@mui/material/Modal";
import { callApi, getEmployer, saveEmployer } from "../../services/apiService";
import { employerDestinyType, employerState } from "../../constants/enum";
import AutocompleteSelect from "../../components/forms/inputsMui/autocomplete";
import DateInputRange from "../../components/forms/inputs/dateInputRange";

const AddEmployerModal = ({ open, onClose, currentEmployer }) => {
  const { t } = useTranslation();
  const [botonActivo, setBotonActivo] = useState(false);
  const validations = {};
  const [startDateFirst, setStartDateFirst] = useState(null);
  const [startDateSecond, setStartDateSecond] = useState(null);
  const [endDateFirst, setEndDateFirst] = useState(null);
  const [endDateSecond, setEndDateSecond] = useState(null);

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    setValue,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    setBotonActivo(false);
    if (!currentEmployer || !open) {
      return;
    }
    loadDataTable(currentEmployer.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onSubmit = handleSubmit((data) => {
    const date1 = startDateSecond != null ? startDateSecond : startDateFirst;

    if (endDateFirst < date1) {
      NotificationManager.warning(t("employer.verifyDates"));
      return;
    }

    setBotonActivo(true);
    var employerData = {
      id: currentEmployer == null ? 0 : parseInt(currentEmployer.id),
      destiny: data.destinyId == ""?null:data.destinyId,
      state: data.state,
      companyName: data.companyName,
      website: data.website,
      city: data.city,
      statePlace: data.stateLocation,
      housing: data.housing,
      startDateFirst: startDateFirst,
      startDateSecond: startDateSecond,
      endDateFirst: endDateFirst,
      endDateSecond: endDateSecond,
      hours: data.hours,
      comments: data.comments,
    };
    if (employerData === null) {
      setBotonActivo(false);
      return;
    }
    setBotonActivo(false);

    callApi(
      () => saveEmployer(employerData),
      () => {
        NotificationManager.success(t("form.success"));
        onCloseHandleAdd();
      }
    );
    setBotonActivo(false);
  });

  const loadDataTable = (currentEmployerId) => {
    if (currentEmployerId == undefined) {
      return;
    }
    callApi(
      () => getEmployer({ id: parseInt(currentEmployerId) }),
      (data) => {
        loadFields(data);
      }
    );
  };

  const loadFields = (data) => {
    setValue(
      "destinyId",
      data.destinyId != null ? parseInt(data.destinyId) : ""
    );
    setValue("state", data.state != null ? parseInt(data.state) : "");
    setValue("companyName", data.companyName);
    setValue("website", data.website);
    setValue("city", data.city);
    setValue("stateLocation", data.statePlace);
    setValue("housing", data.housing);
    if (data.startWorkFirst != null) {
      const startWorkFirstFormated = new Date(data.startWorkFirst);
      setStartDateFirst(startWorkFirstFormated);
    } else {
      setStartDateFirst(null);
    }
    if (data.startWorkSecond != null) {
      const startWorkSecondFormated = new Date(data.startWorkSecond);
      setStartDateSecond(startWorkSecondFormated);
    } else {
      setStartDateSecond(null);
    }
    if (data.endWorkFirst != null) {
      const endWorkFirstFormated = new Date(data.endWorkFirst);
      setEndDateFirst(endWorkFirstFormated);
    } else {
      setEndDateFirst(null);
    }
    if (data.endWorkSecond != null) {
      const endWorkSecondFormated = new Date(data.endWorkSecond);
      setEndDateSecond(endWorkSecondFormated);
    } else {
      setEndDateSecond(null);
    }

    setValue("hours", data.hours);
    setValue("comments", data.comments);
  };

  const onCloseHandleAdd = () => {
    reset();
    setStartDateFirst(null);
    setStartDateSecond(null);
    setEndDateFirst(null);
    setEndDateSecond(null);
    onClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseHandleAdd}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer class="ww-container-xxl" showImage={false}>
          <h1 className="title">
            {currentEmployer ? currentEmployer.company_name : t("employer.Add")}
          </h1>
          <FormProvider {...methods}>
            <form>
              <div className="container-inputs">
                <div className="inputs-style">
                  <label>{t("employer.state")}</label>
                  <AutocompleteSelect
                    name="state"
                    label={t("employer.state")}
                    options={employerState()}
                    register={register}
                    registerOptions={validations}
                    watch={watch}
                    setValue={setValue}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors.state && errors.state.message}
                  </span>
                </div>
                <div className="inputs-style">
                  <label>{t("employer.destiny")}</label>
                  <AutocompleteSelect
                    name="destinyId"
                    label={t("employer.destiny")}
                    options={employerDestinyType()}
                    register={register}
                    registerOptions={validations}
                    watch={watch}
                    setValue={setValue}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors.destinyId && errors.destinyId.message}
                  </span>
                </div>
              </div>
              <div className="container-inputs">
                <div className="inputs-style">
                  <label>{t("employer.companyName")}</label>
                  <input
                    placeholder={t("employer.companyName")}
                    className="form-control mb-2"
                    name="companyName"
                    {...register("companyName", validations.name)}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors.companyName && errors.companyName.message}
                  </span>
                </div>
                <div className="inputs-style">
                  <label>{t("employer.page")}</label>
                  <input
                    placeholder={t("employer.page")}
                    className="form-control mb-2"
                    name="website"
                    {...register("website", validations.website)}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors.website && errors.website.message}
                  </span>
                </div>
              </div>
              <div className="container-inputs">
                <div className="inputs-style">
                  <label>{t("employer.city")}</label>
                  <input
                    placeholder={t("employer.city")}
                    className="form-control mb-2"
                    name="city"
                    {...register("city", validations.city)}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors.city && errors.city.message}
                  </span>
                </div>
                <div className="inputs-style">
                  <label>{t("employer.stateLocation")}</label>
                  <input
                    placeholder={t("employer.stateLocation")}
                    className="form-control mb-2"
                    name="stateLocation"
                    {...register("stateLocation", validations.stateLocation)}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors.stateLocation && errors.stateLocation.message}
                  </span>
                </div>
              </div>
              <div className="container-inputs">
                <div className="inputs-style">
                  <DateInputRange
                    name="startDate"
                    startDate={startDateFirst}
                    endDate={startDateSecond}
                    setStartDate={setStartDateFirst}
                    setEndDate={setStartDateSecond}
                    control={control}
                    placeholder={t("work.startDate")}
                    label={t("work.startDate")}
                  />
                </div>
                <div className="inputs-style">
                  <DateInputRange
                    name="endDate"
                    startDate={endDateFirst}
                    endDate={endDateSecond}
                    setStartDate={setEndDateFirst}
                    setEndDate={setEndDateSecond}
                    control={control}
                    placeholder={t("work.endDate")}
                    label={t("work.endDate")}
                    minDate={
                      startDateSecond != null ? startDateSecond : startDateFirst
                    }
                  />
                </div>
              </div>
              <div className="container-inputs">
                <div className="inputs-style">
                  <label>{t("employer.housing")}</label>
                  <input
                    placeholder={t("employer.housing")}
                    className="form-control mb-2"
                    name="housing"
                    {...register("housing", validations.housing)}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors.housing && errors.housing.message}
                  </span>
                  <input style={{ display: "none" }} name="id" />
                </div>
                <div className="inputs-style">
                  <label>{t("employer.hours")}</label>
                  <input
                    placeholder={t("employer.hours")}
                    className="form-control mb-2"
                    name="hours"
                    {...register("hours", validations.hours)}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors.hours && errors.hours.message}
                  </span>
                </div>
              </div>
              <div className="container-inputs">
                <div className="container-inputs">
                  <div className="inputs-style">
                    <label>{t("employer.comments")}</label>
                    <textarea
                      placeholder={t("employer.comments")}
                      className="form-control mb-2"
                      rows="3"
                      {...register("comments", validations.extra_comments)}
                    ></textarea>
                  </div>
                </div>
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="ww-btn-secondary"
                  size="btn-xs secondary"
                  label={t("main.close")}
                  onClick={onCloseHandleAdd}
                />
                <Button
                  size="btn-xs"
                  disabled={botonActivo}
                  label={t("profile.SaveChanges")}
                  onClick={onSubmit}
                />
              </div>
            </form>
          </FormProvider>
        </MainContainer>
      </Modal>
    </>
  );
};

export default AddEmployerModal;
