import React, { useEffect, useState } from "react";
import MainContainer from "../../layouts/container/mainContainer";
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import Table, {
  defaultCol,
  minimalOptions,
} from "../../components/table";
import Button from "../../components/buttons/button";
import "react-datepicker/dist/react-datepicker.css";
import { AiFillEye, AiOutlineDownload } from "react-icons/ai";
import { typeFiles } from "../../constants/enum";

const   FilesTable = ({ files }) => {
  const { t } = useTranslation();
  const isAndroid = /Android/i.test(navigator.userAgent);
  const urlBase = process.env.REACT_APP_URL_BASE;

  const getObj = (tableMeta) => {
    return files.find((x) => x.name === tableMeta.rowData[0]);
  };

  const [openPreview, setOpenPreview] = useState(false);
  const [currentUrlFile, setCurrentUrlFile] = useState("");
  const [currenNameFile, setCurrentNameFile] = useState("");

  const handleOpenPreview = () => setOpenPreview(true);
  const handleClosePreview = () => {
    setOpenPreview(false);
    setCurrentUrlFile("");
  };

  const columns = [
    {
      name: "name",
      label: t("filesTable.file"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, travelerList, updateValue) => {
          return t(value);
        },
      },
    },
    {
      name: "description",
      label: t("filesTable.description"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, travelerList, updateValue) => {
          return t(value);
        },
      },
    },
    {
      name: "Actions",
      label: "",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, files, updateValue) => {
          var rowObj = getObj(files);
          if(rowObj.type && rowObj.type == typeFiles.video){
            return (
              <div className="table-btn-group">
                <a href={currentUrlFile} download target="_blank">
                  <Button
                    label={<AiFillEye />}
                    onClick={() => {
                      setCurrentUrlFile(rowObj.url);
                    }}
                  />
                </a>
              </div>
            );
          }
          return (
            <div className="table-btn-group">
              {!isAndroid && (
                <>
                  <Button
                    label={<AiFillEye />}
                    onClick={() => {
                      setCurrentUrlFile(rowObj.url);
                      setCurrentNameFile(rowObj.name);
                      handleOpenPreview();
                    }}
                  />
                </>
              )}

              <a href={currentUrlFile} download target="_blank">
                <Button
                  label={<AiOutlineDownload />}
                  onClick={() => {
                    setCurrentUrlFile(rowObj.url);
                  }}
                />
              </a>
            </div>
          );
        },
      },
    },
  ];

  return (
    <>
      <h4 style={{ margin: "20px 0 20px 0" }}>{t("filesTable.title")}</h4>
      <div>
        <Table data={files} columns={columns} options={minimalOptions()} />
      </div>
      <Modal
        open={openPreview}
        onClose={handleClosePreview}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer class="ww-container-m" Nologo={true}>
          <p>{t(currenNameFile)}</p>
          <embed src={currentUrlFile} className="file-preview-view" />
          <div>
            <Button
              style={{ margin: "10px" }}
              size="btn-s secondary"
              label={t("main.close")}
              onClick={() => handleClosePreview()}
            />
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default FilesTable;
