import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDgkCOSRJH2ToJ9-v7nDGBXK1bFMrx4DIM",
  authDomain: "worldwide-5104d.firebaseapp.com",
  projectId: "worldwide-5104d",
  storageBucket: "worldwide-5104d.appspot.com",
  messagingSenderId: "765894171107",
  appId: "1:765894171107:web:e1c84e523731f3520da8d6",
  measurementId: "G-G6MSZYBXPS"
};

export const config = {
  vapidKey:
    "BAOsH1wG6jexf8r01AOt3cDgV_ZmWAlK-SuKxn56OrBuCBh95dK9bQMGfo7r6N1-l3Vzph7sG0yj7_UWvfOOmzk",
  tokenAccess:
    "ya29.c.b0AXv0zTMqfa9EB_I1gS64tzCXzsFvCJi8b2i_dNfIQuk7ttmnjJJpo9gXfM1bO8M3R6tSO02rX-H0e9477YrpCRMTR8LQucrR0gTB-MluaXRaULnrOzQONbmSv9kzPDz1EIyoKmJyHCGhJ0u7FqWL1LWhXFS5SU1SaE49AC2kzV81Z7BJuDbAyuCXfmoA0ktC1KJIm30onr6TsNGJ0j5PKx9eufVsxdg",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const messaging = getMessaging(app);
