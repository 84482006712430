import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { BiHelpCircle } from "react-icons/bi";

const HelpButton = ({ message }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(!open);
    setTimeout(() => {
      setOpen(false);
      console.log("Tooltip cerrada después de 5 segundos");
    }, 5000);
  };

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      onClose={handleTooltipClose}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={message}
    >
      <div className="a-btn" onClick={handleTooltipOpen}>
        <BiHelpCircle />
      </div>
    </Tooltip>
  );
};

export default HelpButton;