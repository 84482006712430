import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { newUser, callApi, getCountries } from "../../services/apiService";
import { useTranslation } from "react-i18next";
import MainContainer from "../../layouts/container/mainContainer";
import Footer from "../../layouts/footer";
import "../../styles/pages/register.scss";
import Button from "../../components/buttons/button";
import AutocompleteSelect from "../../components/forms/inputsMui/autocomplete";

const Register = () => {
  const { t } = useTranslation();
  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const [countryList, setCountryList] = useState([]);

  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  let navigate = useNavigate();
  const [botonActivo, setBotonActivo] = useState(false);

  const onSubmit = handleSubmit((data) => {
    setBotonActivo(true);
    callApi(
      () => newUser(data),
      (x) => {
        if (x == true) {
          NotificationManager.warning(
            t("register.existing1") +
              " " +
              data.email +
              " " +
              t("register.existing2")
          );
          setBotonActivo(false);
          return;
        }
        NotificationManager.success(t("register.success"));
        navigate("/register-success");
      }
    );
  });

  useEffect(() => {
    callApi(getCountries, (data) => {
      data.sort((a, b) => a.name.localeCompare(b.name));
      setCountryList(data);
    });
  }, []);

  const validations = {
    name: {
      required: { value: true, message: t("validationError.name.require") },
    },
    last_name: {
      required: { value: true, message: t("validationError.lastName.require") },
    },
    email: {
      required: { value: true, message: t("validationError.email.require") },
      pattern: {
        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
        message: t("validationError.email.valid"),
      },
    },
    password: {
      required: { value: true, message: t("validationError.password.require") },
      minLength: { value: 5, message: t("validationError.password.short") },
      pattern: {
        value: /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/,
        message: t("validationError.password.items"),
      },
    },
    phone: {
      required: { value: true, message: t("validationError.phone.require") },
      pattern: {
        value: /[0-9]+/,
        message: t("validationError.number"),
      },
    },
    countryNationality: {
      required: {
        value: true,
        message: t("form.validation.countryNationality"),
      },
    },
    countryPermanence: {
      required: {
        value: true,
        message: t("form.validation.countryPermanence"),
      },
    },
  };

  return (
    <>
      <MainContainer>
        <div>
          <h1 className="title">{t("register.title")}</h1>
        </div>
        <FormProvider {...methods}>
          <form>
            <div className="container-inputs">
              <div className="inputs-style">
                <label>{t("register.name")}</label>
                <input
                  placeholder={t("placeholder.name")}
                  className="form-control mb-2"
                  name="name"
                  {...register("name", validations.name)}
                />
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.name && errors.name.message}
                </span>
              </div>
              <div className="inputs-style">
                <label>{t("register.lastName")}</label>
                <input
                  placeholder={t("placeholder.lastname")}
                  className="form-control mb-2"
                  name="last_name"
                  {...register("last_name", validations.last_name)}
                />
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.last_name && errors.last_name.message}
                </span>
              </div>
            </div>
            <div className="container-inputs">
              <div className="inputs-style">
                <label>{t("main.email")}</label>
                <input
                  placeholder={t("placeholder.register.email")}
                  className="form-control mb-2"
                  name="email"
                  {...register("email", validations.email)}
                />
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.email && errors.email.message}
                </span>
              </div>
              <div className="inputs-style">
                <label>{t("register.phone")}</label>
                <input
                  placeholder={t("placeholder.phone")}
                  className="form-control mb-2"
                  name="phone"
                  {...register("phone", validations.phone)}
                />
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.phone && errors.phone.message}
                </span>
              </div>
            </div>
            <div className="container-inputs">
              <div className="inputs-style">
                <label>{t("main.password")}</label>
                <input
                  placeholder={t("placeholder.password")}
                  className="form-control mb-2"
                  name="password"
                  type="password"
                  {...register("password", validations.password)}
                />
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.password && errors.password.message}
                </span>
              </div>
              <div className="inputs-style">
                <label>{t("main.confirmPassword")}</label>
                <input
                  placeholder={t("placeholder.passwordConfirm")}
                  className="form-control mb-2"
                  name="password_confirmation"
                  type="password"
                  {...register("password_confirmation", {
                    required: t("validationError.password.confirm.require"),
                    validate: (value) =>
                      value === watch("password") ||
                      t("validationError.password.confirm.error"),
                  })}
                />
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.password_confirmation &&
                    errors.password_confirmation.message}
                </span>
              </div>
            </div>
            <div className="container-inputs">
              <div className="inputs-style">
                <label>{t("form.nationality")}</label>
                <AutocompleteSelect
                  name="countryNationality"
                  label={t("form.nationality")}
                  options={countryList}
                  register={register}
                  registerOptions={validations}
                  watch={watch}
                  setValue={setValue}
                />
                {watch("countryNationality") == "" && (
                  <span className="text-danger text-small d-block mb-2 validation-margin">
                    {errors.countryNationality &&
                      errors.countryNationality.message}
                  </span>
                )}
              </div>
              <div className="inputs-style">
                <label>{t("form.countryPermanence")}</label>
                <AutocompleteSelect
                  name="countryPermanence"
                  label={t("form.countryPermanence")}
                  options={countryList}
                  register={register}
                  registerOptions={validations}
                  watch={watch}
                  setValue={setValue}
                />
                {watch("countryPermanence") == "" && (
                  <span className="text-danger text-small d-block mb-2 validation-margin">
                    {errors.countryPermanence &&
                      errors.countryPermanence.message}
                  </span>
                )}
              </div>
            </div>

            <br />
            <Button
              size="btn-s"
              disabled={botonActivo}
              label={t("main.register")}
              onClick={onSubmit}
            />
            <br />
            <div className="forgot-password">
              <div>
                <span>{t("register.haveAccount")}</span>
                <Link to="/">{t("register.LoginHere")}</Link>
              </div>
              <div>
                <Link to="/recover-password">{t("login.forgotPassword")}</Link>
              </div>
            </div>
          </form>
        </FormProvider>
      </MainContainer>
      <Footer />
    </>
  );
};

export default Register;
