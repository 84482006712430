import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import {
  FormSteps,
  generalStatesPostulation,
  yesNo,
} from "../../constants/enum";
import { getUserInfo } from "../../services/authService";
import AutocompleteSelect from "../../components/forms/inputsMui/autocomplete";
import Button from "../../components/buttons/button";
import {
  callApi,
  postHealthData,
  getHealthData,
} from "../../services/apiService";
import { NotificationManager } from "react-notifications";

const HealthInformation = ({ setState, postulationState, programName }) => {
  const [id, setId] = useState("");
  const [healthDataId, setHealthDataId] = useState(0);

  const { t } = useTranslation();

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const validations = {
    medicalCondition: {
      required: { value: true, message: t("form.visa.error.standar") },
    },
    medicalConditionExplain: {
      required: { value: true, message: t("form.visa.error.standarExplain") },
      maxLength: { value: 80, message: t("lenghValidation.max70") },
    },
    medicineCurrent: {
      required: { value: true, message: t("form.visa.error.standar") },
    },
    medicineCurrentExplain: {
      required: { value: true, message: t("form.visa.error.standarExplain") },
      maxLength: { value: 80, message: t("lenghValidation.max80") },
    },
    allergies: {
      required: { value: true, message: t("form.visa.error.standar") },
    },
    allergiesExplain: {
      required: { value: true, message: t("form.visa.error.standarExplain") },
      maxLength: { value: 80, message: t("lenghValidation.max80") },
    },
    smoke: {
      required: { value: true, message: t("form.visa.error.standar") },
    },
    tattos: {
      required: { value: true, message: t("form.visa.error.standar") },
    },
    piercing: {
      required: { value: true, message: t("form.visa.error.standar") },
    },
  };

  useEffect(() => {
    const uId = getUserInfo().id;
    callApi(
      () => getHealthData({ uId: uId }),
      (data) => {
        loadHealthData(data);
        setId(data.id);
      }
    );
  }, []);

  const loadHealthData = (obj) => {
    setHealthDataId(obj.id);
    setValue(
      "medicalCondition",
      obj.medical_condition === true
        ? 1
        : obj.medical_condition === false
        ? 2
        : ""
    );
    setValue(
      "medicalConditionExplain",
      !obj || !obj.medical_condition_explain
        ? ""
        : obj.medical_condition_explain
    );

    setValue(
      "medicineCurrent",
      obj.taking_medicine === true ? 1 : obj.taking_medicine === false ? 2 : ""
    );
    setValue(
      "medicineCurrentExplain",
      !obj || !obj.taking_medicine_explain ? "" : obj.taking_medicine_explain
    );

    setValue(
      "allergies",
      obj.allergies === true ? 1 : obj.allergies === false ? 2 : ""
    );
    setValue(
      "allergiesExplain",
      !obj || !obj.allergies_explain ? "" : obj.allergies_explain
    );

    setValue("smoke", obj.smoke === true ? 1 : obj.smoke === false ? 2 : "");
    setValue(
      "tattos",
      obj.tattoos === true ? 1 : obj.tattoos === false ? 2 : ""
    );
    setValue(
      "piercing",
      obj.piercings === true ? 1 : obj.piercings === false ? 2 : ""
    );
  };

  const onSubmit = handleSubmit((data) => {
    const uId = getUserInfo().id;
    const id = loadHealthData.id;
    const submitData = {
      uId: uId,
      healthDataId: healthDataId,
      medicalCondition: data.medicalCondition === 1 ? true : false,
      medicalConditionExplain: data.medicalConditionExplain,
      medicineCurrent: data.medicineCurrent === 1 ? true : false,
      medicineCurrentExplain: data.medicineCurrentExplain,
      allergies: data.allergies === 1 ? true : false,
      allergiesExplain: data.allergiesExplain,
      smoke: data.smoke === 1 ? true : false,
      tattos: data.tattos === 1 ? true : false,
      piercing: data.piercing === 1 ? true : false,
      postulationState: postulationState,
    };
    callApi(
      () => postHealthData(submitData),
      (response) => {
        if (healthDataId == 0) {
          if (response && response.length > 0) {
            setHealthDataId(response[0].id);
          }
        }
        NotificationManager.success(t("form.success"));
        setState(FormSteps.OfficeUse);
      }
    );
  });
  const backForm = () => {
    setState(FormSteps.WorkDates);
    return;
  };

  return (
    <>
      <FormProvider {...methods}>
        <h2 className="title">{t("form.program") + programName}</h2>
        <h2>{t("healthInfo.title")}</h2>
        <form>
          <div className="container-inputs">
            <div className="inputs-style">
              <label>
                {t("healthInfo.medicalCondition")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="medicalCondition"
                label={t("healthInfo.medicalCondition")}
                options={yesNo()}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("medicalCondition") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.medicalCondition && errors.medicalCondition.message}
                </span>
              )}
            </div>
            {watch("medicalCondition") === 1 ? (
              <div className="inputs-style">
                <label>
                  {t("main.explain")}
                  <span className="text-danger"> *</span>
                </label>
                <textarea
                  name="medicalConditionExplain"
                  placeholder={t("main.explain")}
                  className="form-control mb-2"
                  registerOptions={validations}
                  rows="2"
                  {...register(
                    "medicalConditionExplain",
                    validations.medicalConditionExplain
                  )}
                ></textarea>
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.medicalConditionExplain &&
                    errors.medicalConditionExplain.message}
                </span>
              </div>
            ) : (
              <div className="inputs-style">
                <label>{t("main.explain")}</label>
                <textarea
                  disabled
                  name="medicalConditionExplain"
                  placeholder={t("main.explain")}
                  className="form-control mb-2"
                  rows="2"
                ></textarea>
              </div>
            )}
          </div>
          <div className="container-inputs">
            <div className="inputs-style">
              <label>
                {t("healthInfo.medicineCurrent")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="medicineCurrent"
                label={t("healthInfo.medicineCurrent")}
                options={yesNo()}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("medicineCurrent") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.medicineCurrent && errors.medicineCurrent.message}
                </span>
              )}
            </div>
            {watch("medicineCurrent") === 1 ? (
              <div className="inputs-style">
                <label>
                  {t("main.explain")}
                  <span className="text-danger"> *</span>
                </label>
                <textarea
                  name="medicineCurrentExplain"
                  placeholder={t("main.explain")}
                  className="form-control mb-2"
                  registerOptions={validations}
                  rows="2"
                  {...register(
                    "medicineCurrentExplain",
                    validations.medicineCurrentExplain
                  )}
                ></textarea>
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.medicineCurrentExplain &&
                    errors.medicineCurrentExplain.message}
                </span>
              </div>
            ) : (
              <div className="inputs-style">
                <label>{t("main.explain")}</label>
                <textarea
                  disabled
                  name="medicineCurrentExplain"
                  placeholder={t("main.explain")}
                  className="form-control mb-2"
                  rows="2"
                ></textarea>
              </div>
            )}
          </div>
          <div className="container-inputs">
            <div className="inputs-style">
              <label>
                {t("healthInfo.allergies")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="allergies"
                label={t("healthInfo.allergies")}
                options={yesNo()}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("allergies") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.allergies && errors.allergies.message}
                </span>
              )}
            </div>
            {watch("allergies") === 1 ? (
              <div className="inputs-style">
                <label>
                  {t("main.explain")}
                  <span className="text-danger"> *</span>
                </label>
                <textarea
                  name="allergiesExplain"
                  placeholder={t("main.explain")}
                  registerOptions={validations}
                  className="form-control mb-2"
                  rows="2"
                  {...register(
                    "allergiesExplain",
                    validations.allergiesExplain
                  )}
                ></textarea>
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.allergiesExplain && errors.allergiesExplain.message}
                </span>
              </div>
            ) : (
              <div className="inputs-style">
                <label>{t("main.explain")}</label>
                <textarea
                  disabled
                  name="allergiesExplain"
                  placeholder={t("main.explain")}
                  className="form-control mb-2"
                  rows="2"
                ></textarea>
              </div>
            )}
          </div>

          <div className="container-inputs">
            <div className="inputs-style">
              <label>
                {t("healthInfo.smoke")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="smoke"
                label={t("healthInfo.smoke")}
                options={yesNo()}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("smoke") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.smoke && errors.smoke.message}
                </span>
              )}
            </div>
            <div className="inputs-style">
              <label>
                {t("healthInfo.tattos")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="tattos"
                label={t("healthInfo.tattos")}
                options={yesNo()}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("tattos") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.tattos && errors.tattos.message}
                </span>
              )}
            </div>
          </div>
          <div className="container-inputs">
            <div className="inputs-style">
              <label>
                {t("healthInfo.piercing")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="piercing"
                label={t("healthInfo.piercing")}
                options={yesNo()}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("piercing") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.piercing && errors.piercing.message}
                </span>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
      <div className="container-inputs">
        <Button
          size="btn-sx"
          type="ww-btn-secondary"
          label={t("main.back")}
          onClick={() => backForm()}
        />
        <Button label={t("main.next")} size="btn-sx" onClick={onSubmit} />
      </div>
      {postulationState >= generalStatesPostulation.uploadingFiles && (
        <Button
          type="ww-btn-secondary"
          onClick={() => {
            setState(FormSteps.FinishedForm);
          }}
          label={t("main.backPostulation")}
        />
      )}
    </>
  );
};

export default HealthInformation;
