import React from "react";
import "../../styles/layouts/container.scss"

const MainContainer = (props) => {
  const classContainer = !props.class ? "ww-container-xl" : props.class
  const showImage = props.showImage == null || props.showImage ?true:false;

  const image = (show) => {
    if(show){
      return <img src="/images/WorldWide-Logo.svg" alt="logo"  style={{display: props.Nologo ? "none" : ""} } />;
    }
    return;
  };
  
  return (
    <div className="main-container">
      <div className={classContainer}>
        {image(showImage)}
        {props.children}
      </div>
    </div>
  );
};

export default MainContainer;
