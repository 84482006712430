import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { getUserInfo } from "../../services/authService";
import {
  callApi,
  getCountries,
  saveTravelerContact,
  loadContactInfo,
} from "../../services/apiService";
import Button from "../../components/buttons/button";
import AutocompleteSelect from "../../components/forms/inputsMui/autocomplete";
import { NotificationManager } from "react-notifications";
import { FormSteps, generalStatesPostulation } from "../../constants/enum";
import HelpButton from "../../components/buttons/helpButton/index";

const ContactDetails = ({ setState, postulationState , programName}) => {
  const [countryList, setCountryList] = useState([]);
  const [contactInfo, setContactInfo] = useState({});

  const { t } = useTranslation();

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const loadContactData = (obj) => {
    setValue("address", !obj || !obj.address ? "" : obj.address);
    setValue("city", !obj || !obj.city ? "" : obj.city);
    setValue("country", !obj || !obj.country_id ? "" : obj.country_id);
    setValue("providence", !obj || !obj.providence ? "" : obj.providence);
    setValue("homePhone", !obj || !obj.phone ? "" : obj.phone);
    setValue("postalCode", !obj || !obj.postal_code ? "" : obj.postal_code);
    setValue("email", !obj || !obj.email ? "" : obj.email);
    setValue("skypeID", !obj || !obj.skipe_id ? "" : obj.skipe_id);
    setValue("Facebook", !obj || !obj.facebook ? "" : obj.facebook);
    setValue(
      "emergencyNameContact",
      !obj || !obj.emergency_name ? "" : obj.emergency_name
    );
    setValue(
      "emergencyEmail",
      !obj || !obj.emergency_email ? "" : obj.emergency_email
    );
    setValue(
      "emergencyPhone",
      !obj || !obj.emergency_phone ? "" : obj.emergency_phone
    );
    setValue(
      "Relationship",
      !obj || !obj.emergency_relationship ? "" : obj.emergency_relationship
    );
    setValue("Instagram", !obj || !obj.instagram ? "" : obj.instagram);
    setValue("movilePhone", !obj || !obj.movile_phone ? "" : obj.movile_phone);
  };

  useEffect(() => {
    const uId = getUserInfo().id;
    callApi(
      () => loadContactInfo({ uId: uId }),
      (data) => {
        setContactInfo(data);
        loadContactData(data);
        return;
      }
    );
    callApi(getCountries, (data) => {
      data.sort((a, b) => a.name.localeCompare(b.name));
      setCountryList(data);
      return;
    });
  }, []);

  const validations = {
    address: {
      required: {
        value: true,
        message: t("validationError.direction.require"),
      },
      maxLength: { value: 140, message: t("lenghValidation.max140") },
    },
    city: {
      required: { value: true, message: t("validationError.city.require") },
    },
    providence: {
      required: {
        value: true,
        message: t("validationError.providence.require"),
      },
    },
    postalCode: {
      required: {
        value: true,
        message: t("validationError.postalcode.require"),
      },
      pattern: {
        value: /[0-9]+/,
        message: t("validationError.number"),
      },
    },
    homePhone: {
      required: { value: true, message: t("validationError.phone.require") },
      pattern: {
        value: /[0-9]+/,
        message: t("validationError.number"),
      },
    },
    movilePhone: {
      required: {
        value: true,
        message: t("validationError.movilePhone.require"),
      },
      pattern: {
        value: /[0-9]+/,
        message: t("validationError.number"),
      },
    },
    country: {
      required: { value: true, message: t("validationError.country.require") },
    },
    email: {
      required: { value: true, message: t("validationError.email.require") },
      pattern: {
        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
        message: t("validationError.email.valid"),
      },
    },
    emergencyNameContact: {
      required: {
        value: true,
        message: t("validationError.emergencyNameContact.require"),
      },
    },
    Relationship: {
      required: {
        value: true,
        message: t("validationError.Relationship.require"),
      },
    },
    emergencyEmail: {
      required: { value: true, message: t("validationError.email.require") },
      pattern: {
        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
        message: t("validationError.email.valid"),
      },
    },
    emergencyPhone: {
      required: { value: true, message: t("validationError.phone.require") },
      pattern: {
        value: /[0-9]+/,
        message: t("validationError.number"),
      },
    },
  };
  const onSubmit = handleSubmit((data) => {
    const uId = getUserInfo().id;
    const infoId = contactInfo.id;
    callApi(
      () =>
        saveTravelerContact({
          ...data,
          uId,
          infoId,
          postulationState: postulationState,
        }),
      () => {
        NotificationManager.success(t("form.success"));
        setState(FormSteps.UniversityInformation);
      }
    );
  });

  const backForm = () => {
    setState(FormSteps.PassportDetails);
  };

  const [setShowBreakBeginsMessage] = useState(false);
  const handleBreakBeginsHelpClick = () => {
    setShowBreakBeginsMessage(true);
  };

  return (
    <>
      <FormProvider {...methods}>
        <h2 className="title">{t("form.program") + programName}</h2>
        <h2>{t("contact.title")}</h2>
        <form>
          <div className="container-inputs">
            <div className="inputs-style">
              <label>
                {t("register.direction")}
                <span className="text-danger"> *</span>
              </label>
              <textarea
                placeholder={t("placeholder.direction")}
                className="form-control mb-2"
                rows="1"
                {...register("address", validations.address)}
              ></textarea>
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.address && errors.address.message}
              </span>
            </div>
            <div className="inputs-style">
              <label>
                {t("main.city")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("placeholder.city")}
                className="form-control mb-2"
                name="city"
                {...register("city", validations.city)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.city && errors.city.message}
              </span>
            </div>
          </div>
          <div className="container-inputs">
            <div className="inputs-style">
              <label>
                <div className="labelWithButtonDetailContact">
                  <label className="labelButton">
                    {t("main.providence")}
                    <span className="text-danger"> *</span>
                  </label>
                  <HelpButton
                    message={
                      "En este espacio debe colocarse la provincia, el estado, departamento, región"
                    }
                    onClick={handleBreakBeginsHelpClick}
                  />
                </div>
              </label>
              <input
                placeholder={t("placeholder.providence")}
                className="form-control mb-2"
                name="providence"
                {...register("providence", validations.providence)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.providence && errors.providence.message}
              </span>
            </div>
            <div className="inputs-style">
              <label>
                {t("main.country")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="country"
                label={t("main.addCountry")}
                options={countryList}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("country") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.country && errors.country.message}
                </span>
              )}
            </div>
          </div>
          <div className="container-inputs">
            <div className="inputs-style">
              <label>
                {t("main.postalCode")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("placeholder.postalCode")}
                className="form-control mb-2"
                name="postalCode"
                {...register("postalCode", validations.postalCode)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.postalCode && errors.postalCode.message}
              </span>
            </div>
            <div className="inputs-style">
              <label>
                {t("registration.homePhone")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("placeholder.homePhone")}
                className="form-control mb-2"
                name="homePhone"
                {...register("homePhone", validations.homePhone)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.homePhone && errors.homePhone.message}
              </span>
            </div>
          </div>
          <div className="container-inputs">
            <div className="inputs-style">
              <label>
                {t("registration.movilePhone")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("placeholder.movilePhone")}
                className="form-control mb-2"
                name="movilePhone"
                {...register("movilePhone", validations.movilePhone)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.movilePhone && errors.movilePhone.message}
              </span>
            </div>
            <div className="inputs-style">
              <label>
                {t("main.email")}
                <span className="text-danger"> *</span>
              </label>
              <input
                disabled
                placeholder={t("placeholder.email")}
                className="form-control mb-2"
                name="email"
                {...register("email", validations.email)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.email && errors.email.message}
              </span>
            </div>
          </div>
          <div className="container-inputs">
            <div className="inputs-style">
              <label>{t("registration.skypeID")}</label>
              <input
                placeholder={t("placeholder.skypeID")}
                className="form-control mb-2"
                name="skypeID"
                {...register("skypeID", validations.skypeID)}
              />
            </div>
            <div className="inputs-style">
              <label>{t("registration.Facebook")}</label>
              <input
                placeholder={t("placeholder.Facebook")}
                className="form-control mb-2"
                name="Facebook"
                {...register("Facebook", validations.Facebook)}
              />
            </div>
          </div>
          <div className="container-inputs">
            <div className="inputs-style">
              <label>{t("registration.Instagram")}</label>
              <input
                placeholder={t("placeholder.Instagram")}
                className="form-control mb-2"
                name="Instagram"
                {...register("Instagram", validations.Instagram)}
              />
            </div>
            <div className="inputs-style">
              <label>
                {t("registration.emergencyNameContact")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("placeholder.emergencyNameContact")}
                className="form-control mb-2"
                name="emergencyNameContact"
                {...register(
                  "emergencyNameContact",
                  validations.emergencyNameContact
                )}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.emergencyNameContact &&
                  errors.emergencyNameContact.message}
              </span>
            </div>
          </div>
          <div className="container-inputs">
            <div className="inputs-style">
              <label>
                {t("registration.Relationship")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("placeholder.Relationship")}
                className="form-control mb-2"
                name="Relationship"
                {...register("Relationship", validations.Relationship)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.Relationship && errors.Relationship.message}
              </span>
            </div>
            <div className="inputs-style">
              <label>
                {t("main.email")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("placeholder.emergencyEmail")}
                className="form-control mb-2"
                name="emergencyEmail"
                {...register("emergencyEmail", validations.emergencyEmail)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.emergencyEmail && errors.emergencyEmail.message}
              </span>
            </div>
          </div>
          <div className="container-inputs">
            <div className="inputs-style">
              <label>
                {t("register.phone")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("placeholder.emergencyPhone")}
                className="form-control mb-2"
                name="emergencyPhone"
                {...register("emergencyPhone", validations.emergencyPhone)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.emergencyPhone && errors.emergencyPhone.message}
              </span>
            </div>
          </div>
        </form>
      </FormProvider>
      <div className="container-inputs">
        <Button
          size="btn-sx"
          type="ww-btn-secondary"
          label={t("main.back")}
          onClick={() => backForm()}
        />
        <Button label={t("main.next")} size="btn-sx" onClick={onSubmit} />
      </div>
      {postulationState >= generalStatesPostulation.uploadingFiles && (
        <Button
          type="ww-btn-secondary"
          onClick={() => {
            setState(FormSteps.FinishedForm);
          }}
          label={t("main.backPostulation")}
        />
      )}
    </>
  );
};

export default ContactDetails;
