import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MainContainer from "../../layouts/container/mainContainer";
import {
  callApi,
  getAdditionalInfo,
  saveAdditionalInfo,
} from "../../services/apiService";
import { getUserInfo } from "../../services/authService";
import { NotificationManager } from "react-notifications";
import Button from "../../components/buttons/button";
import { FormProvider, useForm } from "react-hook-form";
import { generalStatesPostulation, yesNo } from "../../constants/enum";
import AutocompleteSelect from "../../components/forms/inputsMui/autocomplete";
import { FormSteps } from "../../constants/enum";

const AdditionalInfo = ({ setState, postulationState, programName }) => {
  const { t } = useTranslation();
  const [idAdditionalInfo, setIdAdditionalInfo] = useState({});

  const loadDataFormAdditionalInfo = (obj) => {
    if (obj.driving) {
      obj.driving = 1;
    } else if (obj.driving === false) {
      obj.driving = 2;
    }
    if (obj.swim) {
      obj.swim = 1;
    } else if (obj.swim === false) {
      obj.swim = 2;
    }
    if (obj.ski) {
      obj.ski = 1;
    } else if (obj.ski === false) {
      obj.ski = 2;
    }
    if (obj.cpr) {
      obj.cpr = 1;
    } else if (obj.cpr === false) {
      obj.cpr = 2;
    }
    if (obj.lifeGuard) {
      obj.lifeGuard = 1;
    } else if (obj.lifeGuard === false) {
      obj.lifeGuard = 2;
    }
    setValue("driving", !obj || !obj.driving ? "" : obj.driving);

    setValue("swim", !obj || !obj.swim ? "" : obj.swim);

    setValue("ski", !obj || !obj.ski ? "" : obj.ski);

    setValue("cpr", !obj || !obj.cpr ? "" : obj.cpr);

    setValue("lifeGuard", !obj || !obj.lifeGuard ? "" : obj.lifeGuard);
  };

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const validations = {
    driving: {
      required: { value: true, message: t("form.visa.error") },
    },
    swim: {
      required: { value: true, message: t("form.visa.error") },
    },
    ski: {
      required: { value: true, message: t("form.visa.error") },
    },
    lifeGuard: {
      required: { value: true, message: t("form.visa.error") },
    },
    cpr: {
      required: { value: true, message: t("form.visa.error") },
    },
  };

  useEffect(() => {
    const uId = getUserInfo().id;
    callApi(
      () => getAdditionalInfo({ uId: uId }),
      (data) => {
        setIdAdditionalInfo(data.idTraveler);
        loadDataFormAdditionalInfo(data);
      }
    );
  }, []);

  const onSubmitAdditionalInfo = handleSubmit((data) => {
    const uId = getUserInfo().id;
    const submitData = {
      uId: uId,
      driving: data.driving === 1 ? true : false,
      swim: data.swim === 1 ? true : false,
      ski: data.ski === 1 ? true : false,
      lifeGuard: data.lifeGuard === 1 ? true : false,
      cpr: data.cpr === 1 ? true : false,
      idAdditionalInfo: idAdditionalInfo,
      postulationState: postulationState,
    };

    callApi(
      () => saveAdditionalInfo(submitData),
      () => {
        NotificationManager.success(t("form.success"));
        setState(FormSteps.WorkDates);
      }
    );
  });
  const backForm = () => {
    setState(FormSteps.LanguageSkills);
  };

  return (
    <>
    <h2 className="title">{t("form.program") + programName}</h2>
      <h2>{t("form.aditionalInfo.title")}</h2>
      <FormProvider {...methods}>
        <form>
          <div className="container-inputs">
            {/*  driving */}
            <div className="inputs-style">
              <label>
                {t("form.aditionalInfo.driving")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="driving"
                label={t("form.aditionalInfo.driving")}
                options={yesNo()}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("driving") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.driving && errors.driving.message}
                </span>
              )}
            </div>
            {/* swim */}
            <div className="inputs-style">
              <label>
                {t("form.aditionalInfo.swim")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="swim"
                label={t("form.aditionalInfo.swim")}
                options={yesNo()}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("swim") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.swim && errors.swim.message}
                </span>
              )}
            </div>
          </div>
          <div className="container-inputs">
            {/*  ski */}
            <div className="inputs-style">
              <label>
                {t("form.aditionalInfo.ski")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="ski"
                label={t("form.aditionalInfo.ski")}
                options={yesNo()}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("ski") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.ski && errors.ski.message}
                </span>
              )}
            </div>
            {/* lifeGuard */}
            <div className="inputs-style">
              <label>
                {t("form.aditionalInfo.lifeGuard")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="lifeGuard"
                label={t("form.aditionalInfo.lifeGuard")}
                options={yesNo()}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("lifeGuard") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.lifeGuard && errors.lifeGuard.message}
                </span>
              )}
            </div>
          </div>
          <div className="container-inputs">
            {/*  cpr */}
            <div className="inputs-style">
              <label>
                {t("form.aditionalInfo.cpr")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="cpr"
                label={t("form.aditionalInfo.cpr")}
                options={yesNo()}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("cpr") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.cpr && errors.cpr.message}
                </span>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
      <div className="container-inputs">
        <Button
          size="btn-sx"
          type="ww-btn-secondary"
          label={t("main.back")}
          onClick={() => backForm()}
        />
        <Button
          size="btn-sx"
          onClick={onSubmitAdditionalInfo}
          label={t("main.next")}
        />
      </div>
      {postulationState >= generalStatesPostulation.uploadingFiles && (
        <Button
          type="ww-btn-secondary"
          onClick={() => {
            setState(FormSteps.FinishedForm);
          }}
          label={t("main.backPostulation")}
        />
      )}
    </>
  );
};

export default AdditionalInfo;
