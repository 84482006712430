import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/buttons/button";
import MainContainer from "../../layouts/container/mainContainer";
import { useTranslation } from "react-i18next";

const Unauthorized = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <MainContainer class="ww-container-m">
      <h1 className="errorTitle">:(</h1>
      <h2 className="title">¡UPS!</h2>
      <h2 className="title">{t("error.unauthorized")}</h2>
      <br />
      <p style={{ textAlign: "center" }}>{t("error.unauthorizedMessage")}</p>
      <Button
        label="VOLVER"
        onClick={() => {
          navigate(-1);
        }}
      />
    </MainContainer>
  );
};

export default Unauthorized;
