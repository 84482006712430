import React, { useState, useEffect } from "react";
import { getUserInfo, saveProgramId } from "../../services/authService";
import { callApi, postulationInfo } from "../../services/apiService";
import { useTranslation } from "react-i18next";
import Footer from "../../layouts/footer";
import MainContainer from "../../layouts/container/mainContainer";
import PassportDetails from "./passportDetails";
import ContactDetails from "./contactDetails";
import UniversityInformation from "./universityInformation";
import VisaInformation from "./visaInformation";
import LanguageSkills from "./languageSkills";
import HealthInformation from "./healthInformation";
import CourseSelect from "./courseSelect";
import AdditionalInfo from "./additionalInfo";
import BillingInformation from "./billingInformation";
import WorkDates from "./workDates";
import OfficeUse from "./officeUse";
import UploadFiles from "./uploadFiles";
import SignStep from "./signStep";
import DownloadStep from "./dowloadStep.js";
import Declined from "./declined.js";
import {
  FormSteps,
  generalStatesPostulation,
  formFilesStateEnum,
} from "../../constants/enum";
import { useDispatch, useSelector } from "react-redux";
import { savePostulationState } from "../../services/authService";
import GeneralConditions from "./generalConditions.js";
import FloatButton from "../../components/buttons/floatButton/index.js";

const RegistrationForm = () => {
  const { t } = useTranslation();
  const [state, setState] = useState(0);
  const [course, setCourse] = useState("");
  const [courseId, setCourseId] = useState(null);
  const [dataPostulation, setDataPostulation] = useState(null);
  const [universityData, setUniversityData] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    const uId = getUserInfo().id;
    callApi(
      () => postulationInfo({ uId: uId }),
      (data) => {
        setDataPostulation(data);
        savePostulationState(data.generalState);
        saveProgramId(data.courseId);
        setCourse(data.courseName);
        
        if (data.generalState >= generalStatesPostulation.uploadingFiles) {
          setState(FormSteps.FinishedForm);
        } else {
          setState(data.formState);
        }
      }
    );
  }, []);

  const questionFormPage = (state) => {
    if (
      dataPostulation &&
      dataPostulation.generalState == generalStatesPostulation.declined
    ) {
      return (
        <MainContainer showImage={false}>
          <Declined dataPostulation={dataPostulation} setState={setState} />
        </MainContainer>
      );
    }

    switch (state) {
      case FormSteps.PassportDetails:
        return (
          <MainContainer showImage={false}>
            {savePostulationState(generalStatesPostulation.postulationForm)}
            <PassportDetails
              setState={setState}
              courseId={courseId}
              postulationState={dataPostulation.generalState}
              programName = {course}
            />
          </MainContainer>
        );
      case FormSteps.ContactDetails:
        return (
          <MainContainer showImage={false}>
            {savePostulationState(generalStatesPostulation.postulationForm)}
            <ContactDetails
              setState={setState}
              postulationState={dataPostulation.generalState}
              programName = {course}
            />
          </MainContainer>
        );
      case FormSteps.UniversityInformation:
        return (
          <MainContainer showImage={false}>
            {savePostulationState(generalStatesPostulation.postulationForm)}
            <UniversityInformation
              postulationState={dataPostulation.generalState}
              setState={setState}
              setUniversityData={setUniversityData}
              programName = {course}
            />
          </MainContainer>
        );
      case FormSteps.VisaInformation:
        return (
          <MainContainer showImage={false}>
            {savePostulationState(generalStatesPostulation.postulationForm)}
            <VisaInformation
              setState={setState}
              postulationState={dataPostulation.generalState}
              programName = {course}
            />
          </MainContainer>
        );
      case FormSteps.LanguageSkills:
        return (
          <MainContainer showImage={false}>
            {savePostulationState(generalStatesPostulation.postulationForm)}
            <LanguageSkills
              postulationState={dataPostulation.generalState}
              setState={setState}
              programName = {course}
            />
          </MainContainer>
        );
      case FormSteps.AdditionalInfo:
        return (
          <MainContainer showImage={false}>
            {savePostulationState(generalStatesPostulation.postulationForm)}
            <AdditionalInfo
              postulationState={dataPostulation.generalState}
              setState={setState}
              programName = {course}
            />
          </MainContainer>
        );
      case FormSteps.WorkDates:
        return (
          <MainContainer showImage={false}>
            {savePostulationState(generalStatesPostulation.postulationForm)}
            <WorkDates
              postulationState={dataPostulation.generalState}
              setState={setState}
              universityData={universityData}
              programName = {course}
            />
          </MainContainer>
        );
      case FormSteps.HealthInformation:
        return (
          <MainContainer showImage={false}>
            {savePostulationState(generalStatesPostulation.postulationForm)}
            <HealthInformation
              postulationState={dataPostulation.generalState}
              setState={setState}
              programName = {course}
            />
          </MainContainer>
        );
      case FormSteps.OfficeUse:
        return (
          <MainContainer showImage={false}>
            {savePostulationState(generalStatesPostulation.postulationForm)}
            <OfficeUse
              postulationState={dataPostulation.generalState}
              setState={setState}
              programName = {course}
            />
          </MainContainer>
        );
      case FormSteps.BillingInformation:
        return (
          <MainContainer showImage={false}>
            {savePostulationState(generalStatesPostulation.postulationForm)}
            <BillingInformation
              postulationState={dataPostulation.generalState}
              setState={setState}
              programName = {course}
            />
          </MainContainer>
        );
      case FormSteps.GeneralConditions:
        return (
          <MainContainer showImage={false}>
            {savePostulationState(generalStatesPostulation.uploadingFiles)}
            <GeneralConditions
              postulationState={dataPostulation.generalState}
              setState={setState}
              programName = {course}
            />
          </MainContainer>
        );
      case FormSteps.UploadFiles:
        return (
          <MainContainer showImage={false}>
            {savePostulationState(generalStatesPostulation.uploadingFiles)}
            <UploadFiles
              postulationState={dataPostulation.generalState}
              setState={setState}
              programName = {course}
            />
          </MainContainer>
        );
      case FormSteps.SignStep:
        return (
          <MainContainer showImage={false}>
            <SignStep
              postulationState={dataPostulation.generalState}
              setState={setState}
              programName = {course}
            />
          </MainContainer>
        );
      case FormSteps.DownloadStep:
        return (
          <MainContainer showImage={false}>
            <DownloadStep
              postulationState={dataPostulation.generalState}
              setState={setState}
              programName = {course}
            />
          </MainContainer>
        );
      default:
        return (
          <MainContainer class="ww-container-xxl" showImage={false}>
            {/* {savePostulationState(dataPostulation.generalState)} */}
            <CourseSelect
              setState={setState}
              dataPostulation={dataPostulation}
              setCourseId={setCourseId}
              setCourse={setCourse}
            />
          </MainContainer>
        );
    }
  };

  return (
    <>
      {state !== 0 && state !== FormSteps.FinishedForm ? (
        <h1 className="title">
          {t("form.gnl.title")}
          {course}
        </h1>
      ) : (
        <></>
      )}
      <FloatButton href="/educational-material"/>
      {questionFormPage(state)}
      <Footer />
    </>
  );
};

export default RegistrationForm;
