export const getUserInfo = () => {
  const userInfo = localStorage.getItem("userInfo");
  if (!userInfo) return null;
  return JSON.parse(userInfo);
};

export const saveUserInfo = (userInfo) => {
  localStorage.setItem("userInfo", JSON.stringify(userInfo));
};  

export const saveUserDetail = (userDetail) => {
  localStorage.setItem("userDetail", JSON.stringify(userDetail));
};  

export const getPostulationState = () => {
  const userInfo = localStorage.getItem("postulationState");
  if (!userInfo) return null;
  return JSON.parse(userInfo);
};
export const savePostulationState = (postulationState) => {
  localStorage.setItem("postulationState", JSON.stringify(postulationState));
}; 

export const saveProgramId = (programId) => {
  localStorage.setItem("programId", JSON.stringify(programId));
}; 
export const getProgramId = () => {
  const programInfo = localStorage.getItem("programId");
  if (!programInfo) return null;
  return JSON.parse(programInfo);
};

export const isRole = (role) => {
  const userInfo = localStorage.getItem("userInfo");
  if(!userInfo) return false;
  return JSON.parse(userInfo).role === role;
};

export const signOut = () => localStorage.removeItem("userInfo");
