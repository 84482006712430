import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm, Controller } from "react-hook-form";
import AutocompleteSelect from "../../components/forms/inputsMui/autocomplete";
import DateInput from "../../components/forms/inputs/dateInput";
import Button from "../../components/buttons/button";
import { NotificationManager } from "react-notifications";

import { getUserInfo } from "../../services/authService";
import {
  callApi,
  saveBilling,
  getBilling,
  sendNotificationAgency,
  getCountries,
} from "../../services/apiService";
import { FormSteps, generalStatesPostulation } from "../../constants/enum";

const BillingInformation = ({ setState, postulationState, programName }) => {
  const { t } = useTranslation();
  const [postulationId, setPostulationId] = useState("");
  const [billingId, setBillingId] = useState("");
  const [countryList, setCountryList] = useState([]);

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const validations = {
    name: {
      required: {
        value: true,
        message: t("validationError.nameSocial.required"),
      },
    },
    document: {
      required: {
        value: true,
        message: t("validationError.document.required"),
      },
    },
    taxCondition: {
      required: {
        value: true,
        message: t("validationError.taxCondition.required"),
      },
    },
    address: {
      required: {
        value: true,
        message: t("validationError.direction.require"),
      },
    },
    postalCode: {
      required: {
        value: true,
        message: t("validationError.direction.require"),
      },
    },
    countryPermanence: {
      required: {
        value: true,
        message: t("form.validation.countryPermanence"),
      },
    },
  };

  const loadDataBilling = (obj) => {
    setValue("address", !obj || !obj.address ? "" : obj.address);
    setValue("postalCode", !obj || !obj.postal_code ? "" : obj.postal_code);
    setValue("document", !obj || !obj.document ? "" : obj.document);
    setValue(
      "taxCondition",
      !obj || !obj.tax_condition ? "" : obj.tax_condition
    );
    setValue("name", !obj || !obj.name ? "" : obj.name);
    setValue(
      "countryPermanence",
      !obj || !obj.country_permanence_id ? "" : obj.country_permanence_id
    );
  };
  const loadDate = () => {
    let today = Date.now();
    setValue("date", today);
  };

  useEffect(() => {
    const uId = parseInt(getUserInfo().id);
    loadDate();
    callApi(
      () => getBilling({ uId: uId }),
      (data) => {
        loadDataBilling(data);
        setBillingId(data.billingId);
        setPostulationId(data.postulationId);
      }
    );
    callApi(getCountries, (data) => {
      data.sort((a, b) => a.name.localeCompare(b.name));
      setCountryList(data);
    });
  }, []);

  const onSubmitBilling = handleSubmit((data) => {
    const timeElapsed = Date.now();
    data.date = new Date(timeElapsed);
    const uId = parseInt(getUserInfo().id);
    callApi(
      // () =>
      //   getTokenAgency({
      //     titleMessage: "Nuevo participante inscrito",
      //     bodyMessage: "Se ha realizado una nueva postulación",
      //   }),
      () =>
        saveBilling({
          ...data,
          uId,
          postulationId,
          billingId,
          postulationState,
        }),
      () => {
        NotificationManager.success(t("form.success"));
        if (postulationState >= generalStatesPostulation.uploadingFiles) {
          setState(FormSteps.FinishedForm);
          return;
        }
        setState(FormSteps.GeneralConditions);
      }
    );
  });

  const backForm = () => {
    setState(FormSteps.OfficeUse);
  };

  const tokensAgency = () => {
    callApi(
      () =>
        sendNotificationAgency({
          titleMessage: "Nuevo participante inscrito",
          bodyMessage: "Se ha realizado una nueva postulación",
        }),
      () => {}
    );
  };

  return (
    <>
      <h2 className="title">{t("form.program") + programName}</h2>
      <h2>{t("form.billing.title")}</h2>
      <FormProvider {...methods}>
        <form>
          <div className="container-inputs">
            {/* name */}
            <div className="inputs-style">
              <label>
                {t("form.billing.name")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("form.billing.name")}
                className="form-control mb-2"
                name="name"
                {...register("name", validations.name)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.name && errors.name.message}
              </span>
            </div>
            {/* document */}
            <div className="inputs-style">
              <label>
                {t("form.billing.document")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("form.billing.document")}
                className="form-control mb-2"
                name="document"
                {...register("document", validations.document)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.document && errors.document.message}
              </span>
            </div>
          </div>
          <div className="container-inputs">
            {/* taxCondition */}
            <div className="inputs-style">
              <label>
                {t("form.billing.tax")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("form.billing.tax")}
                className="form-control mb-2"
                name="taxCondition"
                {...register("taxCondition", validations.taxCondition)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.taxCondition && errors.taxCondition.message}
              </span>
            </div>
            <div className="inputs-style">
              <label>
                {t("form.countryPermanence")}
                <span className="text-danger"> *</span>
              </label>
              <AutocompleteSelect
                name="countryPermanence"
                label={t("form.countryPermanence")}
                options={countryList}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("countryPermanence") == "" && (
                <span className="text-danger text-small d-block mb-2 validation-margin">
                  {errors.countryPermanence && errors.countryPermanence.message}
                </span>
              )}
            </div>
          </div>
          <div className="container-inputs">
            {/* postalCode */}
            <div className="inputs-style">
              <label>
                {t("form.billing.postalCode")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("form.billing.postalCode")}
                className="form-control mb-2"
                name="postalCode"
                {...register("postalCode", validations.postalCode)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.postalCode && errors.postalCode.message}
              </span>
            </div>
            {/* address */}
            <div className="inputs-style">
              <label>
                {t("form.billing.address")}
                <span className="text-danger"> *</span>
              </label>
              <input
                placeholder={t("form.billing.address")}
                className="form-control mb-2"
                name="document"
                {...register("address", validations.address)}
              />
              <span className="text-danger text-small d-block mb-2 validation-margin">
                {errors.address && errors.address.message}
              </span>
            </div>
          </div>
          <div className="container-inputs">
            {/* date */}
            <div className="inputs-style">
              <DateInput
                name="date"
                control={control}
                disabled={true}
                placeholder={t("form.billing.date")}
                label={t("form.billing.date")}
                {...register("date")}
              />
              {errors.address && <div style={{ marginBottom: "30px" }}></div>}
            </div>
          </div>
        </form>
      </FormProvider>
      <div className="container-inputs">
        <Button
          size="btn-sx"
          type="ww-btn-secondary"
          label={t("main.back")}
          onClick={() => backForm()}
        />
        <Button
          size="btn-sx"
          onClick={onSubmitBilling}
          label={t("main.next")}
        />
      </div>
      {postulationState >= generalStatesPostulation.uploadingFiles && (
        <Button
          type="ww-btn-secondary"
          onClick={() => {
            setState(FormSteps.FinishedForm);
          }}
          label={t("main.backPostulation")}
        />
      )}
    </>
  );
};

export default BillingInformation;
