import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  BiBriefcase,
  BiCaretRightCircle,
  BiCheckCircle,
  BiFile,
  BiFlag,
  BiHome,
  BiLibrary,
  BiListUl,
  BiUser,
} from "react-icons/bi";
import { useTranslation } from "react-i18next";

const steps = [
  {
    label: "main.form",
    icon: <BiFile />,
    nextLabel: "main.interview",
    nextIcon: <BiUser />,
  },
  {
    label: "main.interview",
    icon: <BiUser />,
    previousLabel: "main.form",
    previousIcon: <BiFile />,
    nextLabel: "main.videoCV",
    nextIcon: <BiCaretRightCircle />,
  },
  {
    label: "main.videoCV",
    icon: <BiCaretRightCircle />,
    previousLabel: "main.interview",
    previousIcon: <BiUser />,
    nextLabel: "general.mockInterview",
    nextIcon: <BiUser />,
  },
  {
    label: "general.mockInterview",
    icon: <BiUser />,
    previousLabel: "main.videoCV",
    previousIcon: <BiCaretRightCircle />,
    nextLabel: "general.employerSelection",
    nextIcon: <BiListUl />,
  },
  {
    label: "general.employerSelection",
    icon: <BiListUl />,
    previousLabel: "general.mockInterview",
    previousIcon: <BiUser />,
    nextLabel: "general.interviewWork",
    nextIcon: <BiUser />,
  },
  {
    label: "general.interviewWork",
    icon: <BiUser />,
    previousLabel: "general.employerSelection",
    previousIcon: <BiListUl />,
    nextLabel: "general.jobOffer",
    nextIcon: <BiBriefcase />,
  },
  {
    label: "general.jobOffer",
    icon: <BiBriefcase />,
    previousLabel: "general.interviewWork",
    previousIcon: <BiUser />,
    nextLabel: "general.interviewConsular",
    nextIcon: <BiFlag />,
  },
  {
    label: "general.interviewConsular",
    icon: <BiFlag />,
    previousLabel: "general.jobOffer",
    previousIcon: <BiBriefcase />,
    nextLabel: "general.flightAndAccommodation",
    nextIcon: <BiHome />,
  },
  {
    label: "general.flightAndAccommodation",
    icon: <BiHome />,
    previousLabel: "general.interviewConsular",
    previousIcon: <BiFlag />,
    nextLabel: "general.preTripTraining",
    nextIcon: <BiLibrary />,
  },
  {
    label: "general.preTripTraining",
    icon: <BiLibrary />,
    previousLabel: "general.flightAndAccommodation",
    previousIcon: <BiHome />,
    nextLabel: "main.result",
    nextIcon: <BiCheckCircle />,
  },
  {
    label: "main.result",
    icon: <BiCheckCircle />,
    previousLabel: "general.preTripTraining",
    previousIcon: <BiLibrary />,
  },
];

export default function CarruselProgress({ stepProcess }) {
  const { t } = useTranslation();

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(stepProcess);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <Paper
        style={{ justifyContent: "center", backgroundColor: "transparent" }}
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 50,
          pl: 2,
          bgcolor: "background.default",
        }}
      >
        <div
          className="row "
          style={{
            justifyContent: "space-between",
            display: "flex",
            width: "80%",
            alignItems: "center",
          }}
        >
          <div
             className={
                activeStep <= stepProcess
                  ? `circle-movile-steps-secondary-sel`
                  : `circle-movile-steps-secondary`
              }
            style={{
              backgroundColor: activeStep === 0 && "transparent",
            }}
          >
            {steps[activeStep].previousIcon}
          </div>
          <div
            className={
              activeStep <= stepProcess
                ? `circle-movile-steps-selected`
                : `circle-movile-steps`
            }
            style={{ fontSize: "xx-large" }}
          >
            {steps[activeStep].icon}
          </div>
          <div
              className={
                activeStep < stepProcess
                  ? `circle-movile-steps-secondary-sel`
                  : `circle-movile-steps-secondary`
              }
            style={{
              backgroundColor: activeStep === 9 && "transparent",
            }}
          >
            {steps[activeStep].nextIcon}
          </div>
        </div>
      </Paper>
      <Box sx={{ textAlign: "center", maxWidth: 400, width: "100%", p: 2 }}>
        <div
          className="row "
          style={{
            justifyContent: "space-between",
            display: "flex",
            width: "110%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              flex: 1,
              textAlign: "center",
              fontSize: "smaller",
              color: "gray",
            }}
          >
            {t(steps[activeStep].previousLabel)}
          </div>
          <div style={{ flex: 1, textAlign: "center" }}>
            {t(steps[activeStep].label)}
          </div>
          <div
            style={{
              flex: 1,
              textAlign: "center",
              fontSize: "smaller",
              color: "gray",
            }}
          >
            {t(steps[activeStep].nextLabel)}
          </div>
        </div>
      </Box>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
           { t("main.next")}
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
           { t("core.datatable.pagination.previous")}
          </Button>
        }
      />
    </Box>
  );
}
