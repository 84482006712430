import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import AutocompleteSelect from "../../components/forms/inputsMui/autocomplete";
import { getUserInfo, saveProgramId } from "../../services/authService";
import { FormProvider, useForm } from "react-hook-form";
import Table, { defaultOptions, defaultCol } from "../../components/table";
import Button from "../../components/buttons/button";
import {
  callApi,
  getCourseplanning,
  postulationInfo,
  saveScheduleInterview,
  getMessagesPostulation,
  getWorkPreferencesTable,
} from "../../services/apiService";
import MainContainer from "../../layouts/container/mainContainer";
import Modal from "@mui/material/Modal";
import "../../styles/pages/postulationState.scss";
import { FormSteps } from "../../constants/enum";
import CourseSelectResume from "./courseSelect/courseSelectResume";
import Presentation from "../educationalMaterial/presentation";

const CourseSelect = ({
  setState,
  dataPostulation,
  setCourseId,
  setCourse,
}) => {
  const { t } = useTranslation();
  const [botonActivo, setBotonActivo] = useState(false);
  const [open, setOpen] = useState(false);
  const [postulation, setPostulation] = useState(false);
  const [courseList, setCourseList] = useState([]);
  const [alertModal, setAlertModal] = useState(false);
  const [alertModalNotification, setAlertModalNotification] = useState(false);
  const [videoUrlModal, setVideoUrlModal] = useState(false);
  const [courseState, setCourseState] = useState(0);
  const [postulationId, setPostulationId] = useState();
  const [generalState, setGeneralState] = useState(0);
  const [interviewState, setInterviewState] = useState(false);
  const [messagesPostulation, setMessagesPostulation] = useState([]);
  const [openModalMsg, setOpenModalMsg] = useState(false);
  const [jobPreferencesModal, setJobPreferencesModal] = useState(false);
  //MULTISELECT STATE
  const [tableData, setTableData] = useState([]);
  const [rowsSelected, setRowsSelected] = useState([]);

  const handleCloseodalMsg = () => setOpenModalMsg(false);

  const loadWorkPreferences = () => {
    callApi(
      () => getWorkPreferencesTable(),
      (data) => {
        setTableData(data);
      }
    );
  };

  useEffect(() => {
    loadWorkPreferences();
    const uId = getUserInfo().id;
    callApi(
      () => postulationInfo({ uId: uId }),
      (data) => {
        setGeneralState(data.generalState);
        setCourseState(data.formState);
        setPostulationId(data.postulationId);
        setPostulation(data);
      }
    );
    callApi(
      () => getCourseplanning(),
      (data) => {
        setCourseList(data);
      }
    );
  }, []);

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const validations = {
    course_id: {
      required: { value: true, message: t("validationError.city.require") },
    },
    video_url: {
      required: { value: true, message: t("validationError.videoUrl.require") },
    },
    file: {
      required: {
        value: true,
        message: t("image.requireImage"),
      },
    },
  };

  const handleClose = () => {
    setOpen(false);
    setJobPreferencesModal(false);
  };

  const onSubmit = handleSubmit((data) => {
    setBotonActivo(true);
    if (postulation.generalState != "") {
      NotificationManager.warning(t("course.inProcess"));
      setAlertModal(false);
      return;
    }
    const uId = getUserInfo().id;
    saveProgramId(watch("course_id"));
    setState(FormSteps.PassportDetails);
  });

  //MODAL comment
  const getMessagesPostulationFunction = (postulationId) => {
    callApi(
      () => getMessagesPostulation({ postulationId: postulationId }),
      (data) => {
        setMessagesPostulation(data);
      }
    );
    setOpenModalMsg(true);
  };

  const columnsMsg = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    defaultCol("date", "msg.table.date"),
    {
      name: "statePostulationGeneralName",
      label: t("msg.table.stateProcess"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, travelerList, updateValue) => {
          return t(value);
        },
      },
    },
    defaultCol("comment", "msg.table.comment"),
  ];

  //NOTIFICATION
  const tokensAgency = () => {
    const nameUser = getUserInfo().name;
    setPostulationId(getProp(dataPostulation, "postulationId"));
    // callApi(() =>
    //   getTokenAgency({
    //     titleMessage: t("pushNotification.title.schedule"),
    //     bodyMessage: nameUser + t("pushNotification.body.schedule"),
    //   })
    // );
    callApi(() => saveScheduleInterview({ postulationId: postulationId }));
    window.location.reload();
    setAlertModalNotification(false);
    setInterviewState(true);
  };

  const getProp = (obj, prop) => {
    if (!obj) return "";
    const propValue = obj[prop];
    return propValue ? propValue : "";
  };

  const resultadoFiltrado = rowsSelected.map((x) => tableData[x]);

  const programId = watch("course_id");

  const getProgramName = (programId) => {
    if (programId == null || programId == "") return;
    const elementoEncontrado = courseList.find((item) => item.id === programId);
    return elementoEncontrado ? elementoEncontrado.name : "";
  };

  return (
    <>
      <h1 className="title">{t("course.title")}</h1>
      <br />
      {dataPostulation && dataPostulation.courseId == "" && (
        <FormProvider {...methods}>
          <div>
            <p style={{ margin: "15px", textAlign: "center" }}>
              {t("postulationState.selectCourse")}
            </p>
            <div
              className="inputs-style"
              style={{ margin: "auto", display: "flex" }}
            >
              <AutocompleteSelect
                name="course_id"
                label={t("course.select")}
                options={courseList}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              <span className="text-danger text-small d-block mb-2">
                {errors.course_id && errors.course_id.message}
              </span>
            </div>
          </div>
          {programId != null && programId != "" && (
            <Presentation courseId={programId} />
          )}
          {programId && (
            <>
             <div
              className="inputs-style"
              style={{ margin: "auto", display: "flex" }}
            >
              <Button
                label={t("promgram.register")}
                size="btn-s"
                onClick={() => {
                  setCourseId(programId);
                  setCourse(getProgramName(programId));
                  setAlertModal(true);
                }}
              />
              </div>
            </>
          )}
        </FormProvider>
      )}
      <CourseSelectResume
        generalState={generalState}
        dataPostulation={dataPostulation}
        setState={setState}
        setAlertModalNotification={setAlertModalNotification}
        setVideoUrlModal={setVideoUrlModal}
        getMessagesPostulationFunction={getMessagesPostulationFunction}
        setGeneralState={setGeneralState}
      />
      {/* MODAL ALERTA */}
      <Modal
        open={alertModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m">
          {courseState < 2 ? (
            <>
              <p>
                {t("course.alert")} {getProgramName(programId) + "?"}
              </p>
              <div style={{ display: "flex" }}>
                <Button
                  type="ww-btn-secondary"
                  style={{ margin: "10px" }}
                  size="btn-sx secondary"
                  label={t("main.cancel")}
                  onClick={() => setAlertModal(false)}
                />
                <Button
                  style={{ margin: "10px" }}
                  size="btn-sx"
                  label={t("main.continue")}
                  onClick={() => onSubmit()}
                  disabled={botonActivo}
                />
              </div>
            </>
          ) : (
            <>
              <p>{t("course.active")}</p>
              <Button
                style={{ margin: "10px" }}
                size="btn-s secondary"
                label={t("main.cancel")}
                onClick={() => setAlertModal(false)}
              />
            </>
          )}
        </MainContainer>
      </Modal>
      {/* MODAL TABLA MENSAJES */}
      <Modal
        open={openModalMsg}
        onClose={handleCloseodalMsg}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer showImage={false} class="ww-container-xxl">
          <Table
            data={messagesPostulation}
            columns={columnsMsg}
            options={defaultOptions()}
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              size="btn-sx"
              type="ww-btn-secondary"
              label={t("main.close")}
              onClick={handleCloseodalMsg}
            />
          </div>
        </MainContainer>
      </Modal>
      {/* MODAL NOTIFICATION */}
      <Modal
        open={alertModalNotification}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m">
          <p>{t("Agend.alert")}</p>
          <div className="btn-container">
            <Button
              style={{
                margin: "10px",
                paddingLeft: "50px",
                paddingRight: "50px",
              }}
              size="btn-xs"
              label={t("main.yes")}
              onClick={() => tokensAgency()}
            />
            <Button
              type="ww-btn-secondary"
              style={{
                margin: "10px",
                paddingLeft: "50px",
                paddingRight: "50px",
              }}
              size="btn-xs secondary"
              label={t("main.no")}
              onClick={() => setAlertModalNotification(false)}
            />
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default CourseSelect;
