import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { callApi, recoverPassword } from "../../services/apiService";
import { useTranslation } from "react-i18next";
import MainContainer from "../../layouts/container/mainContainer";
import Button from "../../components/buttons/button";

import Footer from "../../layouts/footer";
import "../../styles/pages/login.scss";

const RecoverPassword = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = handleSubmit((data) => {
    callApi(
      () => recoverPassword(data),
      () => navigate("/recover-password/email-sent")
    );
  });

  const validations = {
    email: {
      required: { value: true, message: t("validationError.email.require") },
      pattern: {
        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
        message: t("validationError.email.valid"),
      },
    },
  };

  return (
    <>
      <MainContainer class="ww-container-m">
        <h1 className="title">{t("recoverPassword.title")}</h1>
        <FormProvider {...methods}>
          <form>
            {/* Correo electronico */}
            <p className="text">{t("recoverPassword.text")}</p>
            <label>{t("main.email")}</label>
            <input
              placeholder={t("placeholder.email")}
              className="form-control mb-2"
              name="email"
              {...register("email", validations.email)}
            />
            <span className="text-danger text-small d-block mb-2 validation-margin">
              {errors.email && errors.email.message}
            </span>
            <Button label={t("main.send")} size="btn-s " onClick={onSubmit} />
            <br />
            <div>
              <Link
                style={{
                  color: "#00B0DA",
                  textDecoration: "none",
                  textAlign: "center",
                }}
                to="/"
              >
                <div>{t("message.goBack")}</div>
              </Link>
            </div>
          </form>
        </FormProvider>
      </MainContainer>
      <Footer />
    </>
  );
};

export default RecoverPassword;
