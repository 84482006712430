import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserInfo } from "../../services/authService";
import Button from "../../components/buttons/button";
import { callApi, pdfGenerator } from "../../services/apiService";
import SignCanvas from "../../components/signComponent";
import { FormSteps } from "../../constants/enum";

const urlBase = process.env.REACT_APP_URL_BASE;

const SignStep = ({ setState, postulationState , programName}) => {
  const isAndroid = /Android/i.test(navigator.userAgent);
  const { t } = useTranslation();
  const [signBtn, setSignBtn] = useState(false);

  const styles = {
    border: "0.0625rem solid #9c9c9c",
    borderRadius: "0.25rem",
  };

  const backForm = () => {
    setState(FormSteps.UploadFiles);
  };

  useEffect(() => {
    const uInfo = getUserInfo();
    callApi(
      () => pdfGenerator(uInfo),
      () => {}
    );
  }, []);

  useEffect(() => {
    setInterval(() => {
      setSignBtn(true);
    }, 5000);
  }, []);

  return (
    <>
    <h2 className="title">{t("form.program") + programName}</h2>
      <h2>{t("signStep.title")}</h2>
      {signBtn ? (
        <div>
          {isAndroid ? (
            <a
              href={`${urlBase}/${getUserInfo().id}/finalForm.pdf`}
              download
              target="_blank"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button size="btn-m" label={t("form.downloadForm")} />
            </a>
          ) : (
            <embed
              src={`${urlBase}/${getUserInfo().id}/finalForm.pdf`}
              className="file-preview-view"
            />
          )}

          <SignCanvas
            setSignBtn={setState}
            postulationState={postulationState}
          />
        </div>
      ) : (
        <div class="spinner-border text-info" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      )}

      <Button
        size="btn-sx"
        type="ww-btn-secondary"
        label={t("main.back")}
        onClick={() => backForm()}
      />
    </>
  );
};

export default SignStep;
