import React from "react";
import Modal from "@mui/material/Modal";
import Button from "../../components/buttons/button";
import { useTranslation } from "react-i18next";
import { callApi } from "../../services/apiService";
import { getUserInfo } from "../../services/authService";
import MainContainer from "../../layouts/container/mainContainer";

const urlBase = process.env.REACT_APP_URL_BASE;

const DownloadPdfModal = ({ open, onClose, url }) => {
  const { t } = useTranslation();

  return (
    <>
      {/* MODAL DENEGAR  VIDEO CV*/}
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer class="ww-container-m" Nologo={true}>
          <h1
            style={{ marginTop: "15px", marginBottom: "20px" }}
            className="title"
          >
            {t("form.previewView")}
          </h1>

          <embed
            src={`${urlBase + url.replace("WorldWideFiles", "")}`}
            className="file-preview-view"
          />
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Button
              type="ww-btn-secondary"
              size="btn-s secondary"
              label={t("main.close")}
              onClick={() => {
                onClose();
              }}
            />

            <a
              href={`${urlBase + url.replace("WorldWideFiles", "")}`}
              target="_blank"
              download="w3logo"
            >
              <Button size="btn-s" label={t("downloadStep.link.download")} />
            </a>
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default DownloadPdfModal;
