import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { ImEye, ImEyeBlocked } from "react-icons/im";
import { saveUserInfo } from "../../services/authService";
import { callApi, getToken } from "../../services/apiService";
import { useTranslation } from "react-i18next";
import MainContainer from "../../layouts/container/mainContainer";
import Button from "../../components/buttons/button";
import Footer from "../../layouts/footer";
import ModalPWA from "../../components/modalPWA";
import "../../styles/pages/login.scss";

const Login = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState(true);
  let navigate = useNavigate();

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = handleSubmit((data) => {
    callApi(
      () => getToken(data),
      (data) => {
        saveUserInfo(data);
        navigate("/home");
      }
    );
  });

  const validations = {
    email: {
      required: { value: true, message: t("validationError.email.require") },
      pattern: {
        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
        message: t("validationError.email.valid"),
      },
    },
    password: {
      required: { value: true, message: t("validationError.password.require") },
      minLength: { value: 5, message: t("validationError.password.short") },
    },
  };

  return (
    <>
      <MainContainer class="ww-container-m">
        <FormProvider {...methods}>
          <form className="form-m">
            <label>{t("main.email")}</label>
            <input
              placeholder={t("placeholder.email")}
              className="form-control mb-2"
              name="email"
              {...register("email", validations.email)}
            />
            <span className="text-danger text-small d-block mb-2 validation-margin">
              {errors.email && errors.email.message}
            </span>
            <label>{t("main.password")}</label>

            <div className="input-group">
              <input
                placeholder={t("placeholder.password")}
                className="form-control mb-2"
                name={password}
                type={password ? "password" : "text"}
                {...register("password", validations.password)}
              />
              <div className="input-group-append">
                <div
                  className="ButtonEyeContainer"
                  onClick={() => setPassword(!password)}
                >
                  {password ? (
                    <ImEye className="ButtonEye" />
                  ) : (
                    <ImEyeBlocked className="ButtonEye" />
                  )}
                </div>
              </div>
            </div>
            <span className="text-danger text-small d-block mb-2 validation-margin">
              {errors.password && errors.password.message}
            </span>
            <Button size="btn-s" label={t("main.getInto")} onClick={onSubmit} />
            <br />
            <div className="forgot-password">
              <div>
                <Link to="/recover-password">{t("login.forgotPassword")}</Link>
              </div>
              <div>
                <span>{t("login.registerQuestion")}</span>
                <Link to="/register">{t("login.registerHere")}</Link>
              </div>
            </div>
          </form>
        </FormProvider>
      </MainContainer>
      <ModalPWA />
      <Footer />
    </>
  );
};

export default Login;
