import React from "react";
import ReactPlayer from "react-player";
import "../../styles/components/videoPlayer.scss";


const VideoPlayer = (props) => {
  return (
    <div className="wrapper">
      <div>
        <ReactPlayer className="youtube-player" url={props.url} controls />
      </div>
      <div className="video-title">
        <p>{props.title}</p>
      </div>
    </div>
  );
};

export default VideoPlayer;
