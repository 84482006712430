import React from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";
import { getUserInfo } from "../../services/authService";
import { callApi, signDocument, changeState } from "../../services/apiService";
import Button from "../buttons/button";
import i18next from "../../i18n";
import { NotificationManager } from "react-notifications";
import { FormSteps } from "../../constants/enum";

const styles = {
  border: "0.0625rem solid #9c9c9c",
  borderRadius: "0.25rem",
};

const SignDocumentPDF = (sign, postulationState) => {
  const uInfo = getUserInfo();
  const submitData = {
    uId: uInfo.id,
    state: FormSteps.DownloadStep,
    postulationState: postulationState,
  };
  callApi(() => {
    signDocument({ id: uInfo.id, sign: sign });
    changeState(submitData);
  });
};

const SignCanvas = class extends React.Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
    this.setState = props.setSignBtn;
    this.postulationState = props.postulationState;
  }

  render() {
    return (
      <div className="column-center">
        <ReactSketchCanvas
          ref={this.canvas}
          strokeWidth={5}
          strokeColor="black"
        />
        <div className="inputs-style" style={{ placeSelf: "center" }}>
          <div className="container-inputs">
            <Button
              size="btn-s"
              label={i18next.t("main.clean")}
              onClick={() => {
                this.canvas.current.clearCanvas();
              }}
            />
            <Button
              size="btn-s"
              label={i18next.t("main.sign")}
              onClick={() => {
                this.canvas.current
                  .exportImage("png")
                  .then((data) => {
                    SignDocumentPDF(data, this.postulationState);
                    this.setState(FormSteps.DownloadStep);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default SignCanvas;
