import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdDelete } from "react-icons/md";
import { BsPencilFill } from "react-icons/bs";
import { BiHelpCircle } from "react-icons/bi";

import Modal from "@mui/material/Modal";
import { NotificationManager } from "react-notifications";
import { FormProvider, useForm } from "react-hook-form";
import Table, { defaultOptions, defaultCol } from "../../components/table";
import {
  callApi,
  getLanguages,
  postLanguages,
  deleteLanguages,
  editLanguages,
  setFormState,
} from "../../services/apiService";
import { getUserInfo } from "../../services/authService";
import MainContainer from "../../layouts/container/mainContainer";
import Button from "../../components/buttons/button";
import AutocompleteSelect from "../../components/forms/inputsMui/autocomplete";
import {
  FormSteps,
  generalStatesPostulation,
  language_skills,
  languages,
} from "../../constants/enum";

const LanguageSkills = ({ setState, postulationState , programName}) => {
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [editId, seteditId] = useState("");
  const [botonActivo, setBotonActivo] = useState(false);
  const [languagesList, setLanguagesList] = useState([]);
  const [alertModal, setAlertModal] = useState(false);

  const { t } = useTranslation();

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const getObj = (tableMeta) =>
    languagesList.find((x) => x.id === tableMeta.rowData[0]);

  useEffect(() => {
    const uId = getUserInfo().id;
    loadLanguages(uId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => setOpen(false);

  const getNameById = (id) => {
    if (id == null) {
      return "";
    }
    const languagesListTmp = [
      { id: "GERMAN", name: t("language.German") },
      { id: "KOREAN", name: t("language.Korean") },
      { id: "MANDARIN", name: t("language.Mandarin") },
      { id: "SPANISH", name: t("language.Spanish") },
      { id: "FRENCH", name: t("language.French") },
      { id: "JAPANESE", name: t("language.Japanese") },
      { id: "ENGLISH", name: t("language.English") },
      { id: "PORTUGUESE", name: t("language.Portuguese") },
      { id: "RUSSIAN", name: t("language.Russian") },
    ];
    var language = languagesListTmp.find((l) => l.id === id);
    return (language = !null ? language.name : "");
  };

  const getNameSkillById = (id) => {
    if (id == null) {
      return "";
    }
    const skillListTmp = [
      { id: "BASIC", name: t("language.basic") },
      { id: "PRE_INTERMEDIATE", name: t("language.preIntermediate") },
      { id: "INTERMEDIATE", name: t("language.intermediate") },
      { id: "HIGH_MIDIUM", name: t("language.highMidium") },
      { id: "ADVANCED", name: t("language.advanced") },
    ];
    var skill = skillListTmp.find((l) => l.id == id);
    return (skill = !null ? skill.name : "");
  };

  const loadLanguages = (id) => {
    callApi(
      () => getLanguages({ id: id }),
      (data) => {
        data.map((item) => {
          item.name = getNameById(item.language_id);
          item.nameSkill = getNameSkillById(item.language_skill);
        });
        setLanguagesList(data);
      }
    );
  };

  const loadLanguagesData = (obj) => {
    setValue("language_id", !obj || !obj.language_id ? "" : obj.language_id);
    setValue(
      "language_skill",
      !obj || !obj.language_skill ? "" : obj.language_skill
    );
  };

  const cleanInputs = () => {
    setValue("language_id", "");
    setValue("language_skill", "");
  };

  const onSubmit = handleSubmit((data) => {
    const uId = getUserInfo().id;
    const submitData = {
      id: uId,
      language_id: data.language_id,
      language_skill: data.language_skill,
      postulationState: postulationState,
    };
    if (languagesList.find((x) => x.language_id === data.language_id)) {
      NotificationManager.error(t("language.already"));
    } else {
      callApi(
        () => postLanguages(submitData),
        (data) => {
          NotificationManager.success(t("language.add"));
          data.map((item) => {
            item.name = getNameById(item.language_id);
            item.nameSkill = getNameSkillById(item.language_skill);
          });
          setLanguagesList(data);
          loadLanguages(uId);
          cleanInputs();
        }
      );
    }
  });

  const onDelete = (data) => {
    const uId = getUserInfo().id;
    setBotonActivo(true);
    const id = {
      id: data,
    };
    callApi(
      () => deleteLanguages(id),
      () => {
        NotificationManager.warning(t("language.delete"));
        loadLanguages(uId);
      }
    );
    setBotonActivo(false);
    setAlertModal(false);
  };

  const onEdit = handleSubmit((data) => {
    const uId = getUserInfo().id;

    setBotonActivo(true);
    callApi(
      () => editLanguages({ ...data, id: editId }),
      () => {
        NotificationManager.success(t("language.edit"));
        cleanInputs();
        handleClose();
        loadLanguages(uId);
      }
    );
    setBotonActivo(false);
  });

  const next = () => {
    const uId = getUserInfo().id;
    callApi(
      () => setFormState({ state: FormSteps.AdditionalInfo, uId: uId }),
      () => {
        NotificationManager.success(t("form.success"));
        setState(6);
      }
    );
  };
  const backForm = () => {
    setState(FormSteps.VisaInformation);
  };
  const validations = {
    language_id: {
      required: { value: true, message: t("language.validation") },
    },
    language_skill: {
      required: { value: true, message: t("language.validationII") },
    },
  };

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    defaultCol("name", "language.language"),
    defaultCol("nameSkill", "language.skills"),
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, employee, updateValue) => {
          var rowObj = getObj(employee);

          return (
            <div className="table-btn-group">
              <Button
                disabled={botonActivo}
                label={<BsPencilFill />}
                onClick={() => {
                  seteditId(rowObj.id);
                  setOpen(true);
                  loadLanguagesData(rowObj);
                }}
              />
              <Button
                disabled={botonActivo}
                label={<MdDelete />}
                onClick={() => {
                  setDeleteId(rowObj.id);
                  setAlertModal(true);
                }}
              />
            </div>
          );
        },
      },
    },
  ];

  return (
    <>
      <FormProvider {...methods}>
      <h2 className="title">{t("form.program") + programName}</h2>
        <h2>{t("lenguage.title")}</h2>
        <form>
          <div className="container-inputs">
            <div className="inputs-style">
              <label>{t("language.language")}</label>
              <AutocompleteSelect
                name="language_id"
                label={t("form.countryCitizenship")}
                options={languages()}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("language_id") == "" && (
                <span className="text-danger text-small d-block mb-2">
                  {errors.language_id && errors.language_id.message}
                </span>
              )}
            </div>

            <div className="inputs-style">
              <label>{t("language.skills")}</label>
              <AutocompleteSelect
                name="language_skill"
                label={t("language.skills")}
                options={language_skills()}
                register={register}
                registerOptions={validations}
                watch={watch}
                setValue={setValue}
              />
              {watch("language_skill") == "" && (
                <span className="text-danger text-small d-block mb-2">
                  {errors.language_skill && errors.language_skill.message}
                </span>
              )}
            </div>
            <a
              href="https://learnenglish.britishcouncil.org/english-levels/understand-your-english-level"
              target="_blank"
            >
              <div className="a-btn">
                <BiHelpCircle />
              </div>
            </a>
          </div>
          <Button
            size="btn-s"
            disabled={botonActivo}
            label={t("language.addnew")}
            onClick={onSubmit}
          />
        </form>
      </FormProvider>
      <br />
      <Table
        data={languagesList}
        columns={columns}
        options={defaultOptions()}
      />
      <div className="container-inputs container-inputs-topmargen">
        <Button
          size="btn-sx"
          type="ww-btn-secondary"
          disabled={botonActivo}
          label={t("main.back")}
          onClick={() => backForm()}
        />
        {languagesList.length !== 0 && (
          <Button
            size="btn-sx"
            disabled={botonActivo}
            label={t("main.next")}
            onClick={() => next()}
          />
        )}
      </div>
      {postulationState >= generalStatesPostulation.uploadingFiles && (
        <Button
          type="ww-btn-secondary"
          onClick={() => {
            setState(FormSteps.FinishedForm);
          }}
          label={t("main.backPostulation")}
        />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer>
          <h1 className="title">{t("Edit.Language")}</h1>

          <FormProvider {...methods}>
            <form>
              <div className="container-inputs">
                <div className="inputs-style">
                  <label>{t("language.language")}</label>
                  <AutocompleteSelect
                    name="language_id"
                    label={t("form.countryCitizenship")}
                    options={languages()}
                    register={register}
                    registerOptions={validations}
                    watch={watch}
                    setValue={setValue}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors.language_id && errors.language_id.message}
                  </span>
                </div>

                <div className="inputs-style">
                  <label>{t("language.skills")}</label>
                  <AutocompleteSelect
                    name="language_skill"
                    label={t("language.skills")}
                    options={language_skills()}
                    register={register}
                    registerOptions={validations}
                    watch={watch}
                    setValue={setValue}
                  />
                  <span className="text-danger text-small d-block mb-2">
                    {errors.language_skill && errors.language_skill.message}
                  </span>
                </div>
              </div>
              <br />
            </form>
            <div>
              <div className="container-inputs">
                <Button
                  size="btn-s secondary"
                  type="ww-btn-secondary"
                  label={t("main.cancel")}
                  onClick={() => setOpen(false)}
                />
                <Button
                  size="btn-s"
                  disabled={botonActivo}
                  label={"EDITAR"}
                  onClick={() => onEdit(editId)}
                />
              </div>
            </div>
          </FormProvider>
        </MainContainer>
      </Modal>

      <Modal
        open={alertModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m">
          <p>{t("language.alert")}</p>
          <div>
            <div className="container-inputs">
              <Button
                style={{ margin: "10px" }}
                type="ww-btn-secondary"
                size="btn-s secondary"
                label={t("main.cancel")}
                onClick={() => setAlertModal(false)}
              />
              <Button
                style={{ margin: "10px" }}
                size="btn-s"
                label={t("main.delete")}
                onClick={() => onDelete(deleteId)}
              />
            </div>
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default LanguageSkills;
