import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/buttons/button";
import "../../styles/pages/postulationState.scss";
import {
  callApi,
  getWorkPreferencesByPostulation,
  getWorkPreferencesDates,
} from "../../services/apiService";
import MainContainer from "../../layouts/container/mainContainer";
import Modal from "@mui/material/Modal";
import Table, { defaultOptions, defaultCol } from "../../components/table";
import { FormProvider, useForm } from "react-hook-form";
import DateInput from "../../components/forms/inputs/dateInput";

const EmployerListModal = ({
  handleCloseEmployerList,
  openEmployerList,
  postulationId,
}) => {
  const { t } = useTranslation();
  const [jobPreferencesModal, setJobPreferencesModal] = useState(false);
  const [selectableRowsHideCheckboxes, setSelectableRowsHideCheckboxes] =
    useState(false);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [tableData, setTableData] = useState([]);

  const defaultValues = { commentPriority: "" };
  const methods = useForm({ defaultValues });
  const {
    control,
    setValue,
    register,
    watch,
    formState: { errors },
  } = methods;

  const resultadoFiltrado = rowsSelected.map((x) => tableData[x]);

  const handleClose = () => {
    handleCloseEmployerList();
    setJobPreferencesModal(false);
  };

  useEffect(() => {
    if (!openEmployerList) return;
    loadWorkPreferences();
    getWorkDates();
  }, [openEmployerList]);

  const loadWorkPreferences = () => {
    callApi(
      () => getWorkPreferencesByPostulation({ postulationId: postulationId }),
      (data) => {
        setValue("commentPriority", data.comment);
        setTableData(data.resultArray);
      }
    );
  };
  const getWorkDates = () => {
    callApi(
      () => getWorkPreferencesDates({ postulationId: postulationId }),
      (data) => {
        loadData(data);
      }
    );
  };
  const loadData = (dataDates) => {
    setValue(
      "uniBreakBegins",
      !dataDates || !dataDates.universityBreakBegin
        ? ""
        : new Date(dataDates.universityBreakBegin)
    );
    setValue(
      "uniBreakEnd",
      !dataDates || !dataDates.universityBreakEnd
        ? ""
        : new Date(dataDates.universityBreakEnd)
    );
    setValue(
      "startDateWork",
      !dataDates || !dataDates.startDateWork
        ? ""
        : new Date(dataDates.startDateWork)
    );
    setValue(
      "endDateWork",
      !dataDates || !dataDates.endDateWork
        ? ""
        : new Date(dataDates.endDateWork)
    );
  };

  const columnsWorkPreferencesTable = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    {
      name: "priority",
      label: t("employer.priority"),
      options: {
        filter: false,
        sort: true,
        searchable: false,
      },
    },
    defaultCol("company_name", "jobPreferences.company_name"),
    {
      name: "website",
      label: t("employer.page"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value) => (
          <a href={value} target="_blank" rel="noopener noreferrer">
            {t("employer.toPage")}
          </a>
        ),
      },
    },
    defaultCol("city", "jobPreferences.city"),
    defaultCol("state", "jobPreferences.state"),
    defaultCol("position", "jobPreferences.position"),
    defaultCol("pay_rate", "jobPreferences.pay_rate"),
    defaultCol("hours", "jobPreferences.hours"),
    defaultCol("start_work", "jobPreferences.start_date"),
    defaultCol("end_work", "jobPreferences.end_date"),
    defaultCol("housing", "jobPreferences.housing"),
    {
      name: "english_level",
      label: t("jobPreferences.english_level"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, travelerList, updateValue) => {
          return t(value);
        },
      },
    },
    defaultCol("details", "employer.positionDetails"),
    defaultCol("comments", "jobPreferences.other_comments"),
  ];

  return (
    <>
      <Modal
        open={openEmployerList}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer showImage={false} class="ww-container-xxl">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "400px",
                overflowY: "scroll",
              }}
            >
              <Table
                data={tableData}
                columns={columnsWorkPreferencesTable}
                options={defaultOptions()}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <FormProvider {...methods}>
                <form>
                  <textarea
                    name="commentPriority"
                    className="form-control mb-2"
                    rows="2"
                    disabled
                  ></textarea>
                  <label>{t("employer.priorityDates")}</label>
                  <div className="container-inputs">
                    <div className="inputs-style">
                      <DateInput
                        name="uniBreakBegins"
                        control={control}
                        placeholder={t("form.university.breackB")}
                        label={
                          <div className="labelWithButton">
                            <label className="labelButton">
                              {t("form.university.breackB")}
                              <span className="text-danger"> *</span>
                            </label>
                          </div>
                        }
                        disabled
                      />
                    </div>
                    <div className="inputs-style">
                      <DateInput
                        name="uniBreakEnd"
                        control={control}
                        placeholder={t("form.university.breackE")}
                        label={
                          <div className="labelWithButton">
                            <label className="labelButton">
                              {t("form.university.breackE")}
                              <span className="text-danger"> *</span>
                            </label>
                          </div>
                        }
                        disabled
                      />
                    </div>
                  </div>
                  <div className="container-inputs">
                    <div className="inputs-style">
                      <DateInput
                        name="startDateWork"
                        control={control}
                        placeholder={t("work.startDate")}
                        label={
                          <div className="labelWithButton">
                            <label className="labelButton">
                              {t("work.startDate")}
                              <span className="text-danger"> *</span>
                            </label>
                          </div>
                        }
                        disabled
                      />
                    </div>
                    <div className="inputs-style">
                      <DateInput
                        name="endDateWork"
                        control={control}
                        placeholder={t("work.endDate")}
                        label={
                          <div className="labelWithButton">
                            <label className="labelButton">
                              {t("work.endDate")}
                              <span className="text-danger"> *</span>
                            </label>
                          </div>
                        }
                        disabled
                      />
                    </div>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              type="ww-btn-secondary"
              size="btn-xs secondary"
              label={t("main.close")}
              onClick={handleClose}
            />
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default EmployerListModal;
