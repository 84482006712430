import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/buttons/button";
import "../../../styles/pages/postulationState.scss";
import {
  callApi,
  getWorkPreferencesDates,
  getWorkPreferencesTable,
  submitWorkPreferencesData,
} from "../../../services/apiService";
import MainContainer from "../../../layouts/container/mainContainer";
import Modal from "@mui/material/Modal";
import { NotificationManager } from "react-notifications";
import Table, { defaultCol } from "../../../components/table";
import { generalStatesPostulation } from "../../../constants/enum";
import { BsFiles } from "react-icons/bs";
import FilesModal from "../../employees/filesModal";
import { Checkbox } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import DateInput from "../../../components/forms/inputs/dateInput";
import HelpButton from "../../../components/buttons/helpButton";

const JobPreferencesModal = ({
  closeHandleJobPreferencesModal,
  openJobPreferencesModal,
  postulationId,
  setGeneralState,
  userId,
}) => {
  const { t } = useTranslation();

  const [botonActivo, setBotonActivo] = useState(false);
  const [jobPreferencesModal, setJobPreferencesModal] = useState(false);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [currentEmployer, setCurrentEmployer] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [openFilesModal, setOpenFilesModal] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);

  const [jobPriorityModal, setJobPriorityModal] = useState(false);
  const [priorityTable, setPriorityTable] = useState([]);

  const handleCloseFilesModal = () => {
    setCurrentEmployer([]);
    setOpenFilesModal(false);
  };

  const resultadoFiltrado = rowsSelected.map((x) => tableData[x]);

  const getObj = (data) => {
    return tableData.find((x) => x.id_employer_position === data.rowData[0]);
  };

  const clearSelection = () => {
    setRowsSelected([]);
  };

  const handleClose = () => {
    reset();
    clearData();
    setCheckedRows([]);
    clearSelection();
    handleCloseJobPriority();
    closeHandleJobPreferencesModal();
    setJobPreferencesModal(false);
  };

  const handleOpenJobPriority = () => {
    setJobPriorityModal(true);
  };
  const handleCloseJobPriority = () => {
    setPriorityTable([]);
    setJobPriorityModal(false);
  };

  useEffect(() => {
    if (!openJobPreferencesModal) return;
    getWorkDates();
    loadWorkPreferences();
  }, [openJobPreferencesModal]);

  const loadWorkPreferences = () => {
    callApi(
      () => getWorkPreferencesTable(),
      (data) => {
        setTableData(data);
      }
    );
  };
  const getWorkDates = () => {
    callApi(
      () => getWorkPreferencesDates({ postulationId: postulationId }),
      (data) => {
        loadData(data);
      }
    );
  };

  const handleCheckboxChange = (rowIndex) => {
    const updatedCheckedRows = [...checkedRows];
    if (updatedCheckedRows.includes(rowIndex)) {
      const indexToRemove = updatedCheckedRows.indexOf(rowIndex);
      updatedCheckedRows.splice(indexToRemove, 1);
    } else {
      updatedCheckedRows.push(rowIndex);
    }
    setCheckedRows(updatedCheckedRows);
  };

  const options = {
    filter: true,
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "vertical",
    rowsPerPage: 10,
    selectToolbarPlacement: "none",
  };

  const columnsWorkPreferencesTable = [
    {
      name: "id_employer_position",
      label: "id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, employers, updateValue) => {
          var rowObj = getObj(employers);
          return (
            <Checkbox
              checked={checkedRows.includes(rowObj)}
              onChange={() => handleCheckboxChange(rowObj)}
            />
          );
        },
      },
    },
    defaultCol("company_name", "jobPreferences.company_name"),
    {
      name: "Actions",
      label: t("userList.filesEmployer"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, employers, updateValue) => {
          var rowObj = getObj(employers);
          return (
            <div className="table-btn-group">
              <Button
                disabled={botonActivo}
                label={<BsFiles />}
                onClick={() => {
                  setCurrentEmployer(rowObj);
                  setOpenFilesModal(true);
                }}
              />
            </div>
          );
        },
      },
    },
    {
      name: "website",
      label: t("employer.page"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value) => (
          <a href={value} target="_blank" rel="noopener noreferrer">
            {t("employer.toPage")}
          </a>
        ),
      },
    },
    defaultCol("city", "jobPreferences.city"),
    {
      name: "destiny",
      label: t("employer.destiny"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, travelerList, updateValue) => {
          return t(value);
        },
      },
    },
    defaultCol("position", "jobPreferences.position"),
    defaultCol("pay_rate", "jobPreferences.pay_rate"),
    defaultCol("hours", "jobPreferences.hours"),
    defaultCol("start_work", "jobPreferences.start_date"),
    defaultCol("end_work", "jobPreferences.end_date"),
    defaultCol("housing", "jobPreferences.housing"),
    {
      name: "english_level",
      label: t("jobPreferences.english_level"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, travelerList, updateValue) => {
          return t(value);
        },
      },
    },
    defaultCol("details", "employer.positionDetails"),
    defaultCol("comments", "jobPreferences.other_comments"),
  ];

  const columnsWorkPriorityTable = [
    {
      name: "id_employer_position",
      label: "id",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: "excluded",
      },
    },
    defaultCol("company_name", "jobPreferences.company_name"),
    {
      name: "website",
      label: t("employer.page"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value) => (
          <a href={value} target="_blank" rel="noopener noreferrer">
            {t("employer.toPage")}
          </a>
        ),
      },
    },
    defaultCol("city", "jobPreferences.city"),
    defaultCol("position", "jobPreferences.position"),
    {
      name: "priority",
      label: t("employer.priority"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, employers, updateValue) => {
          var rowObj = getObj(employers);

          const handlePriorityChange = (event) => {
            const newPriority = event.target.value;

            rowObj.priority = newPriority;
            updateRowPriority(rowObj, newPriority);
          };
          return (
            <select onChange={handlePriorityChange}>
              <option value="">Select</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </select>
          );
        },
      },
    },
  ];

  const updateRowPriority = (rowObj, newPriority) => {
    const updatedTable = priorityTable.map((row) =>
      row.id_employer_position === rowObj.id_employer_position
        ? { ...row, priority: newPriority }
        : row
    );
    setPriorityTable(updatedTable);
  };

  const submitWorkPreferences = () => {
    const mappedList = checkedRows
      .filter((obj) => obj !== undefined)
      .map((obj) => ({
        employer_id: obj.employer_id,
        id_employer_position: obj.id_employer_position,
        id_employer_info: obj.id_employer_info,
      }));

    const filteredList = checkedRows.filter((obj) => obj !== undefined);

    if (mappedList.length < 1 || mappedList.length > 3) {
      NotificationManager.warning(t("jobPreferences.WarningNotification"));
      return;
    }

    setPriorityTable(filteredList);
    handleOpenJobPriority();
  };

  const validations = {
    uniBreakBegins: {
      required: {
        value: true,
        message: t("userList.required"),
      },
    },
    uniBreakEnd: {
      required: {
        value: true,
        message: t("userList.required"),
      },
    },
    startDateWork: {
      required: {
        value: true,
        message: t("userList.required"),
      },
    },
    endDateWork: {
      required: {
        value: true,
        message: t("userList.required"),
      },
    },
  };
  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const clearData = () => {
    setValue("uniBreakBegins", "");
    setValue("uniBreakEnd", "");
    setValue("startDateWork", "");
    setValue("endDateWork", "");
  };
  const loadData = (dataDates) => {
    setValue(
      "uniBreakBegins",
      !dataDates || !dataDates.universityBreakBegin
        ? ""
        : new Date(dataDates.universityBreakBegin)
    );
    setValue(
      "uniBreakEnd",
      !dataDates || !dataDates.universityBreakEnd
        ? ""
        : new Date(dataDates.universityBreakEnd)
    );
    setValue(
      "startDateWork",
      !dataDates || !dataDates.startDateWork
        ? ""
        : new Date(dataDates.startDateWork)
    );
    setValue(
      "endDateWork",
      !dataDates || !dataDates.endDateWork
        ? ""
        : new Date(dataDates.endDateWork)
    );
  };

  const submitWorkWithPriority = handleSubmit((data) => {
    const priorities = priorityTable.map((row) => row.priority);
    if (priorities.some((x) => x === "" || x == undefined)) {
      NotificationManager.warning(t("employer.priorityEmpty"));
      return;
    }
    const seenPriorities = new Set();
    for (let i = 0; i < priorities.length; i++) {
      const priority = priorities[i];
      if (seenPriorities.has(priority)) {
        NotificationManager.warning(t("employer.priorityRepeat"));
        return;
      }
      seenPriorities.add(priority);
    }

    const mappedList = priorityTable.map((obj) => ({
      employer_id: obj.employer_id,
      id_employer_position: obj.id_employer_position,
      id_employer_info: obj.id_employer_info,
      priority: obj.priority,
    }));

    data.uniBreakBegins = new Date(data.uniBreakBegins);
    data.uniBreakBegins.setHours(0, 0, 0, 0);
    data.uniBreakEnd = new Date(data.uniBreakEnd);
    data.uniBreakEnd.setHours(0, 0, 0, 0);
    data.startDateWork = new Date(data.startDateWork);
    data.startDateWork.setHours(0, 0, 0, 0);
    data.endDateWork = new Date(data.endDateWork);
    data.endDateWork.setHours(0, 0, 0, 0);

    const dataToSubmit = {
      postulationId: postulationId,
      arrayPositions: mappedList,
      comment: watch("commentPriority"),
      uniBreakBegins: data.uniBreakBegins,
      uniBreakEnd: data.uniBreakEnd,
      startDateWork: data.startDateWork,
      endDateWork: data.endDateWork,
      userId: userId,
    };

    callApi(
      () => submitWorkPreferencesData(dataToSubmit),
      () => {
        handleCloseJobPriority();
        handleClose();
        NotificationManager.success(t("form.success"));
        setGeneralState(generalStatesPostulation.employerSelection);
      }
    );
  });
  return (
    <>
      {/* MODAL Job Preferences */}
      <Modal
        open={openJobPreferencesModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer showImage={false} class="ww-container-xxl">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Table
              data={tableData}
              columns={columnsWorkPreferencesTable}
              options={options}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                type="ww-btn-secondary"
                size="btn-xs secondary"
                label={t("main.close")}
                onClick={handleClose}
              />
              <Button
                size="btn-xs"
                disabled={botonActivo}
                label={t("main.next")}
                onClick={() => {
                  submitWorkPreferences(resultadoFiltrado);
                }}
              />
            </div>
          </div>
        </MainContainer>
      </Modal>
      {/* MODAL files */}
      <FilesModal
        open={openFilesModal}
        onClose={handleCloseFilesModal}
        currentEmployer={currentEmployer}
      />
      <Modal
        open={jobPriorityModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer showImage={false} class="ww-container-xxl">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <h1 className="title">{t("employer.priorityTitle")}</h1>
            <Table
              data={priorityTable}
              columns={columnsWorkPriorityTable}
              options={options}
            />
            <FormProvider {...methods}>
              <form>
                <label>{t("OfficeUse.comments")}</label>
                <textarea
                  name="commentPriority"
                  placeholder={t("OfficeUse.comments")}
                  className="form-control mb-2"
                  rows="2"
                  {...register("commentPriority")}
                ></textarea>
                <div className="HContainer">
                  <label>{t("employer.priorityDates")}</label>{" "}
                  <HelpButton message={t("orderModal.help")} />
                </div>
                <div className="container-inputs">
                  <div className="inputs-style">
                    <DateInput
                      name="uniBreakBegins"
                      control={control}
                      placeholder={t("form.university.breackB")}
                      label={
                        <div className="labelWithButton">
                          <label className="labelButton">
                            {t("form.university.breackB")}
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                      }
                      {...register(
                        "uniBreakBegins",
                        validations.uniBreakBegins
                      )}
                    />
                    <span className="text-danger text-small d-block mb-2">
                      {errors.uniBreakBegins && errors.uniBreakBegins.message}
                    </span>
                  </div>
                  <div className="inputs-style">
                    <DateInput
                      name="uniBreakEnd"
                      minDate={watch("uniBreakBegins")}
                      control={control}
                      placeholder={t("form.university.breackE")}
                      label={
                        <div className="labelWithButton">
                          <label className="labelButton">
                            {t("form.university.breackE")}
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                      }
                      {...register("uniBreakEnd", validations.uniBreakEnd)}
                    />
                    <span className="text-danger text-small d-block mb-2">
                      {errors.uniBreakEnd && errors.uniBreakEnd.message}
                    </span>
                  </div>
                </div>
                <div className="container-inputs">
                  <div className="inputs-style">
                    <DateInput
                      name="startDateWork"
                      control={control}
                      minDate={watch("uniBreakBegins")}
                      maxDate={watch("uniBreakEnd")}
                      placeholder={t("work.startDate")}
                      label={
                        <div className="labelWithButton">
                          <label className="labelButton">
                            {t("work.startDate")}
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                      }
                      disabled={
                        watch("uniBreakBegins") == null ||
                        watch("uniBreakEnd") == null
                      }
                      {...register("startDateWork", validations.startDateWork)}
                    />
                    <span className="text-danger text-small d-block mb-2">
                      {errors.startDateWork && errors.startDateWork.message}
                    </span>
                  </div>
                  <div className="inputs-style">
                    <DateInput
                      name="endDateWork"
                      control={control}
                      minDate={watch("startDateWork")}
                      maxDate={watch("uniBreakEnd")}
                      placeholder={t("work.endDate")}
                      label={
                        <div className="labelWithButton">
                          <label className="labelButton">
                            {t("work.endDate")}
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                      }
                      disabled={
                        watch("uniBreakBegins") == null ||
                        watch("uniBreakEnd") == null
                      }
                      {...register("endDateWork", validations.endDateWork)}
                    />
                    <span className="text-danger text-small d-block mb-2">
                      {errors.endDateWork && errors.endDateWork.message}
                    </span>
                  </div>
                </div>
              </form>
            </FormProvider>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                type="ww-btn-secondary"
                size="btn-xs secondary"
                label={t("main.return")}
                onClick={handleCloseJobPriority}
              />
              <Button
                size="btn-xs"
                disabled={botonActivo}
                label={t("profile.SaveChanges")}
                onClick={() => submitWorkWithPriority()}
              />
            </div>
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default JobPreferencesModal;
